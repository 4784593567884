// show or hide compnent
const useVisibilityComponent = (component, visibility) => {
  return visibility ? component : null;
};

//validation
const windowScrollY = (pixel) => {
  return window.scrollY >= pixel ? true : false;
};

const windowScreenWidth = (pixel) => {
  return window.screen.width <= pixel ? true : false;
};

const pagePosition = (currentPositon, position) => {
  return currentPositon === position ? true : false;
};

const checkUnit = (unit) => {
  // return unit === "kg" ? "/kg" : `/${unit}`;
  if (unit) {
    return unit.charAt(0) !== "/" ? `/${unit}` : ` ${unit}`;
  } else {
    return unit;
  }
};

//show when position
const getOnePositionPage = (
  currentPositon,
  position,
  trueCondition,
  falseCondition
) => {
  return pagePosition(currentPositon, position)
    ? falseCondition
    : falseCondition;
};

const getTwoPositionPage = (
  currentPositon,
  positionOne,
  positionTwo,
  trueCondition,
  falseCondition
) => {
  return pagePosition(currentPositon, positionOne) ||
    pagePosition(currentPositon, positionTwo)
    ? trueCondition
    : falseCondition;
};

const isObjectEmpty = (obj) => {
  if (obj == null) return true;
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;
  if (typeof obj !== "object") return true;
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }
};
export {
  getOnePositionPage,
  getTwoPositionPage,
  useVisibilityComponent,
  windowScrollY,
  windowScreenWidth,
  pagePosition,
  checkUnit,
  isObjectEmpty,
};
