const { default: client, guests } = require("../../utils/axios");

async function generateCidSecret() {
  const cid = await client.get("api/v1/generate-cid-csecret");
  return cid;
}

async function getToken(cid) {
  const res = await client.post("api/v1/get-token", cid);
  return res;
}

async function getTokenGuest(cid) {
  const res = await guests.post("api/v1/get-token", cid);
  return res;
}

export { generateCidSecret, getToken, getTokenGuest };
