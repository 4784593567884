const { default: client } = require("../../utils/axios");

async function getKotaByLatLong(location) {
  const { data } = await client.post("api/v1/get_kecamatan", location);
  return data.Kota;
}

async function getKotaOpenstreetMap(lat, long) {
  const { data } = await client.get(
    `https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${long}&zoom=18&format=jsonv2`
  );
  return data;
}

export { getKotaByLatLong, getKotaOpenstreetMap };
