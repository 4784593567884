import { differenceInSeconds } from "date-fns";
import Cookie from "js-cookie";
import {
  loginWithFacebook,
  registerWithFacebook,
} from "../services/LoginSocialMediaService";
import expiredStorage from "../utils/expiredStorage";

const responseFacebook = (e, setErrorText, setShowError) => {
  if (e._profile && e._token) {
    const form = { userId: e._profile.id, token: e._token.accessToken };
    registerWithFacebook(form)
      .then((res) => {
        loginWithFacebook(form)
          .then((res) => {
            const now = new Date(Date.now());
            const expired = new Date(res.data.Data.expired_at * 1000);
            const seconds = differenceInSeconds(expired, now);
            expiredStorage.setItem("@user", res.data.Data.session_id, seconds);
            expiredStorage.setItem(
              "@tipelogin",
              res.data.Data.login_with_socmed,
              seconds
            );
            Cookie.remove("user_guest");
            Cookie.remove("chart_guest");
            Cookie.remove("chart_length_guest");
            window.location.reload();
          })
          .catch((e) => {
            if (e.response.data.Deskripsi === "Failed to create session") {
              setErrorText("Anda sudah melakukan login sebelumnya");
              setShowError(true);
            }
          });
      })
      .catch((err) => {
        loginWithFacebook(form)
          .then((res) => {
            const now = new Date(Date.now());
            const expired = new Date(res.data.Data.expired_at * 1000);
            const seconds = differenceInSeconds(expired, now);
            expiredStorage.setItem("@user", res.data.Data.session_id, seconds);
            expiredStorage.setItem(
              "@tipelogin",
              res.data.Data.login_with_socmed,
              seconds
            );
            Cookie.remove("user_guest");
            Cookie.remove("chart_guest");
            Cookie.remove("chart_length_guest");
            window.location.reload();
          })
          .catch((e) => {
            if (e.response.data.Deskripsi === "Failed to create session") {
              setErrorText("Anda sudah melakukan login sebelumnya");
              setShowError(true);
            }
          });
      });
  }
};

export default responseFacebook;
