import React, { useEffect, useReducer, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { withRouter } from "react-router-dom";
import KeyIcon from "../../../assets/icon/key.svg";
import ListShop from "../../../assets/profile/daftarBelanja.png";
import OnProcess from "../../../assets/profile/onProcess.png";
import Review from "../../../assets/profile/review.png";
import BreadCrumb from "../../../components/breadcrump/BreadCrumb";
import ToastNotification from "../../../components/button/Toast";
import FormPembelian from "../../../components/modal/FormPembelian";
import CardAlamat from "../../../components/Profile/CardAlamat";
import EditProfile from "../../../components/Profile/EditProfile";
import ProfileMobile from "../../../components/Profile/ProfileMobile";
import { FETCH_COMPLETE, FETCH_DATA } from "../../../components/reducer/Action";
import {
  customerReducer,
  customerState,
} from "../../../components/reducer/CustomerReducer";
import { getProfileCustomer } from "../../../components/services/CustomerService";
import localId from "../../../components/shared/constants/ConstantsIdCustomer";
import FaIcon from "../../../components/shared/font-awesome/FaIcon";
import expiredStorage from "../../../components/utils/expiredStorage";
import "../../../scss/profile/profile.scss";
import NoDataFound from "./NoDataFound";
import Page from "./Page";

const DefaultProfile =
  "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png";

export const checkSessionId = (err, props) => {
  if (err.response) {
    if (
      err.response.data.Deskripsi === "Session Expired" ||
      err.response.data.Deskripsi === "Something Wrong"
    ) {
      expiredStorage.removeItem("@user");
      expiredStorage.removeItem("@userEMail");
      expiredStorage.removeItem("@tipelogin");
      props.history.push("/login");
      window.location.reload();
    }
  }
};

export const toastObject = {
  isOpen: false,
  status: "",
  message: "",
};
export const clearToast = (setToast) => {
  setToast({ ...toastObject });
};

function Profile(props) {
  const isLoginMedsos = expiredStorage.getItem("@tipelogin");
  const [editProfile, setEditProfile] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [modal, setModal] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [state, dispatch] = useReducer(customerReducer, customerState);

  const fetchData = () => dispatch({ type: FETCH_DATA });
  const fetchComplete = (payload) =>
    dispatch({ type: FETCH_COMPLETE, payload });

  const activities = [
    { image: ListShop, text: "Daftar Belanja", link: "/listorder", value: 0 },
    {
      image: OnProcess,
      text: "Pesanan Diproses",
      link: "/listorder",
      value: 2,
    },
    {
      image: Review,
      text: "Ulasan Saya",
      link: "/profile/listulasan",
      value: "",
    },
  ];

  const [toast, setToast] = useState({ ...toastObject });

  useEffect(() => {
    fetchData();
    if (localId && localId !== null) {
      getProfileCustomer()
        .then((res) => {
          fetchComplete(res.data.Data);
        })
        .catch((err) => {
          checkSessionId(err, props);
        });
    } else {
      props.history.push("/login");
    }
  }, [props.location, trigger]);

  const btnListAddress = () => {
    setSeeAll(!seeAll);
  };

  const generateListAddress = () => {
    let rows = (
      <NoDataFound error={true} text="Anda belum menambahkan alamat." />
    );
    if (state.customer.daftar_alamat) {
      rows = state.customer.daftar_alamat.map((res, i) => {
        if (i !== 0) {
          return (
            <CardAlamat
              data={res}
              key={i}
              trigger={trigger}
              setTrigger={setTrigger}
              setToast={setToast}
              toast={toast}
              clearToast={() => clearToast(setToast)}
            />
          );
        }
      });
    }
    return rows;
  };

  const generateFirstAddress = () => {
    let rows = "";
    if (state.customer.daftar_alamat) {
      const data = state.customer.daftar_alamat[0];
      rows = (
        <CardAlamat
          data={data}
          setTrigger={setTrigger}
          trigger={trigger}
          setToast={setToast}
          toast={setToast}
          clearToast={() => clearToast(setToast)}
        />
      );
    }
    return rows;
  };

  return (
    <Page>
      <BreadCrumb />
      <div className="profile-container">
        <label className="profile-title">Informasi Akun</label>
        <div className="container-profile-activity">
          <div className="profile-detail left">
            <div className="row">
              <div className="col-sm-6 img-profile-new">
                <img
                  id="img-profile"
                  src={DefaultProfile}
                  alt="profile image"
                  className="profile-image"
                />
              </div>
              <div className=" col-sm-6 detail-data">
                <label className="text-detail-profile-name">
                  {state.customer.nama_depan !== undefined ? (
                    state.customer.nama_depan +
                    " " +
                    state.customer.nama_belakang
                  ) : (
                    <Skeleton />
                  )}
                </label>
                {state.customer.ttl ? (
                  <>
                    <label className="text-detail-profile">
                      {state.customer.ttl}
                    </label>
                    <label className="text-detail-profile">
                      {state.customer.jk}
                    </label>
                  </>
                ) : (
                  ""
                )}
                <label className="text-detail-profile">
                  {state.customer.no_hp}
                </label>
                <label className="text-detail-profile">
                  {state.customer.email}
                </label>
                {state.customer.ttl && state.customer.jk ? (
                  ""
                ) : (
                  <label className="text-detail-profile-alamat_profile">
                    {state.customer.alamat}
                  </label>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1rem 1rem 0 1rem",
              }}
            >
              {isLoginMedsos == "false" ? (
                <button
                  id="btn-ubah-katasandi"
                  className="btn-change-password"
                  style={{ float: "right" }}
                  onClick={() => props.history.push("/changepassword")}
                >
                  <img
                    src={KeyIcon}
                    alt="key-icon"
                    className="img-ganti-sandi"
                  />
                  <label className="text-on-button my-0">UBAH KATA SANDI</label>
                </button>
              ) : (
                ""
              )}
              <button
                id="btn-ubah-profile"
                className="btn-change-profile"
                onClick={() => setEditProfile(true)}
              >
                <FaIcon
                  icon="far edit"
                  className="fa-lg"
                  style={{ color: "#fff" }}
                />
                <label className="text-on-button my-0">UBAH PROFILE</label>
              </button>
            </div>
          </div>

          <div className="shopping-activity-container right">
            <label style={{ fontWeight: "bold", fontSize: "1.4em" }}>
              Aktivitas Belanja
            </label>
            <div className="row shopping-activity">
              {activities.map((res, i) => {
                return (
                  <div
                    key={i}
                    className="col-4 card-shop-activity"
                    onClick={() =>
                      props.history.push({
                        pathname: res.link,
                        tabFromProfile: res.value,
                      })
                    }
                  >
                    <img
                      src={res.image}
                      alt="daftar belanja"
                      className="icon-shop-activity"
                    />
                    <label className="text-shop-activity">{res.text}</label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="profile-address">
          <label style={{ fontWeight: "bold" }}>Setting Alamat</label>
          <div style={{ padding: "1rem" }}>
            {generateFirstAddress()}
            <div style={{ overflow: "auto" }}>
              <button
                id="btn-lihat-semua"
                className="btn-list-address-profile"
                onClick={() => btnListAddress()}
              >
                Lihat Semua
                <FaIcon
                  icon={seeAll ? "fas chevron-up" : "fas chevron-down"}
                  className="fa-sm icon-chevron"
                />
              </button>
              <button
                id="btn-alamat-baru"
                className="btn-add-address-profile"
                onClick={() => setModal(true)}
              >
                <FaIcon icon="fas plus-circle" className="fa-sm mr-2" />
                Alamat Baru
              </button>
            </div>
            <div
              className={
                seeAll ? "list-address-profile" : `list-address-profile-up`
              }
            >
              {seeAll ? generateListAddress() : ""}
            </div>
          </div>
        </div>

        <FormPembelian
          modal={modal}
          setModal={setModal}
          trigger={trigger}
          setTrigger={setTrigger}
          where={"profile"}
          toast={toast}
          setToast={setToast}
          clearToast={() => clearToast(setToast)}
        />
        <ProfileMobile
          className={"container-profile-mobile"}
          customer={state.customer}
          profileImage={DefaultProfile}
          activities={activities}
          modal={modal}
          setModal={setModal}
          trigger={trigger}
          setTrigger={setTrigger}
          toast={toast}
          setToast={setToast}
          clearToast={() => clearToast(setToast)}
          editProfile={editProfile}
          setEditProfile={setEditProfile}
          isLoginMedsos={isLoginMedsos}
          KeyIcon={KeyIcon}
        />
        <EditProfile
          data={state.customer}
          isOpen={editProfile}
          setIsOpen={setEditProfile}
          trigger={trigger}
          setTrigger={setTrigger}
          toast={toast}
          setToast={setToast}
        />
        <ToastNotification
          isOpen={toast.isOpen}
          message={toast.message}
          status={toast.status}
          setToast={setToast}
        />
      </div>
    </Page>
  );
}

export default withRouter(Profile);
