import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import EmptyCart from "../../../../assets/emptyCart.svg";
import MapOff from "../../../../assets/gps-tidak-aktif.png";
import NoDataImg from "../../../../assets/nodatafound.svg";
import token from "../../../../components/shared/constants/ConstToken";
import "./style.scss";
const NoDataFound = (props) => {
  const { error, text, buttons, cart, text2, map, textButton, image } = props;

  useEffect(() => {
    if (!token && token == "") {
      window.location.reload(false);
    }
  }, []);

  return (
    <Container className={`text-center ${props.className}`}>
      <img
        className={map ? "image-map" : "image-no-data"}
        src={cart ? EmptyCart : map ? MapOff : image ? image : NoDataImg}
        alt="no data"
      />
      <div className={map ? "container-text-map" : "container_text_no_data"}>
        <p
          className="text1"
          style={{
            textAlign: "center",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {error ? text : "Hasil tidak di temukan"}
        </p>
        <label className="label-2">{text2}</label>
      </div>
      <div>
        {buttons ? (
          <button
            className="btn_no_data_found"
            onClick={() => props.history.push("/")}
          >
            {textButton}
          </button>
        ) : null}
      </div>
    </Container>
  );
};

export default withRouter(NoDataFound);
