import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Input, Label } from "reactstrap";
import "../../scss/login/login.scss";
import logoPasar from "../../assets/logo-pasarid.png";
import { Link, useHistory } from "react-router-dom";
import { updatePassword } from "../services/CustomerService";
import localId from "../shared/constants/ConstantsIdCustomer";
import { CheckCircle, Cancel } from "@material-ui/icons";

export default function ChangePassword() {
  const password = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const errorValue = [
    { status: false, msg: "Tidak mengandung spasi" },
    { status: false, msg: "Minimal 8 karakter" },
    { status: false, msg: "Minimal 1 huruf besar (A-Z)" },
    { status: false, msg: "Minimal 1 huruf kecil (a-z)" },
    { status: false, msg: "Minimal 1 angka (0-9)" },
  ];

  const [errorPass, setErrorPass] = useState(errorValue);

  const [psswd, setPassword] = useState({ ...password });
  const [passwordShown, setPasswordShown] = useState(true);
  const [isInvalid, setInvalid] = useState(false);
  const [disable, setDisable] = useState(true);
  const [valid, setValid] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [success, setSucces] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const history = useHistory();

  const checkError = (e) => {
    switch (e) {
      case "Password kurang dari 8 karakter":
        setMessage(e);
        break;
      case "Password lama salah":
        setMessage(e);
        break;
      case "Password harus mengandung minimal 1 spesial karakter":
        setMessage(e);
        break;
      case "Password harus mengandung huruf besar":
        setMessage(e);
        break;
      case "Password harus mengandung huruf kecil":
        setMessage(e);
        break;
      case "Password harus mengandung angka":
        setMessage(e);
        break;
      case "Passwowd harus mengandung angka":
        setMessage("Password harus mengandung angka");
        break;
      case "Password baru tidak boleh sama dengan password lama":
        setMessage(e);
        break;
      case "Password harus mengandung minimal 1 spesial character":
        setMessage("Password harus mengandung minimal 1 spesial karakter");
        break;
      default:
        if (e?.validationError) {
          switch (e?.validationError) {
            case "Password kurang dari 8 karakter":
              setMessage("Panjang password minimal 8 karakter");
              break;
            default:
              setMessage(e?.validationError);
              break;
          }
        } else setMessage(e);
        break;
    }
  };

  const checkPassword = (e) => {
    setPassword({ ...psswd, confirmPassword: e });
    if (e === psswd.newPassword || e === "") {
      setValid(true);
      setShow(true);
    } else {
      setValid(false);
      setDisable(true);
      setShow(false);
    }
  };

  const checkPass = (password) => {
    const regexUpper = /[A-Z]/;
    const regexLower = /[a-z]/;
    const regexNumber = /.*[0-9].*/;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z_@./#&+-\d]{8,20}$/;

    setPassword({ ...psswd, newPassword: password });

    if (password.length > 0) setInvalid(true);
    else setInvalid(false);

    if (/^\S*$/g.test(password)) {
      const newError = errorPass;
      newError[0] = { ...newError[0], status: true };
      setErrorPass(newError);
    } else {
      const newError = errorPass;
      newError[0] = { ...newError[0], status: false };
      setErrorPass(newError);
    }

    if (password.length > 7 && password.length < 21) {
      const newError = errorPass;
      newError[1] = { ...newError[1], status: true };
      setErrorPass(newError);
    } else {
      const newError = errorPass;
      newError[1] = { ...newError[1], status: false };
      setErrorPass(newError);
    }

    if (regexUpper.test(password)) {
      const newError = errorPass;
      newError[2] = { ...newError[2], status: true };
      setErrorPass(newError);
    } else {
      const newError = errorPass;
      newError[2] = { ...newError[2], status: false };
      setErrorPass(newError);
    }

    if (regexLower.test(password)) {
      const newError = errorPass;
      newError[3] = { ...newError[3], status: true };
      setErrorPass(newError);
    } else {
      const newError = errorPass;
      newError[3] = { ...newError[3], status: false };
      setErrorPass(newError);
    }

    if (regexNumber.test(password)) {
      const newError = errorPass;
      newError[4] = { ...newError[4], status: true };
      setErrorPass(newError);
    } else {
      const newError = errorPass;
      newError[4] = { ...newError[4], status: false };
      setErrorPass(newError);
    }

    if (regex.test(password)) {
      setInvalid(false);
    } else {
      setInvalid(true);
    }
  };

  const onSubmit = () => {
    updatePassword(localId, psswd)
      .then((res) => {
        setAlertOpen(true);
        setSucces(true);
        setMessage("Sukses Ubah Password");
        setTimeout(() => {
          setAlertOpen(false);
          history.push("/profile");
        }, 2500);
      })
      .catch((err) => {
        checkError(err?.response?.data?.Data?.error);
        setAlertOpen(true);
        setTimeout(() => {
          setAlertOpen(false);
        }, 3000);
      });
  };

  useEffect(() => {
    const buttonDisable = (usr) => {
      let result = Object.values(usr).every((o) => o !== "");

      if (psswd.newPassword === psswd.confirmPassword) {
        setValid(true);
        setShow(true);
      } else {
        setValid(false);
        setShow(false);
      }

      if (result && show) setDisable(false);
      else setDisable(true);
    };

    buttonDisable(psswd);
  }, [psswd, show]);

  console.log(disable, isInvalid);

  return (
    <div className="container-change-password">
      <div className="content-change-password">
        <div className="img-container">
          <Link to="/">
            <img height="64px" src={logoPasar} alt="logo pasarid" />
          </Link>
        </div>
        <h4 className="text-reset-password">Reset Password</h4>

        <Col md={12} style={{ marginTop: "0.5rem" }}>
          <Label>Password Lama</Label>
          <Input
            id="input_change_pass"
            type="password"
            value={psswd.email}
            onChange={(e) =>
              setPassword({ ...psswd, oldPassword: e.target.value })
            }
            required={true}
            placeholder="Masukkan password lama Anda"
            minLength={8}
            maxLength={20}
          />
        </Col>
        <Col md={12} style={{ marginTop: "0.5rem" }}>
          <Label>Password Baru</Label>
          <div className="pass-wrapper">
            <Input
              id="input_change_pass"
              type={passwordShown ? `password` : `text`}
              value={psswd.password}
              onChange={(e) => checkPass(e.target.value)}
              required={true}
              minLength={8}
              maxLength={20}
              placeholder="Minimal 8 karakter"
            />
            <i
              className="eye_icon_change_pass"
              onClick={() => setPasswordShown(!passwordShown)}
            >
              <FontAwesomeIcon
                color={passwordShown ? `#d1d1d1` : `#000`}
                size="lg"
                icon="eye"
              />{" "}
            </i>
          </div>
        </Col>
        <Col md={12} style={{ marginTop: "0.5rem" }}>
          {errorPass?.map((row, i) => (
            <div className={row.status ? "valid" : "invalid"} key={i}>
              {row.status ? (
                <CheckCircle className="pass-icon" />
              ) : (
                <Cancel className="pass-icon" />
              )}
              {row.msg}
            </div>
          ))}
        </Col>

        <Col md={12} style={{ marginTop: "0.5rem" }}>
          <Label>Konfirmasi Password Baru</Label>
          <div className="pass-wrapper">
            <Input
              id="input_change_pass"
              type="password"
              value={psswd.confirmPassword}
              onChange={(e) => checkPassword(e.target.value)}
              required={true}
              // invalid={valid}
              minLength={8}
              maxLength={20}
              // valid={valid}
              placeholder="Pastikan sesuai dengan password baru"
            />
            {/* <i onClick={() => setPasswordShown(!passwordShown)}>
              <FontAwesomeIcon
                color={passwordShown ? `#d1d1d1` : `#000`}
                size="lg"
                icon="eye"
              />{" "}
            </i> */}
          </div>
        </Col>
        {!valid && psswd?.confirmPassword && (
          <Col md={12} style={{ marginTop: "0.5rem" }}>
            <div className="invalid">
              <Cancel className="pass-icon" />
              Password tidak sesuai
            </div>
          </Col>
        )}
        <Col md={12} style={{ marginTop: "0.5rem" }}>
          <div className="pass-info">
            Setelah Password berhasil diubah, silahkan masuk kembali dengan
            Passwordi baru anda disemua perangkat Anda.
          </div>
        </Col>

        <Alert color={success ? "success" : "danger"} isOpen={alertOpen}>
          {message}
        </Alert>
        <br />
        <div className="container-button">
          <div className="button-content">
            <Button
              block
              onClick={() => history.goBack()}
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              Kembali
            </Button>
          </div>
          <div className="button-content">
            <Button
              onClick={() => onSubmit()}
              block
              disabled={disable || isInvalid}
              style={{ backgroundColor: "#7A8D3F" }}
            >
              Reset
            </Button>
          </div>
        </div>

        <br />
      </div>
    </div>
  );
}
