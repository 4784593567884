import React, { useContext, useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { Link, Redirect, useHistory } from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";
import { differenceInSeconds } from "date-fns";
import { Close } from "@material-ui/icons";
import productContext from "../../contex/context/product-context";
import { loginCustomer, lupaPassword, loginSocmedHide } from "../services/CustomerService";
import responseGoogle from "../auth/ResponseGoogle";
import SocialLoginButton from "../button/socialLoginButton";
import responseFacebook from "../auth/ReponseFacebook";
import googleClientID from "../shared/constants/GoogleClientID";
import fbClientID from "../shared/constants/FacebookClientID";
import FaIcon from "../shared/font-awesome/FaIcon";
import expiredStorage from "../utils/expiredStorage";
import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  FormText,
  Input,
  Label,
  Row,
  Spinner,
  Modal,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookie from "js-cookie";

import ModalComp from "../form/ModalComp";

import { checkEmail } from "../utils/validator";
import localId from "../shared/constants/ConstantsIdCustomer";

import { generateCidSecret, getToken } from "../services/CidService";

import TempIllustration from "assets/login_illustrasi.png";
import PasarId from "assets/logo-pasarid.png";
import IconGoogle from "assets/google-icon.svg";
import "../../scss/login/login.scss";
import { Typography } from "@material-ui/core";

const wordInLineStyle = {
  width: "100%",
  textAlign: "center",
  borderBottom: "1px solid #E0E0E0",
  lineHeight: "0.1em",
  margin: "30px 0 20px",
  fontSize: "0.8em",
  color: "#757575",
};

const LoginModal = (props) => {
  const { isOpen, isGuest, onClose } = props;
  const [open, setOpen] = useState(isOpen);
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validMail, setValidMail] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const { reloadCart } = useContext(productContext);

  const [loginFacebook, setLoginFacebook] = useState(0);
  const [loginGoogle, setLoginGoogle] = useState(0);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [showError, setShowError] = useState(false);
  const [forgot, setForgot] = useState(false);
  const { fetchCustomerComplete } = useContext(productContext);

  const history = useHistory();

  // useEffect(() => {
  //   if (localId) {
  //     props.history.push("/");
  //   }
  // }, [localId]);

  useEffect(() => {
    loginSocmedHide().then((res) => {
      setLoginFacebook(res.data.Data.btn_facebook);
      setLoginGoogle(res.data.Data.btn_google);
    });
  }, []);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const userAgent = navigator.userAgent;

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (forgotPassword) {
      lupaPassword(user.email)
        .then((res) => {
          setIsLoading(false);
          setForgot(true);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data.Deskripsi === "You Need To Be Authorized") {
              setErrorText("Maaf, terjadi kesalahan silahkan ulangi");
              setErrorEmail(true);
              generateCidSecret().then((res) => {
                getToken(res.data.Data).then((res) => {
                  expiredStorage.setItem("@token", res.data.Data.token, 3595);
                  Cookie.remove("user_guest");
                  Cookie.remove("chart_guest");
                  Cookie.remove("chart_length_guest");
                  window.location.reload();
                });
              });
            } else if (err.response.data.Deskripsi === "Customer isn't active") {
              setErrorText("Akun belum diaktivasi");
              setErrorEmail(true);
            } else {
              setErrorText("E-mail tidak terdaftar");
              setErrorEmail(true);
            }
          }
          setIsLoading(false);
        });
    } else {
      loginCustomer(user)
        .then((res) => {
          fetchCustomerComplete(res.data.Data);
          setIsLoading(false);
          setUser({
            email: "",
            password: "",
          });
          const now = new Date(Date.now());
          const expired = new Date(res.data.Data.expired_at * 1000);
          const seconds = differenceInSeconds(expired, now);
          expiredStorage.setItem("@user", res.data.Data.session_id, seconds);
          expiredStorage.setItem("@tipelogin", res.data.Data.login_with_socmed, seconds);
          Cookie.remove("user_guest");
          Cookie.remove("chart_guest");
          Cookie.remove("chart_length_guest");
          window.location.reload();
        })
        .catch((err) => {
          setErrorEmail(true);
          setIsLoading(false);
          setErrorText(err?.response?.data?.Deskripsi);
        });
    }
  };

  const handleSuccess = () => {
    setSuccess(false);
    setForgotPassword(false);
  };

  // const redirectToDomain = () => {
  //   const domain = user.email.substring(user.email.lastIndexOf("@") + 1);
  //   window.location.assign(`https://${domain}`);
  // };

  const displayLogin = () => {
    return (
      <>
        <Col className="col-login" md={12}>
          {/* <Alert className="login-alert" color="danger" isOpen={errorEmail}>
            <FaIcon
              style={{ marginRight: "10px" }}
              icon="fas exclamation-circle"
            />
            {errorText}
          </Alert> */}
          <Label>Email</Label>
          <Input
            type="email"
            value={user.email}
            onChange={(e) => checkEmail(e, user, setUser, setValidMail)}
            invalid={validMail}
            required={true}
          />
          {!validMail && <FormFeedback>Email format tidak sesuai</FormFeedback>}
        </Col>
        <Col className="col-login mt-3" md={12}>
          <Label>Kata Sandi</Label>
          <div className="pass-wrapper">
            <Input
              type={passwordShown ? "text" : "password"}
              value={user.password}
              onChange={(e) => setUser({ ...user, password: e.target.value })}
              required={true}
              minLength={8}
              maxLength={20}
            />
            <i onClick={() => setPasswordShown(!passwordShown)}>
              <FontAwesomeIcon color={!passwordShown ? `#d1d1d1` : `#000`} size="lg" icon="eye" />{" "}
            </i>
          </div>
        </Col>
        <Col md={6} style={{ marginTop: "10px" }}>
          <input type="checkbox" id="rememberMe" name="rememberMe" value={true} />{" "}
          <label htmlFor="rememberMe">Ingat saya</label>
        </Col>
        <Col md={6} style={{ marginBottom: "1rem", textAlign: "end" }}>
          <Link
            to={{ pathname: "/password/reset", state: "forgot" }}
            onClick={() => setForgotPassword(!forgotPassword)}
            style={{ color: "#7A8D3F" }}
          >
            Lupa Kata Sandi
          </Link>
        </Col>

        <br />
        <br />
        <Col md={12}>
          <Button
            block
            disabled={isLoading}
            style={{
              backgroundColor: "#7A8D3F",
              borderRadius: "60px",
              border: "none",
              fontWeight: "bold",
              padding: "15px",
            }}
          >
            MASUK
          </Button>
        </Col>
        <Col md={12} style={{ marginTop: "0.75rem" }}>
          <Button
            block
            onClick={() => history.push("/register")}
            style={{
              color: "#7A8D3F",
              backgroundColor: "#fff",
              borderRadius: "60px",
              border: "1px solid #7A8D3F",
              fontWeight: "bold",
              padding: "12px 15px",
            }}
          >
            DAFTAR BARU
          </Button>
        </Col>
        <br />
        <br />
      </>
    );
  };

  // if (Boolean(localId)) {
  //   return <Redirect to="/" />;
  // }

  const generateRandomString = (length) => {
    var id = "";
    var rdm62;
    while (length--) {
      // Generate random integer between 0 and 61, 0|x works for Math.floor(x) in this case
      rdm62 = 0 | (Math.random() * 62);
      // Map to ascii codes: 0-9 to 48-57 (0-9), 10-35 to 65-90 (A-Z), 36-61 to 97-122 (a-z)
      id += String.fromCharCode(rdm62 + (rdm62 < 10 ? 48 : rdm62 < 36 ? 55 : 61));
    }
    return id;
  };

  const guestResponse = () => {
    //generate random string
    Cookie.set("user_guest", generateRandomString(16), { expires: 1 });
    reloadCart();
    window.location.reload();
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        // style={{width:'45vw', maxWidth:'45vw'}}
        style={{ padding: "30px !important" }}
        centered
      >
        <Form
          style={{
            width: "100%",
            padding: "5% 10%",
          }}
          className="mx-auto my-2 form-login"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="text-center">
            {!forgotPassword ? (
              <>
                <h4
                  style={{
                    color: "#424242",
                    fontWeight: "800",
                    marginBottom: "1.5rem",
                  }}
                >
                  Masuk
                </h4>
              </>
            ) : !forgot ? (
              <>
                <h4>Lupa Password</h4>
                <p>
                  Masukkan alamat email dan kami akan mengirim email berisi tautan untuk mereset
                  kata sandi
                </p>
              </>
            ) : null}
          </div>
          <Row>
            <Close
              onClick={onClose}
              style={{
                position: "absolute",
                top: 15,
                right: 15,
                cursor: "pointer",
              }}
            />
            <Col md={12} className="logo-mobile">
              <div className="logo-pasar">
                <Link to="/">
                  <img height="44px" src={PasarId} alt="pasar" />
                </Link>
              </div>
            </Col>

            {!forgotPassword ? displayLogin() : ""}
            {forgot ? null : (
              <>
                {((/PasaridM/i.test(userAgent) && isGuest) || !/PasaridM/i.test(userAgent)) && (
                  <h2 style={wordInLineStyle}>
                    <span style={{ background: "#fff", padding: "0 10px" }}>atau masuk dengan</span>
                  </h2>
                )}
                <br />
                <br />
                {loginFacebook === 0 || /PasaridM/i.test(userAgent) ? (
                  ""
                ) : (
                  <Col
                    className="wrap-ic"
                    md={loginGoogle === 0 ? 12 : 6}
                    xs={12}
                    style={{ paddingTop: 10 }}
                  >
                    <SocialLoginButton
                      provider="facebook"
                      appId={fbClientID}
                      onLoginSuccess={(e) => responseFacebook(e, setErrorText, setShowError)}
                      onLoginFailure={(e) => responseFacebook(e, setErrorText, setShowError)}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            color: "#4167B2",
                            marginLeft: "1em",
                            textAlign: "left",
                          }}
                        >
                          <FaIcon icon="fab facebook-f" />
                        </div>
                        <div
                          style={{
                            color: "black",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          Facebook
                        </div>
                      </div>
                    </SocialLoginButton>
                  </Col>
                )}
                {loginGoogle === 0 || /PasaridM/i.test(userAgent) ? (
                  ""
                ) : (
                  <Col md={loginFacebook === 0 ? 12 : 6} xs={12} style={{ paddingTop: 10 }}>
                    <GoogleLogin
                      clientId={googleClientID}
                      buttonText="Login"
                      onSuccess={(response) => responseGoogle(response, setErrorText, setShowError)}
                      onFailure={(response) => responseGoogle(response, setErrorText, setShowError)}
                      cookiePolicy={"single_host_origin"}
                      render={(props) => (
                        <Button block className="btn-google" onClick={props.onClick}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                marginRight: "1em",
                                textAlign: "center",
                              }}
                            >
                              <img width="25px" src={IconGoogle} />
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: "#757575",
                              }}
                            >
                              Google
                            </div>
                          </div>
                        </Button>
                      )}
                    />
                  </Col>
                )}
              </>
            )}
            {isGuest && (
              <Col md={12} style={{ marginTop: "0.75rem" }}>
                <Button
                  block
                  onClick={guestResponse}
                  style={{
                    color: "#7A8D3F",
                    backgroundColor: "#fff",
                    borderRadius: "60px",
                    border: "1px solid #7A8D3F",
                    fontWeight: "bold",
                    padding: "15px",
                  }}
                >
                  LANJUTKAN SEBAGAI PENGUNJUNG
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
      <SweetAlert show={showError} danger title={errorText} />
      <SweetAlert
        show={errorEmail}
        title={
          <>
            <Typography variant="h5" align="center" style={{ fontWeight: "bold !important" }}>
              Login Gagal
            </Typography>
            <Typography variant="body2" align="center" display="block" component="div">
              {errorText}
            </Typography>
          </>
        }
        danger
        confirmBtnStyle={{
          backgroundColor: "#fff",
          border: "1px solid #ED4140",
          color: "#ED4140",
          width: "150px",
          borderRadius: "60px",
          boxShadow: "none",
        }}
        style={{ width: "22em", borderRadius: "20px", paddingBottom: "40px" }}
        onConfirm={() => setErrorEmail(false)}
      />
    </div>
  );
};

export default LoginModal;
