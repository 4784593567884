import React, { useEffect, useRef, useState } from "react";
import { Modal } from "reactstrap";
import Smile from "../../assets/icon/smile.png";
import "../../scss/profile/profile.scss";
import { updateProfileCustomer } from "../services/CustomerService";
import { maxDate } from "../utils/validator";

export default function EditProfile(props) {
  const { data, isOpen, setIsOpen, trigger, setTrigger, toast, setToast } = props;
  let fileInput = useRef(null);
  const sizeMb = 2048000;

  const dateMax = maxDate();

  const fileUp = {
    name: "",
    isUpload: false,
    path: "",
  };
  const dataProfile = {
    alamat: "",
    email: "",
    nama_belakang: "",
    nama_depan: "",
    no_hp: "",
  };
  const [text, setText] = useState("");
  const [profile, setProfile] = useState({ ...dataProfile });
  const [file, setFile] = useState({ ...fileUp });

  const fileSelectHandler = (e) => {
    if (e.target.files[0]) {
      const size = e.target.files[0].size;
      var fileName = document.getElementById("customFile").value;
      var idxDot = fileName.lastIndexOf(".") + 1;
      var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        if (size <= sizeMb) {
          setFile({
            ...file,
            name: e.target.files[0].name,
            isUpload: true,
            path: URL.createObjectURL(e.target.files[0]),
          });
        } else {
          alert("Ukuran file melebihi batas maksimal");
        }
      } else {
        alert("Only jpg/jpeg and png files are allowed!");
      }
    }
  };

  const fileUploadHandler = (e) => {
    e.preventDefault();
  };

  const dateControl = (e) => {
    let dateElm = document.getElementById("date-input");
    dateElm.max = dateMax;
  };

  useEffect(() => {
    if (data) {
      setProfile({ ...profile, ...data });
    }
  }, [data]);

  const imageDispay = () => {
    let image = "";
    if (file.path == "") image = Smile;
    else image = file.path;
    return image;
  };

  const numeric = (e) => {
    const regexNum = /^[0-9\b()]+$/;
    if (e === "" || regexNum.test(e)) {
      if (e.length <= 14) {
        setProfile({ ...profile, no_hp: e });
      }
    }
  };

  const styleInput = {
    borderRadius: " 10px !important",
    color: "#757575 !important",
    fontSize: "0.9em",
  };

  const saveClick = () => {
    errorChecking();
    if (errorChecking()) {
      updateProfileCustomer(profile)
        .then((res) => {
          setIsOpen(!isOpen);
          setTrigger(!trigger);
          setToast({
            ...toast,
            isOpen: true,
            message: "Sukses Edit Profile",
            status: "success",
          });
        })
        .catch((err) => {
          const error = err.response.data;
          if (error.Data.error.validationError === "nomorHp already exists") {
            setToast({
              ...toast,
              isOpen: true,
              message: "Nomor HP sudah terdaftar",
              status: "error",
            });
          } else {
            setToast({
              ...toast,
              isOpen: true,
              message: "Gagal Edit Profile",
              status: "error",
            });
          }
        });
    }
  };

  const timeoutError = () => {
    setTimeout(() => {
      setText("");
    }, 3000);
  };

  const errorChecking = () => {
    timeoutError();
    if (profile.nama_depan.length < 3) {
      setText("Nama depan minimal 3 karakter");
      return false;
    }
    if (profile.no_hp.length < 9) {
      setText("Nomor Telpon minimal 9 karakter");
      return false;
    }
    if (profile.no_hp[0] != "0") {
      setText("Nomor Telpon tidak sesuai format 08xxxxxxx");
      return false;
    }
    if (profile.no_hp[1] != "8") {
      setText("Nomor Telpon tidak sesuai format 08xxxxxxx");
      return false;
    }
    if (profile.alamat.length < 20) {
      setText("Alamat minimal 20 karakter");
      return false;
    }
    return true;
  };

  const closePopup = () => {
    setProfile({ ...profile, ...data });
    setIsOpen(!isOpen);
  };

  return (
    <Modal isOpen={isOpen} className="modal_edit_profile" backdrop={true} centered>
      <form>
        {/* disini function ubah profile dipanggil. di line ini */}
        <div>
          {/* Kontak */}

          {text ? (
            <label
              style={{
                fontWeight: "0.7em",
                color: "#fff",
                width: "100%",
                padding: "0.5rem",
                backgroundColor: "#ED4140",
                borderRadius: "10px",
                opacity: "0.6",
              }}
            >
              {text}
            </label>
          ) : (
            ""
          )}
          <label
            style={{
              fontWeight: "bold",
              fontSize: "1.3em",
              width: "100%",
              marginBottom: "2rem",
            }}
          >
            Ubah Profil
          </label>
          <label
            className="text-divide-edit"
            id="text_edit"
            style={{ color: "#ED4140", fontWeight: "bold" }}
          >
            Biodata Diri
          </label>
          <div className="form-group row  mt-3">
            <label
              htmlFor="inputEmail3"
              className="col-sm-4 col-form-label"
              style={{
                fontSize: "0.9em",
                color: "#757575",
              }}
            >
              Nama Depan
            </label>
            <div className="col-sm-8 wrapper_input_edit_profile">
              <input
                type="text"
                className="form-control"
                value={profile.nama_depan}
                onChange={(e) => setProfile({ ...profile, nama_depan: e.target.value })}
                maxLength="25"
              />
              <small className="counter_char">{profile.nama_depan.length}/25</small>
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="inputEmail3"
              className="col-sm-4 col-form-label"
              style={{
                fontSize: "0.9em",
                color: "#757575",
              }}
            >
              Nama Belakang
            </label>
            <div className="col-sm-8 wrapper_input_edit_profile">
              <input
                type="text"
                className="form-control"
                value={profile.nama_belakang}
                onChange={(e) => setProfile({ ...profile, nama_belakang: e.target.value })}
                maxLength="25"
              />
              <small className="counter_char">{profile.nama_belakang.length}/25</small>
            </div>
          </div>
          <label
            className="text-divide-edit"
            id="text_edit_kontak"
            style={{
              color: "#ED4140",
              fontWeight: "bold",
              marginTop: "2rem",
            }}
          >
            Kontak
          </label>
          <div className="form-group row">
            <label
              htmlFor="inputEmail3"
              className="col-sm-4 col-form-label"
              style={{
                fontSize: "0.9em",
                color: "#757575",
              }}
            >
              Email
            </label>
            <div className="col-sm-8 wrapper_input_edit_profile">
              <input
                style={styleInput}
                type="email"
                className="form-control"
                value={profile.email}
                disabled
                maxLength="30"
              />
            </div>
          </div>
          <div className="form-group row wrapper_input_edit_profile">
            <label
              className="col-sm-4 col-form-label"
              style={{
                fontSize: "0.9em",
                color: "#757575",
              }}
            >
              No Handphone
            </label>
            <div className="col-sm-8 wrapper_input_edit_profile">
              <input
                style={styleInput}
                type="text"
                className="form-control"
                value={profile.no_hp}
                placeholder="08xx xxxx xxxx"
                maxLength="14"
                onChange={(e) => numeric(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              className="col-sm-4 col-form-label"
              style={{
                fontSize: "0.9em",
                color: "#757575",
              }}
            >
              Alamat
            </label>
            <div className="col-sm-8 wrapper_input_edit_profile">
              <textarea
                maxLength="50"
                style={styleInput}
                type="text"
                className="form-control"
                value={profile.alamat}
                onChange={(e) => setProfile({ ...profile, alamat: e.target.value })}
              />
              <small className="counter_char_alamat">{profile.alamat.length}/50</small>
            </div>
          </div>
        </div>
      </form>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          className="col-8 px-0"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem 1rem 0 1rem",
            float: "right",
          }}
        >
          <button className="btn-change-password text_btn_mobile" onClick={closePopup}>
            BATAL
          </button>
          <button className="btn-change-profile text_btn_mobile" onClick={saveClick}>
            SIMPAN
          </button>
        </div>
      </div>
    </Modal>
  );
}
const ubahProfile = () => {
  /* <div className="this-edit-profile">
          <label className="text-divide-edit">Ubah Profil</label>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
              Foto Profil
            </label>
            <div className="col-sm-8 col-custom-file">
              <div className="img-container-upload">
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  onChange={fileSelectHandler}
                  ref={(file) => (fileInput = file)}
                  accept="image/jpg, image/jpeg, image/png"
                />
                <div
                  className="file-input"
                  onClick={() => fileInput.click()}
                  style={{
                    backgroundImage: `url(${imageDispay()})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                  }}
                >
                  <div className="append-file">
                    <FaIcon icon="fas camera" className="fa-sm cam-icon" />
                  </div>
                </div>
              </div>
              <div className="div-direction">
                <label className="file-upload-name">
                  {file.isUpload ? file.name : "file_name.jpg"}
                </label>
                <br />
                <label className="file-direction" onClick={fileUploadHandler}>
                  Besar file: maksimum 2Mb <br />
                  Ekstensi file yang diperbolehkan: .JPG .JPEG .PNG
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
              Nama Depan
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                value={profile.nama_depan}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Nama Belakang</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                value={profile.nama_belakang}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Tanggal Lahir</label>
            <div className="col-sm-8">
              <input
                id="date-input"
                type="date"
                className="form-control"
                onChange={dateControl}
                min="1934-01-01"
                max={`${dateMax}`}
              />
            </div>
          </div>
          <fieldset className="form-group">
            <div className="row">
              <legend className="col-form-label col-sm-4 pt-0">
                Jenis Kelamin
              </legend>
              <div className="jk-content col-sm-8">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios1"
                    value="Laki - Laki"
                    checked={true}
                  />
                  <label className="form-check-label" htmlFor="gridRadios1">
                    Laki - laki
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios2"
                    value="Perempuan"
                  />
                  <label className="form-check-label" htmlFor="gridRadios2">
                    Perempuan
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
        </div> */
};
