import { FETCH_COMPLETE, FETCH_DATA, RESET, SET_LOADING } from "./Action";

export const addressState = {
  addressList: [],
  address: {},
  isLoading: false,
};

export const addressReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DATA:
      return { ...state, isLoading: true };
    case FETCH_COMPLETE:
      return { ...state, addressList: payload, isLoading: false };
    case "FETCH_ADDRESS":
      return { ...state, address: payload, isLoading: false };
    case SET_LOADING:
      return { ...state, isLoading: !state.isLoading };
    default:
      return { ...state };
  }
};
