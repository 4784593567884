const regex = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/
);
const regexNum = /^[0-9\b()]+$/;

const checkEmail = (e, user, setUser, setValidMail, isTemp, setTempEmail) => {
  if (isTemp) {
    setTempEmail(e.target.value);
  } else {
    setUser({ ...user, email: e.target.value });
  }
  if (regex.test(e.target.value)) {
    setValidMail(false);
  } else {
    setValidMail(true);
  }
};

const numericInput = (e, user, setUser, setIsNumber) => {
  if (e.target.value === "" || regexNum.test(e.target.value)) {
    if (e.target.value.length <= 14) {
      setUser({ ...user, no_hp: e.target.value });
    }
    setIsNumber(true);
  } else {
    setIsNumber(false);
  }
};

const maxDate = () => {
  const now = new Date();
  const seventenAgo = new Date();
  seventenAgo.setFullYear(
    now.getFullYear() - 17,
    now.getMonth() + 1,
    now.getDate()
  );
  const year = seventenAgo.getFullYear();
  const month = seventenAgo.getMonth();
  const day = seventenAgo.getDate();

  return `${year}-${month}-${day}`;
};

export { checkEmail, numericInput, maxDate };
