import React, { useState } from "react";
import "scss/footer/footer.scss";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import FaIcon from "../shared/font-awesome/FaIcon";

import PasarId from "assets/logo-pasarid.png";
import GooglePlay from "assets/google-play.png";
import AppStore from "assets/app-store.png";
import BRIlogo from "assets/bank-bri-logo-new.png";
import "./styles.scss";
import SweetAlert from "react-bootstrap-sweetalert";

export default function Footer() {
  const [currentLat, setCurrentLat] = useState(localStorage.getItem("@lat"));
  const [currentLong, setCurrentLong] = useState(localStorage.getItem("@long"));
  const [showLatLong, setShowLatLong] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const pageCart =
    location.pathname === "/cart" || location.pathname === "/checkout";
  const pageDetailProduk =
    location.pathname ===
    `/${params.subDomainPasar}/${params.idLapak}/${params.idProduct}`;
  const pageToken = location.pathname === `/auth/token/${params.token}`;

  let userAgent = navigator.userAgent || navigator.vendor;

  return (
    <div
      className={location.pathname === "/waiting" ? "footer-none" : "footer"}
      id="footer"
    >
      {/* <div className="row no-gutters padd">
        <div className="col-md-4">
          <div className="row row-logo">
            <div className="logo-pasar logo-pasar-footer col-md-12">
              <Link to="/">
                <img height="100px" src={PasarId} alt="pasar" />
              </Link>
            </div>
            <div className="row apps">
              <div className="col-md-6">
                <Link to="/">
                  <img src={GooglePlay} alt="google-play" />
                </Link>
              </div>
              <div className="col-md-6">
                <Link to="/">
                  <img src={AppStore} alt="app-store" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 row nav-foot">
          <div className="col-md-6 item-nav-foot">
            <h2>Pasar.id</h2>
            <ul>
              <li>
                <Link to="/">Tentang Pasar.id</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 item-nav-foot">
            <h2>Bisnis</h2>
            <ul>
              <li>
                <Link to="/">Menjadi Partner</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 item-nav-foot">
            <h2>Bantuan & Panduan</h2>
            <ul>
              <li>
                <Link to="#">Customer Service</Link>
              </li>
              <li>
                <Link to="#">Cara Belanja</Link>
              </li>
              <li>
                <Link>Syarat & Ketentuan</Link>
              </li>
              <li>
                <Link>Kebijakan & Privasi</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 item-nav-foot">
            <h2 className="follow-us" style={{ color: "#757575" }}>
              Ikuti Kami
            </h2>
            <div className="sosmed">
              <FaIcon
                className="ic"
                style={{ color: "#475993", marginLeft: "0px" }}
                icon="fab facebook-square"
                size="2x"
              />
              <FaIcon
                className="ic"
                style={{ color: "#00ACEE" }}
                icon="fab twitter-square"
                size="2x"
              />
              <FaIcon className="ic ig" icon="fab instagram-square" size="2x" />
            </div>

            <div className="mitra d-block d-md-none d-lg-none d-xl-none">
              <h2 className="our-mitra">Mitra Kami :</h2>
              <p className="mitra-bri">Bank Rakyat Indonesia</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row row-logo bri">
            <div className={`logo-pasar col-md-12 d-none d-md-block `}>
              <h2>Didukung Oleh</h2>
              <Link to="/">
                <img height="60px" src={BRIlogo} alt="bri" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="cr-footer">
        <div className="row wrapper-copy">
          <div className="col-md-12 copyright" style={{ textAlign: "center" }}>
            &copy;2021 Pasar id. All Right Reserved
          </div>
        </div>
      </div> */}
      <div
        className={
          pageCart
            ? "footer-container-cart"
            : pageDetailProduk
            ? "footer-detail-produk"
            : "footer-container"
        }
      >
        <div className={pageToken ? "footer-hide" : "logo-footer"}>
          <div>
            <img
              src={PasarId}
              alt="pasar"
              className="logo-pasar-image"
              onClick={() => setShowLatLong(!showLatLong)}
            />
          </div>
          <div
            className="cara-belanja"
            onClick={() => history.push("/carabelanja")}
          >
            <label>Panduan Cara Belanja</label>
          </div>
          <div className="cara-belanja" onClick={() => history.push("/faq")}>
            <label>FAQ</label>
          </div>
        </div>

        <div className="title-footer">
          <label className={pageToken ? "footer-hide" : "title-mitra-kami"}>
            Mitra Kami
          </label>
          <label className={pageToken ? "footer-hide" : ""}>
            Bank Rakyat Indonesia
          </label>
          <label className="title-copy-right">
            &copy;{new Date().getFullYear()} Pasar id. All Right Reserved
          </label>
        </div>
      </div>
      <SweetAlert
        show={showLatLong}
        onConfirm={() => setShowLatLong(false)}
        title={
          <>
            <p>Latitude: {currentLat}</p>
            <p>Longitude: {currentLong}</p>
            <p>Uset Agent: {userAgent}</p>
            <p>
              Env: {process.env.REACT_APP_DEFAULT_LATITUDE},{" "}
              {process.env.REACT_APP_DEFAULT_LONGITUDE}
            </p>
          </>
        }
      />
    </div>
  );
}
