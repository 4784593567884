import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import * as serviceWorker from "./serviceWorker"; // eslint-disable-line no-unused-vars
import "./scss/base.scss";
import { Provider } from "react-redux"; // eslint-disable-line no-unused-vars
import store from "./store"; // eslint-disable-line no-unused-vars
import fontawesome from "./plugins/fonstawesome"; // eslint-disable-line no-unused-vars
import "leaflet/dist/leaflet.css";

// import qiscus from './plugins/qiscus';

// window.addEventListener('DOMContentLoaded', () => {
//   qiscus.init({
//     AppId: 'qchatsdk--vlv0so6zyia'
//   })
// })

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// serviceWorker.register();
