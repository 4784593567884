import { FETCH_COMPLETE, FETCH_DATA, RESET, SET_LOADING } from "./Action";

export const orderState = {
  order: [],
  detailOrder: {},
  konfirmasiPembayaran: {},
  generateWhatsapp: {},
  isLoading: true,
};

export const OrderReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DATA:
      return { ...state, isLoading: true };
    case FETCH_COMPLETE:
      return { ...state, order: payload, isLoading: false };
    case SET_LOADING:
      return { ...state, isLoading: !state.isLoading };
    default:
      return { ...state };
  }
};

export const DetailOrderReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DATA:
      return { ...state, isLoading: true };
    case FETCH_COMPLETE:
      return { ...state, detailOrder: payload, isLoading: false };
    case SET_LOADING:
      return { ...state, isLoading: !state.isLoading };
    default:
      return { ...state };
  }
};

export const KonfirmasiPembayaranReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DATA:
      return { ...state, isLoading: true };
    case FETCH_COMPLETE:
      return { ...state, konfirmasiPembayaran: payload, isLoading: false };
    case SET_LOADING:
      return { ...state, isLoading: !state.isLoading };
    default:
      return { ...state };
  }
};

export const GenerateWhatsappReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DATA:
      return { ...state, isLoading: true };
    case FETCH_COMPLETE:
      return { ...state, generateWhatsapp: payload, isLoading: false };
    case SET_LOADING:
      return { ...state, isLoading: !state.isLoading };
    default:
      return { ...state };
  }
};
