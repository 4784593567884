import { FETCH_COMPLETE, FETCH_DATA } from "../../components/reducer/Action";

const formProduct = {
  category: {
    category: "",
  },
  cashier: {
    fullname: "",
    phone: "",
  },
  barcode: "",
  name: "",
  selling_price: "",
  stock: "",
  picture: "",
  status: "",
  unit: "",
};
export const productState = {
  productList: [],
  form: { ...formProduct },
  isLoading: false,
};

export default function ProductReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_DATA:
      return { ...state, isLoading: true };
    case FETCH_COMPLETE:
      return { ...state, productList: [...payload], isLoading: false };
    default:
      return { ...state };
  }
}
