import localId from "../shared/constants/ConstantsIdCustomer";
import cookieGuest from "../shared/constants/ConstantsGuestCustomer";

const { default: client, guests } = require("../../utils/axios");

async function getListOrder(type, page, limit) {
  const res = await client.get(
    `/api/v1/get_order_list?session_id=${localId}&type=${type}&page=${page}&limit=${limit}`
  );
  return res;
}

async function getListOrderGuest(type, page, limit) {
  const res = await guests.get(
    `/api/v1/guests/get_order_list?session_id=${cookieGuest}&type=${type}&page=${page}&limit=${limit}`
  );
  return res;
}

async function getOrderDetail(idUser, idOrder) {
  const { data } = await client.get(
    `/api/v1/get_order_detail?session_id=${localId}&order_id=${idOrder}`
  );
  return data;
}

async function getConfirmOrderGuest(invoice) {
  const { data } = await guests.get(
    `/api/v1/guests/confrm-orders?invoice=${invoice}`
  );
  return data;
}

async function guestOrder(
  id_pasar,
  tokenGuest,
  jamKirim,
  alamatGuest,
  parsedProductCheck
) {
  const form = {
    name_buyer: alamatGuest.nama,
    email_buyer: alamatGuest.email,
    address_buyer: alamatGuest.alamat,
    nohp_buyer: alamatGuest.noHp,
    latitude: alamatGuest.latitude.toString(),
    longitude: alamatGuest.longitude.toString(),
    estimate_ship_date: jamKirim,
    cookies_buyer: tokenGuest,
    orders: parsedProductCheck.map((res) => {
      return {
        product_id: `${res.idProduct}`,
        qty: res.quantity,
        store_id: +res.idLapak,
        note: res.catatan,
      };
    }),
  };
  const { data } = await guests.post(
    `/api/v1/guests/orders-items/${id_pasar}/pasar`,
    form
  );
  return data;
}

async function getPesananGuest(orderId) {
  const { data } = await guests.get(
    `/api/v1/guests/order_detail?session_id=${cookieGuest}&order_id=${orderId}`
  );
  return data;
}

async function getTrackingGuest(orderId) {
  const { data } = await guests.get(
    `/api/v1/guests/track_order?session_id=${cookieGuest}&orderId=${orderId}`
  );
  return data;
}

async function getTrackingOrder(idUser, idOrder) {
  const { data } = await client.get(
    `/api/v1/track_order?session_id=${localId}&orderId=${idOrder}`
  );
  return data;
}

async function getPayment(idOrder, isGuest) {
  const { data } = await client.get(
    `/api/v1/get_pembayaran?session=${
      localId ? localId : cookieGuest
    }&order=${idOrder}&is_guest=${isGuest || ""}`
  );
  return data;
}

async function confirmationPayment(dataKonfirmasi, isGuest) {
  const form = {
    session: localId ? localId : cookieGuest,
    id_pasar: dataKonfirmasi.idPasar,
    id_order: dataKonfirmasi.idOrder,
    nama_pengirim: dataKonfirmasi.namaPengirim,
    nama_bank: dataKonfirmasi.namaBank,
    norek: dataKonfirmasi.norek,
    is_guest: isGuest?.toString() || "",
  };
  const { data } = await client.post(`/api/v1/confirmation_payment`, form);
  return data;
}

async function confirmPayment(idUser, idOrder) {
  const { data } = await client.get(
    `/api/v1/confirm_payment?session_id=${localId}&orderId=${idOrder}`
  );
  return data;
}

async function cancelOrder(idUser, idOrder) {
  const { data } = await client.post(
    `/api/v1/expired_payment_trigger?session_id=${localId}&orderId=${idOrder}`
  );
  return data;
}

async function saveInfoBilling(form) {
  let data = new FormData();
  data.append("id_pasar", form.idPasar);
  data.append("id_order", form.idOrder);
  data.append("session_id", localId);
  data.append("nama_pengirim", form.payment_info.nama_pengirim);
  data.append("norek", form.payment_info.norek);
  data.append("nama_bank", form.payment_info.nama_bank);
  const response = await client.post(`/api/v1/save_info_pengirim`, data);
  return response;
}

async function generateWhatsapp(idOrder) {
  const { data } = await client.get(
    `/api/v1/buyer_confirmation?session_id=${localId}&order_id=${idOrder}`
  );
  return data;
}

async function getInfoPengirim(idOrder) {
  const res = await client.get(
    `/api/v1/get_info_pengirim?session_id=${localId}&order_id=${idOrder}`
  );
  return res;
}

async function saveOrderDetailFinished(idOrder) {
  const { data } = await client.put(
    `/api/v1/order_confirm_complete/${idOrder}/7?sessionId=${localId}`
  );
  return data;
}

async function saveOrderDetailFinishedGuest(orderId) {
  const { data } = await guests.put(
    `/api/v1/guests/order_confirm_complete/${orderId}/7?sessionId=${cookieGuest}`
  );
  return data;
}

async function getListUlasan(limit) {
  const res = await client.get(
    `/api/v1/ulasan?limit=${limit}&sessionId=${localId}`
  );
  return res;
}

async function getDetailUlasan(ulasan_id) {
  const res = await client.get(
    `/api/v1/ulasan_detail/${ulasan_id}?sessionId=${localId}`
  );
  return res;
}

async function updateUlasan(dataUlasan) {
  const { data } = await client.put(
    `/api/v1/ulasan?sessionId=${localId}`,
    dataUlasan
  );
  return data;
}

async function deleteUlasan(ulasan_id) {
  const data = await client.delete(
    `/api/v1/ulasan_lampiran/${ulasan_id}?sessionId=${localId}`
  );
  return data;
}

async function getListUlasanProduct(product_id, params) {
  if (Boolean(product_id)) {
    const { data } = await client.get(`/api/v1/ulasan_product/${product_id}`, {
      params,
    });
    return data;
  }
}

async function getUlasanProduct(product_id, lapak_id) {
  if (Boolean(product_id) && Boolean(lapak_id)) {
    const { data } = await client.get(
      `/api/v1/ulasan_product_summary/${product_id}?lapak_id=${lapak_id}`
    );
    return data;
  }
}

async function getUlasanLampiran(product_id, lapak_id) {
  const { data } = await client.get(
    `/api/v1/ulasan_product_lampiran/${product_id}?lapak_id=${lapak_id}`
  );
  return data;
}

async function uploadImgUlasan1(formData) {
  const data = await client.post(`/src/assets`, formData);
  return data;
}

async function uploadImgUlasan(formData) {
  const data = await client.post(
    `/api/v1/upload_document?sessionId=${localId}`,
    formData
  );
  return data;
}

async function getLapakByPasar(dataPasar) {
  const { data } = await client.post(`/api/v1/get_lapak_by_pasar`, dataPasar);
  return data;
}

async function saveStatusKepuasan(ulasan_id, status, ulasanId) {
  const { data } = await client.put(
    `/api/v1/ulasan_status_kepuasan/${ulasan_id}?sessionId=${localId}`,
    { status: status }
  );
  return data;
}

async function getUlasanId(idPasar, idOrder, status) {
  const form = {
    session: localId,
    id_pasar: idPasar,
    id_order: +idOrder,
    status: status,
  };
  const { data } = await client.post(`api/v1/status_kepuasan`, form);
  return data;
}

export {
  getListOrder,
  getListOrderGuest,
  getOrderDetail,
  getTrackingOrder,
  getPayment,
  confirmationPayment,
  confirmPayment,
  cancelOrder,
  saveInfoBilling,
  generateWhatsapp,
  getInfoPengirim,
  saveOrderDetailFinished,
  getListUlasan,
  getListUlasanProduct,
  getUlasanProduct,
  getUlasanLampiran,
  getDetailUlasan,
  updateUlasan,
  deleteUlasan,
  uploadImgUlasan,
  getLapakByPasar,
  saveStatusKepuasan,
  getUlasanId,
  getConfirmOrderGuest,
  guestOrder,
  getTrackingGuest,
  getPesananGuest,
  saveOrderDetailFinishedGuest,
};
