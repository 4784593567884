import axios from "axios";
import token from "../components/shared/constants/ConstToken";
import https from "https";
import expiredStorage from "../components/utils/expiredStorage";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    token: `${token}`,
  },

  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: false,
  // }),
});

const tokenChat = expiredStorage.getItem("@tokenChat")

export const guests = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    token: `${token}`,
  },
});

export const ApiChat = axios.create({
  baseURL: `${process.env.REACT_APP_API_CHAT_URL}`,
  headers: {
    Authorization:  `Bearer ${tokenChat}`
  },
});

export default client;
