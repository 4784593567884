import { loadState } from "./localStorage";

export const config = () => {
  const tokenLogin = loadState().auth.token;
  return {
    headers: {
      Authorization: "Bearer " + tokenLogin,
    },
  };
};

export const convertDate = (item) => {
  const newDate = new Date(item);
  const date = newDate.getDate();
  const months = newDate.getMonth();
  const year = newDate.getFullYear();
  const month = monthName[months];
  let h = newDate.getHours().toString();
  let m = newDate.getMinutes().toString();
  let s = newDate.getSeconds().toString();
  if (h.length < 2) {
    h = `0${h}`;
  }
  if (m.length < 2) {
    m = `0${m}`;
  }
  if (s.length < 2) {
    s = `0${s}`;
  }

  const dates = date < 10 ? "0" + date : date;
  const dTime = h + ":" + m + ":" + s;
  return {
    dates,
    date,
    month,
    year,
    dTime,
    full: `${date} ${month} ${year}`,
    h,
    m,
    s,
    months,
  };
};

export const monthName = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const monthNameEng = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const splitDate = (date) => {
  const dd = date.split(" ");
  const dateString = date;
  const convDate = new Date(dateString.replace(" ", "T"));
  const hours = convDate.getHours() < 10 ? `0` + convDate.getHours() : convDate.getHours();
  const toHours =
    convDate.getHours() < 10 ? "0" + (convDate.getHours() + 1) : convDate.getHours() + 1;
  const to3Hours =
    convDate.getHours() < 10 ? "0" + (convDate.getHours() + 3) : convDate.getHours() + 3;

  const minutes = convDate.getMinutes() < 10 ? `0` + convDate.getMinutes() : convDate.getMinutes();

  return {
    full: `${convDate.getDate()} ${monthName[convDate.getMonth()]} ${convDate.getFullYear()}`,
    monthYear: `${monthName[convDate.getMonth()]} ${convDate.getFullYear()}`,
    month: `${monthName[convDate.getMonth()]}`,
    time: `${dd[1]}`,
    trackdate: `${convDate.getDate()} ${
      monthName[convDate.getMonth()]
    } ${convDate.getFullYear()}, ${hours}:${minutes}`,
    timeShip: `${convDate.getDate()} ${
      monthName[convDate.getMonth()]
    } ${convDate.getFullYear()}, ${hours}:00-${toHours}:00`,
    convDate: convDate,
    formatMdn: `${
      monthNameEng[convDate.getMonth()]
    } ${convDate.getDate()}, ${convDate.getFullYear()} ${to3Hours}:${minutes}:${convDate.getSeconds()}`,
  };
};

export const capitalize = (s) => {
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
};

export const capitalizeEach = (s) => {
  if (s) {
    return s.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  }
};

export const capitalizeAll = (w) => {
  return w.toUpperCase();
};

export const numberFormat = (num) => {
  return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export function getTimeStamp(input) {
  var parts = input.split(" ");
  var date = parts[0].split("-");
  var time = (parts[1] ? parts[1] : "00:00:00").split(":");

  // NOTE:: Month: 0 = January - 11 = December.
  var d = new Date(date[0], date[1] - 1, date[2], time[0], time[1], time[2]);
  return d.getTime() / 1000;
}

export const getTimeFromTimestamp = (ts) => {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  var date = new Date(ts * 1000);
  // Hours part from the timestamp
  var hours = date.getHours();
  // Minutes part from the timestamp
  var minutes = date.getMinutes();
  // Seconds part from the timestamp
  var seconds = date.getSeconds();

  return { hours, minutes, seconds };
};

export const splitParams = (params) => {
  const param = params.split("-");
  const id = param[param.length - 1];
  const name = param[0];
  return { id, name };
};

export const debounce = (callback, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), delay);
  };
};
