import { Modal } from "reactstrap";
import React from "react";
import "../../scss/home/modalsubmit.scss";

const ModalAdminNotExist = (props) => {
  let noHp = "";
  if (props.noLapak) {
    if (props.noLapak[0] != 0) noHp = props.noLapak;
    else noHp = "+62" + props.noLapak.substring(1);
  }
  const whatsappLink = `https://api.whatsapp.com/send?phone=+${noHp}`;

  return (
    <div style={{ width: "80%", maxWidth: "600px" }}>
      <Modal
        style={{ overlay: { backgroundColor: "rgba(0,0,0,0.4)" } }}
        className="modal__admin"
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        toggle={props.onRequestClose}
        centered={true}
      >
        <div style={{ width: "100%", textAlign: "center" }}>
          <label>
            Mohon maaf {props.pasarName} belum memiliki admin belanja pasar, silahkan hubungi
            pedagang {props.sellerName} untuk melakukan pesanan.
          </label>
        </div>
        <div className="button-confirm-modal">
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
            <button
              className="button-modal"
              onClick={props.onRequestClose}
              style={{ backgroundColor: "#7A8D3F", color: "white" }}
            >
              Hubungi Penjual
            </button>
          </a>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAdminNotExist;
