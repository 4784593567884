import React, { useEffect, useState } from "react";
import "../../../scss/footer/verifikasi.scss";
import Logo from "../../../assets/logo-pasarid.png";
import { Link, useParams, useHistory } from "react-router-dom";
import FaIcon from "../../../components/shared/font-awesome/FaIcon";
import {
  resendActivation,
  verifikasiAkun,
} from "../../../components/services/CustomerService";
import SweetAlert from "react-bootstrap-sweetalert";
import Skeleton from "react-loading-skeleton";
import { Spinner } from "reactstrap";
import Page from "./Page";

export default function VerifikasiAkun() {
  const { token } = useParams();
  const history = useHistory();
  const [sukses, setSukses] = useState(false);
  const [showMail, setShowMail] = useState(true);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");
  const [textAlert, setTextAlert] = useState(false);
  const [mail, setMail] = useState({ email: "" });

  useEffect(() => {
    // setLoading(true)
    verifikasiAkun(token)
      .then((res) => {
        setSukses(true);
        setLoading(false);
      })
      .catch((err) => {
        setSukses(false);
        setLoading(false);
        errResponse(err.response.data.Deskripsi);
      });
  }, []);

  const onSubmit = () => {
    resendActivation(mail)
      .then((res) => {
        setAlert(true);
        setMail({ ...mail, email: "" });
      })
      .catch((err) => {
        setTextAlert(true);
      });
  };

  const errResponse = (message) => {
    switch (message) {
      case "Your account already active":
        setText("Akun Anda sudah aktif.");
        break;
      case "Activation Link Expired":
        setText(
          "Link aktivasi sudah kedaluwarsa. Silahkan kirim ulang ke email Anda."
        );
        setShowMail(true);
        break;
      default:
        setText("Gagal aktivasi");
        break;
    }
  };

  const viewScreen = () => {
    let view = (
      <div style={{ padding: "10px" }}>
        <Skeleton height={200} />
      </div>
    );
    if (loading === false) {
      if (sukses) {
        view = (
          <div className="succes-aktivasi">
            <FaIcon icon="fas check-circle" className="icon fa-4x" />
            <label className="title-verification">Selamat!</label>
            <label className="title-verification">Akun Anda telah aktif.</label>
            <small className="title-small">
              Anda sudah bisa log in dan belanja sekarang di{" "}
              <span>Pasar.Id</span>
            </small>
            <button
              className="button-belanja-sekarang"
              onClick={() => history.push("/login")}
            >
              BELANJA SEKARANG
            </button>
          </div>
        );
      } else if (!sukses) {
        view = (
          <div className="failed-aktivasi">
            <FaIcon icon="far clock" className="icon-fail fa-4x" />
            <label className="title-verification">Link Expired</label>
            <small className="title-small">
              Link aktivasi sudah lebih dari 24 Jam. Silahkan lakukan registrasi
              kembali.
            </small>
            <button
              className="button-register-sekarang"
              onClick={() => history.push("/register")}
            >
              DAFTAR BARU
            </button>
            {/* {showMail ? (
              <div className="email-resent">
                <div class="form-group">
                  <label>Kirim ulang kode aktivasi ke email</label>
                  <div className="input-grp mb-3">
                    <input
                      value={mail.email}
                      type="email"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Masukkan email Anda"
                      onChange={(e) =>
                        setMail({ ...mail, email: e.target.value })
                      }
                    />
                    <button
                      onClick={() => onSubmit()}
                      className="btn-danger"
                      type="button"
                    >
                      Kirim
                    </button>
                  </div>
                </div>
              </div>
            ) : null} */}
          </div>
        );
      }
    }

    return view;
  };

  return (
    // <Page>
    //   <div className="container-verifikasi">
    //     <div className="content-verifikasi">{viewScreen()}</div>
    //   </div>
    //   <SweetAlert
    //     success
    //     show={alert}
    //     title={"Email aktivasi telah dikirim"}
    //     onConfirm={() => setAlert(false)}
    //     confirmBtnBsStyle="danger"
    //   />
    //   <SweetAlert
    //     danger
    //     show={textAlert}
    //     title={"Akun tidak ada"}
    //     onConfirm={() => setTextAlert(false)}
    //     confirmBtnBsStyle="danger"
    //   />
    // </Page>
    <div style={{ position: "relative", height: "100vh" }}>
      <nav className="menu-area-activation">
        <Link to="/">
          <img height="60px" src={Logo} alt="logo pasarid" />
        </Link>
      </nav>
      <div className="container-verifikasi">
        <div className="content-verifikasi">{viewScreen()}</div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          marginTop: "10px",
          fontWeight: "300",
          fontFamily: "Lato",
          padding: "15px",
          alignItems: "center",
          boxShadow: "0px 3px 10px 1px rgba(0, 0, 0, 0.15)",
        }}
      >
        <p className="text-center p-1 footer-p">
          &copy;{new Date().getFullYear()} Pasar id. All Right Reserved
        </p>
      </div>
      <SweetAlert
        success
        show={alert}
        title={"Email aktivasi telah dikirim"}
        onConfirm={() => setAlert(false)}
        confirmBtnBsStyle="danger"
      />
      <SweetAlert
        danger
        show={textAlert}
        title={"Akun tidak ada"}
        onConfirm={() => setTextAlert(false)}
        confirmBtnBsStyle="danger"
      />
    </div>
  );
}
