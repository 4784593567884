import {
  FETCH_CHECKOUT,
  FETCH_COMPLETE_CHECKOUT,
  SET_LOADING,
} from "../../components/reducer/Action";

const cartForm = {
  id: "",
  id_order: "",
  id_user: "",
  id_produk: "",
  id_lapak: "",
  id_pasar: "",
  transaction_type: "",
  quantity: 0,
  date_created: "",
  date_updated: "",
  price: 0,
  unit: "",
};

export const checkoutState = {
  checkout: [],
  // form: { ...cartForm },
  isLoading: false,
  reload: false,
};

export default function CheckoutReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CHECKOUT:
      return { ...state, isLoading: true };
    case FETCH_COMPLETE_CHECKOUT:
      return { ...state, checkout: payload, isLoading: false };
    case SET_LOADING:
      return { ...state, reload: !state.reload };
    default:
      return { ...state };
  }
}
