import "../../scss/dropdown/breadcrumb.scss";
import React from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

const BreadCrumbs = (props) => {
  const {
    history,
    location: { pathname, state },
    idpasar,
  } = props;

  const pathnames = pathname.split("/").filter((x) => x);
  return (
    <MUIBreadcrumbs
      className={props.cssClassName || ""}
      aria-label="breadcrumb"
    >
      {pathnames.length > 0 ? (
        <Link onClick={() => history.push("/")}>Home</Link>
      ) : (
        <Typography> Home </Typography>
      )}
      {pathnames.map((name, index) => {
        if (name.includes("-")) {
          const name_splitted = name.split("-");
          const routeTo = {
            pathname: `/${pathnames.slice(0, index + 1).join("/")}`,
            state: { id: state, idpasar: idpasar },
          };
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Typography
              className={`${
                window.location.href.indexOf(name_splitted[0]) > -1
                  ? "bold"
                  : ""
              }`}
              key={index}
            >
              {name_splitted[0]}
            </Typography>
          ) : (
            <Link
              className={`breadcrumb-item ${
                window.location.href.indexOf(name_splitted[0]) > -1
                  ? "bold"
                  : ""
              }`}
              key={name_splitted[0]}
              onClick={() => history.push(routeTo)}
            >
              {name_splitted[0]}
            </Link>
          );
        } else {
          const routeTo = {
            pathname: `/${pathnames.slice(0, index + 1).join("/")}`,
            state: { id: state, idpasar: idpasar },
          };
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Typography
              className={`${
                window.location.href.indexOf(name) > -1 ? "bold" : ""
              }`}
              key={index}
            >
              {name}
            </Typography>
          ) : (
            <Link
              className={`breadcrumb-item ${
                window.location.href.indexOf(name) > -1 ? "bold" : ""
              }`}
              key={name}
              onClick={() => history.push(routeTo)}
            >
              {name}
            </Link>
          );
        }
      })}
    </MUIBreadcrumbs>
  );
};

export default withRouter(BreadCrumbs);
