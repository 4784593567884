const { default: client } = require("../../utils/axios");

async function registerWithGoogle(form) {
  let data = new FormData();
  data.append("token", form);
  const response = await client.post(
    "/api/v1/google/register_with_google",
    data,
    {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    }
  );
  return response;
}

async function loginWithGoogle(form) {
  let data = new FormData();
  data.append("token", form);
  const response = await client.post("/api/v1/google/login_with_google", data, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
  });
  return response;
}

async function registerWithFacebook(form) {
  let data = new FormData();
  data.append("user_id", form.userId);
  data.append("token", form.token);
  const response = await client.post(
    "/api/v1/facebook/register_with_facebook",
    data,
    {
      headers: {
        "Content-Type": "text/plain",
      },
    }
  );
  return response;
}

async function loginWithFacebook(form) {
  let data = new FormData();
  data.append("user_id", form.userId);
  data.append("token", form.token);
  const response = await client.post(
    "/api/v1/facebook/login_with_facebook",
    data,
    {
      headers: {
        "Content-Type": "text/plain",
      },
    }
  );
  return response;
}

export {
  loginWithGoogle,
  registerWithGoogle,
  loginWithFacebook,
  registerWithFacebook,
};
