import React from "react";
import CurrencyFormat from "react-currency-format";

const currencyFormat = (value) => {
  return (
    <CurrencyFormat
      value={value}
      displayType={"text"}
      thousandSeparator={"."}
      decimalSeparator={","}
    />
  );
};

export default currencyFormat;
