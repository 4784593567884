import { combineReducers } from "redux";

import chat from "./chat/reducer";
import product from "./product/reducer";
import pasar from "./pasar/reducer";
import pedagang from "./pedagang/reducer";
import commons from "./commons/reducer";
import faq from "./faq/reducer";
import wishlist from "./wishlist/reducer";

const createRootReducer = () =>
  combineReducers({
    chat,
    productReducer: product,
    pasarReducer: pasar,
    pedagangReducer: pedagang,
    commonsReducer: commons,
    faqReducer: faq,
    wishlistReducer: wishlist,
  });

export default createRootReducer;
