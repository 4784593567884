import { createContext } from "react";

const productContext = createContext({
  products: [],
  cart: [],
  checkout: [],
  addToCart: (product) => {},
  removeFromCart: (productId) => {},
  listTobuy: "",
  listProduct: [],
  profile: "",
  mdlCheckout: "",
  setMdlChckout: "",
});

export default productContext;
