import L from "leaflet";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { Modal } from "reactstrap";
import markerIcon from "../../assets/marker-pin.svg";
import NoDataFound from "../../containers/layout/page/NoDataFound";
import "../../scss/form/modal.scss";
import targetIcon from "../../assets/icon/icon-target.svg";
import FaIcon from "../shared/font-awesome/FaIcon";
import ModalAdminNotExist from "./ModalAdminNotExist";
import "../../../node_modules/leaflet-geosearch/dist/geosearch.css";
import { getKotaOpenstreetMap } from "../../components/services/LocationService";

let myIcon = L.icon({
  iconUrl: markerIcon,
  iconSize: [40, 60],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

let searchIcon = L.icon({
  iconUrl: markerIcon,
  iconSize: [0, 0],
  iconAnchor: [0, 0],
  popupAnchor: [0, 0],
});

function Maps(props) {
  const { modal, setModal, setLatLong, latLong, isDetailPasar, setLokasi } =
    props;
  const lat = parseFloat(
    localStorage.getItem("@lat") ||
      parseFloat(process.env.REACT_APP_DEFAULT_LATITUDE)
  );
  const long = parseFloat(
    localStorage.getItem("@long") ||
      parseFloat(process.env.REACT_APP_DEFAULT_LONGITUDE)
  );
  let position = { lat, long };
  const [isGps, setIsGps] = useState(true);
  const [locationName, setLocationName] = useState("");
  const [detailLocation, setDetailLocation] = useState("");

  const history = useHistory();

  const email = process.env.REACT_APP_EMAIL_MAPS;

  const [map, setMap] = useState(null);
  const [pos, setPosition] = useState({ ...position });
  const [data, setData] = useState();
  const markerRef = useRef(null);
  const eventHandlers = useMemo((e) => {
    return {
      dragend: async () => {
        const marker = markerRef.current;
        if (marker != null) {
          const latLong = marker.getLatLng();
          setPosition({
            ...pos,
            lat: latLong.lat,
            long: latLong.lng,
          });
          sessionStorage.setItem("lat", latLong.lat);
          sessionStorage.setItem("long", latLong.lng);
        }
      },
    };
  }, []);

  useEffect(() => {
    setPosition({ ...pos, lat: lat, long: long });
  }, [lat, long]);

  const SearchField = ({ email }) => {
    const provider = new OpenStreetMapProvider({
      params: {
        email: email,
        countrycodes: "ID",
        "accept-language": "ID",
        addressdetails: 1,
      },
    });

    // @ts-ignore
    const searchControl = new GeoSearchControl({
      notFoundMessage: "Sorry, that address could not be found.",
      provider: provider,
      popupFormat: ({ query, result }) => {
        setPosition({ ...pos, lat: result.y, long: result.x });
        sessionStorage.setItem("lat", result.y);
        sessionStorage.setItem("long", result.x);
        return result.label;
      },
      marker: { icon: searchIcon },
    });

    const map = useMap();
    useEffect(() => {
      map.addControl(searchControl);
      return () => map.removeControl(searchControl);
    }, []);

    return null;
  };

  function onLocationFound(e) {
    setPosition({ ...pos, lat: e.latlng.lat, long: e.latlng.lng });
    sessionStorage.setItem("lat", e.latlng.lat);
    sessionStorage.setItem("long", e.latlng.lng);
  }

  function getLocationLeaflet() {
    map.on("locationfound", onLocationFound);

    map.locate({ setView: true, maxZoom: 16 });
  }

  if (map && isDetailPasar === false) {
    map.on("click", function (e) {
      if (
        parseFloat(sessionStorage.long) !== e.latlng.lng &&
        parseFloat(sessionStorage.lat) !== e.latlng.lat
      ) {
        setPosition({ lat: e.latlng.lat, long: e.latlng.lng });
        sessionStorage.setItem("lat", e.latlng.lat);
        sessionStorage.setItem("long", e.latlng.lng);
      }
    });
  }

  useEffect(() => {
    if (latLong.latitude !== "") {
      setPosition({ lat: latLong.latitude, long: latLong.longitude });
      sessionStorage.setItem("lat", latLong.latitude);
      sessionStorage.setItem("long", latLong.longitude);
    } else {
      if (lat && long) setIsGps(true);
      // else setIsGps(false);
    }
  }, [modal]);

  useEffect(() => {
    if (pos) {
      fetch(
        `https://nominatim.openstreetmap.org/reverse.php?lat=${pos.lat}&lon=${pos.long}&zoom=18&format=jsonv2`
      )
        .then((res) => res.json())
        .then((data) => {
          setLocationName(
            data.name ||
              data.address?.neighbourhood ||
              data.address?.residential
          );
          setDetailLocation(data.display_name);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [pos]);

  const btnClick = () => {
    setModal(!modal);
    setLatLong({
      ...latLong,
      latitude: pos.lat,
      longitude: pos.long,
    });
    if (history.location.pathname === "/") {
      localStorage.setItem("@lat", parseFloat(sessionStorage.lat));
      localStorage.setItem("@long", parseFloat(sessionStorage.long));
      sessionStorage.setItem("latPosition", parseFloat(sessionStorage.lat));
      sessionStorage.setItem("longPosition", parseFloat(sessionStorage.long));
      window.location.reload();
    }
  };
  const generateMap = () => {
    return isGps ? (
      <MapContainer
        center={[pos.lat, pos.long]}
        zoom={isDetailPasar ? 15 : 16}
        scrollWheelZoom={true}
        className="modal-map-show"
        id="map"
        whenCreated={setMap}
      >
        <SearchField email={email} className="search-field" />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          position={[pos.lat, pos.long]}
          icon={myIcon}
          draggable={true}
          eventHandlers={eventHandlers}
          ref={markerRef}
        >
          {isDetailPasar ? (
            <Popup>
              <label style={{ fontWeight: "bold" }}>{latLong.nama}</label>
              <br />
              <label>{latLong.alamat}</label>
            </Popup>
          ) : (
            <Popup>{`Latitude: ${pos.lat}, Longitude: ${pos.long}`}</Popup>
          )}
        </Marker>
        <div className="current-location" onClick={getLocationLeaflet}>
          <img
            src={targetIcon}
            alt="current"
            className="current-location-icon"
          />
        </div>
      </MapContainer>
    ) : (
      <NoDataFound
        error={true}
        text="GPS Anda tidak terdeteksi. Silahkan aktifkan terlebih dahulu."
        text2="Cek pengaturan GPS anda untuk mengaktifkan"
        map={true}
      />
    );
  };
  return (
    <Modal centered isOpen={modal} toggle={() => setModal(!modal)} backdrop>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <div className="container-icon-arrow">
          <div>
            <FaIcon
              icon="fas arrow-left"
              className="icon-arrow-left"
              onClick={() => setModal(!modal)}
            />
          </div>
          <div className="label-lokasi">
            <label>Lokasi</label>
          </div>
        </div>
        {generateMap()}
        <div className="container-location">
          <label className="location-name">{locationName}</label>
          <label className="detail-location">{detailLocation}</label>
        </div>
        {!isGps ? (
          <button
            style={{ margin: "1rem auto" }}
            className="btn-set-address"
            onClick={() => setModal(!modal)}
          >
            Aktifkan GPS
          </button>
        ) : isDetailPasar ? (
          ""
        ) : (
          <button
            style={{ margin: "1rem auto" }}
            className="btn-set-address"
            onClick={btnClick}
          >
            Pilih Lokasi
          </button>
        )}
      </div>
    </Modal>
  );
}
export default Maps;
