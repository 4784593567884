import * as types from "./actions";

const initialState = {
  loading: false,
  markets: [],
  pageMarket: null,
  totalMarket: null,
  error: null,
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_DATA:
      return {
        ...state,
        markets: action?.isInit ? action.payload.Data : [...state.markets, ...action.payload.Data],
        pageMarket: action.payload.Total_Page,
        totalMarket: action.payload.Total_Pasar,
      };
    case types.CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}
