import { encryptText } from "../../utils/encrypt";
import clientFormData from "../../utils/formDataClient";
import setting from "../shared/constants/ConstantServiceHeader";
import localId from "../shared/constants/ConstantsIdCustomer";

const { default: client } = require("../../utils/axios");

async function registerCustomer(form) {
  const psswd = encryptText(form.password);
  let data = new FormData();
  data.append("nama", form.nama);
  data.append("email", form.email);
  data.append("no_hp", form.no_hp);
  data.append("password", psswd);
  const response = await client.post("api/v1/register", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
}

async function lupaPassword(email) {
  let data = new FormData();
  data.append("email", email);
  const response = await client.post("api/v1/lupa_password", data);
  return response;
}

async function resetPassword(token, password) {
  let data = new FormData();
  data.append("password", encryptText(password));
  const response = await client.post(
    "api/v1/reset_password?token=" + token,
    data,
    setting
  );
  return response;
}

async function checkTokenResetPassword(token) {
  const response = await client.post(
    "api/v1/check_lupa_password?token=" + token
  );
  return response;
}

async function loginCustomer(form) {
  const data = { email: form.email, password: encryptText(form.password) };
  const response = await client.post("/api/v1/login_form", data);

  return response;
}

async function getProfileCustomer() {
  const response = await client.post(
    "api/v1/get_profile_customer?sessionId=" + localId
  );
  return response;
}

async function updateProfileCustomer(form) {
  let data = new FormData();
  data.append("nama_depan", form.nama_depan);
  data.append("nama_belakang", form.nama_belakang);
  data.append("email", form.email);
  data.append("password", form.password);
  data.append("alamat", form.alamat);
  data.append("no_hp", form.no_hp);
  const response = await clientFormData.patch(
    `/api/v1/update_profile_customer?sessionId=${localId}`,
    data
  );
  return response;
}

async function updatePassword(id, form) {
  const oldPass = encryptText(form.oldPassword);
  const newPass = encryptText(form.newPassword);
  const verifyPass = encryptText(form.confirmPassword);
  let data = new FormData();
  data.append("password_lama", oldPass);
  data.append("password_baru", newPass);
  data.append("confirm_password", verifyPass);
  const response = await client.patch(
    `/api/v1/update_password_customer?sessionId=${id}`,
    data
  );

  return response;
}

async function logoutCustomer() {
  const res = await client.post(`/api/v1/logout?sessionId=${localId}`);
  return res;
}

async function verifikasiAkun(token) {
  const res = await client.post(`/api/v1/activation_account?token=${token}`);
}

async function resendActivation(email) {
  let data = new FormData();
  data.append("email", email.email);
  const res = await client.post(`/api/v1/resend_activation_link`, data);
}

async function getListAddress() {
  const data = await client.get(`/api/v1/purchase_form?sessionId=${localId}`);
  return data;
}

async function updateFirstAddress(idAddress) {
  let form = { info_pembelian_id: encryptText(idAddress) };
  const data = await client.patch(
    `/api/v1/pick_information_purchase?sessionId=${localId}`,
    form
  );
  return data;
}

async function deleteAddress(idAddress) {
  const enc = encryptText(idAddress);
  const encSlash = enc.replaceAll("/", "&sol;");
  const data = await client.delete(
    `/api/v1/purchase_form/${encSlash}?sessionId=${localId}`
  );
  return data;
}

async function saveAddress(res) {
  let form = {
    judul: res.title,
    name: res.receiver,
    nomor_hp: res.hp,
    alamat: res.address,
    latitude: res.latitude.toString(),
    longitude: res.longitude.toString(),
  };
  const data = await client.post(
    `/api/v1/purchase_form?sessionId=${localId}`,
    form
  );
  return data;
}

async function updateAddress(res) {
  let form = {
    info_pembelian_id: encryptText(res.id),
    judul: res.title,
    name: res.receiver,
    nomor_hp: res.hp,
    alamat: res.address,
    latitude: res.latitude.toString(),
    longitude: res.longitude.toString(),
  };
  const data = await client.patch(
    `/api/v1/purchase_form?sessionId=${localId}`,
    form
  );

  return data;
}

async function getReview(limit, day, stat, s) {
  const days = day ? `&day=${day}` : "";
  const status = stat ? `&status=${stat}` : "";
  const search = s ? `&search=${s}` : "";
  const data = await client.get(
    `/api/v1/ulasan?limit=${limit}&sessionId=${localId}` +
      days +
      status +
      search
  );
  return data;
}

async function getReviewBySearch(limit, search) {
  const data = await client.get(
    `/api/v1/ulasan?limit=${limit}&sessionId=${localId}&search=${search}`
  );
  return data;
}

async function getPassChat() {
  const data = await client.get(`/api/v1/user-chat-pass?sessionId=${localId}`);
  return data;
}

async function loginSocmedHide() {
  const data = await client.get(`/api/v1/btn-hide`);
  return data;
}

export {
  registerCustomer,
  loginCustomer,
  getProfileCustomer,
  updateProfileCustomer,
  lupaPassword,
  resetPassword,
  updatePassword,
  logoutCustomer,
  verifikasiAkun,
  resendActivation,
  getListAddress,
  saveAddress,
  updateFirstAddress,
  deleteAddress,
  updateAddress,
  getReview,
  getReviewBySearch,
  checkTokenResetPassword,
  getPassChat,
  loginSocmedHide,
};
