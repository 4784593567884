import React, { useState, useCallback, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

// assets
import PasarId from "../../assets/logo-pasarid.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useVisibilityComponent } from "../utils/condition";

import "../../scss/footer/footer.scss";
import expiredStorage from "../utils/expiredStorage";

// services
// import { getListUserRoom } from "../services/ChatServices";

export default function Navbar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [scroll, setScroll] = useState(0);
  const { keyword, keywords, idorder, subDomainPasar, idLapak, idProduct, kota } = useParams();

  const userEmail = expiredStorage.getItem("@userEmail");
  const tokenChat = expiredStorage.getItem("@tokenChat");

  // const generateUnreadRoom = useCallback(() => {
  //   getListUserRoom()
  //     .then(({ data }) => {
  //       const resRooms = data.response_data?.results?.rooms || [];
  //       const allRooms = resRooms
  //         .map((room) => {
  //           const splitName = room.room_name.split("/");
  //           room.room_name = splitName.length > 1 ? splitName[1] : "";
  //           return room;
  //         })
  //         .filter((res) => res.room_name);
  //       const rooms = allRooms.map((room) => parseInt(room.room_id));
  //       const params = {
  //         user_id: userEmail,
  //         room_ids: rooms,
  //       };
  //       dispatch({ type: "chat/GET_UNREAD_MESSAGE", params });
  //     })
  //     .catch((err) => {
  //       if (err.response.data.error == "Not Valid Token or Token Expired") {
  //         expiredStorage.removeItem("@userEmail");
  //         expiredStorage.removeItem("@tokenChat");
  //         window.location.reload();
  //       }
  //     });
  // }, [dispatch]);

  // useEffect(() => {
  //   if (tokenChat) {
  //     generateUnreadRoom();
  //     setInterval(() => {
  //       generateUnreadRoom();
  //     }, 60000);
  //   }
  // }, [generateUnreadRoom]);

  const goBackHandle = () => {
    history.goBack();
  };

  const backBtn = useVisibilityComponent(
    <FontAwesomeIcon
      key={1}
      className="back-button"
      icon={
        window.location.pathname === "/login" || window.location.pathname === "/register"
          ? "chevron-left"
          : "arrow-left"
      }
      onClick={goBackHandle}
    />,
    props.backBtn
  );

  const cityLogo = useVisibilityComponent(<img src={props.cityImg} alt="logo-city" />, props.city);

  window.addEventListener(
    "scroll",
    function (event) {
      setScroll(this.scrollY);
    },
    false
  );

  const screenSize = window.screen.width;

  const lengthURL = window.location.href.split("/").length;

  const renderCaption = (param) => {
    const styleLabel = {
      // width: "fit-content",
      marginTop: "10px",
      fontWeight: "bold",
      width: "65%",
    };
    if (param === "/register") {
      return <label style={styleLabel}>Daftar</label>;
    } else if (param === "/login") {
      return <label style={styleLabel}>Masuk</label>;
    }
    return "";
  };

  const hideBackAtSearchPage = () => {
    return window.location.pathname === `/search/${keyword}/Indonesia`;
  };

  return (
    <nav
      className={
        location.pathname === "/" ||
        location.pathname === `/${subDomainPasar}` ||
        location.pathname === `/${subDomainPasar}/${idLapak}` ||
        location.pathname === `/${subDomainPasar}/${idLapak}/${idProduct}` ||
        location.pathname === "/product" ||
        location.pathname === "/pasar" ||
        location.pathname === `/search/${keyword}/${kota}` ||
        location.pathname === `/${subDomainPasar}/${idLapak}/search/${keywords}` ||
        location.pathname === `/${subDomainPasar}/search/${keyword}`
          ? "menu-area"
          : "hide-menu-area"
      }
      id="navbar"
      style={props.style}
    >
      {cityLogo}
      {hideBackAtSearchPage() ? "" : backBtn}
      {props.isLoginOrRegist === "" ? (
        <div
          className={
            props.showLogo
              ? `logo-pasar ${
                  screenSize <= 767
                    ? lengthURL >= 4 || window.location.pathname.includes("searchpasar")
                      ? "d-none"
                      : ""
                    : ""
                }`
              : // : `logo-pasar ${
                //     screenSize <= 767
                //       ? lengthURL === 4
                //         ? scroll === 0
                //           ? "center-logo"
                //           : "left-logo"
                //         : ""
                //       : ""
                //   }`
                `logo-pasar ${
                  screenSize <= 767
                    ? window.location.pathname === "/"
                      ? scroll === 0
                        ? ""
                        : "left-logo"
                      : ""
                    : "logo-pasar-mobile"
                }`
          }
        >
          <Link to="/">
            <img height="58px" src={PasarId} alt="pasar" />
          </Link>
        </div>
      ) : (
        renderCaption(props.isLoginOrRegist)
      )}
      {props.children}
    </nav>
  );
}
