import { SET_LOADING } from "components/reducer/Action";
import client from "../../utils/axios";

const userAgent = navigator.userAgent;

async function getDaftarPasarClosest(limit, page, category, latEnv, longEnv) {
  const form = {
    latitude:
      parseFloat(sessionStorage.latPosition) &&
      parseFloat(sessionStorage.latPosition) !== null
        ? parseFloat(sessionStorage.latPosition)
        : !localStorage.getItem("@lat") && /PasaridM/i.test(userAgent)
        ? latEnv
        : parseFloat(localStorage.getItem("@lat")),
    longitude:
      parseFloat(sessionStorage.longPosition) &&
      parseFloat(sessionStorage.longPosition) !== null
        ? parseFloat(sessionStorage.longPosition)
        : !localStorage.getItem("@long") && /PasaridM/i.test(userAgent)
        ? longEnv
        : parseFloat(localStorage.getItem("@long")),
    limit: `${limit}`,
    kategori: category || "",
  };
  const { data } = await client.post(
    "api/v1/get_daftar_pasar_terdekat?page=" + page,
    form
  );
  return data;
}
async function getDataTotal() {
  const { data } = await client.get("api/v1/data_total");
  return data.Data;
}

//BANNER
async function getBanners(limit, page, sort) {
  const response = await client.post("api/v1/banner?page=" + page, limit, sort);
  return response;
}
async function getBannersTerlama(limit, page) {
  const response = await client.post(
    "api/v1/banner_terlama?page=" + page,
    limit
  );
  return response;
}
async function getBannersTerbaru(limit, page) {
  const response = await client.post(
    "api/v1/banner_terbaru?page=" + page,
    limit
  );
  return response;
}

async function getDetailPasar(id) {
  const form = {
    latitude: parseFloat(localStorage.getItem("@lat")),
    longitude: parseFloat(localStorage.getItem("@long")),
    idpasar: `${id}`,
  };
  const { data } = await client.post("api/v1/get_detail_pasar", form);
  return data.Data;
}

async function getKategori(id) {
  const form = {
    idpasar: id,
    limit: "1000",
  };
  const { data } = await client.post("api/v1/get_daftar_kategori", form);
  return data.Data;
}

async function getPasarByTema(city, tipe, limit, page) {
  const form = {
    kota: city,
    tema_pasar: tipe,
    latitude: parseFloat(localStorage.getItem("@lat")),
    longitude: parseFloat(localStorage.getItem("@long")),

    // latitude: -6.217251,
    // longitude: 106.814389,
    limit: `${limit}`,
  };
  const { data } = await client.post(
    `api/v1/get_daftar_pasar_by_tema?page=${page}`,
    form
  );
  return data;
}

async function sortPasarByPopular(limit, page, category, latEnv, longEnv) {
  const form = {
    latitude:
      parseFloat(sessionStorage.latPosition) &&
      parseFloat(sessionStorage.latPosition) !== null
        ? parseFloat(sessionStorage.latPosition)
        : !localStorage.getItem("@lat") && /PasaridM/i.test(userAgent)
        ? latEnv
        : parseFloat(localStorage.getItem("@lat")),
    longitude:
      parseFloat(sessionStorage.longPosition) &&
      parseFloat(sessionStorage.longPosition) !== null
        ? parseFloat(sessionStorage.longPosition)
        : !localStorage.getItem("@long") && /PasaridM/i.test(userAgent)
        ? longEnv
        : parseFloat(localStorage.getItem("@long")),
    limit: `${limit}`,
    kategori: category || "",
  };
  const { data } = await client.post(
    `api/v1/get_daftar_pasar_terpopuler?page=${page}`,
    form
  );
  return data;
}

async function sortPasarByNewest(limit, page, category, latEnv, longEnv) {
  const form = {
    latitude:
      parseFloat(sessionStorage.latPosition) &&
      parseFloat(sessionStorage.latPosition) !== null
        ? parseFloat(sessionStorage.latPosition)
        : !localStorage.getItem("@lat") && /PasaridM/i.test(userAgent)
        ? latEnv
        : parseFloat(localStorage.getItem("@lat")),
    longitude:
      parseFloat(sessionStorage.longPosition) &&
      parseFloat(sessionStorage.longPosition) !== null
        ? parseFloat(sessionStorage.longPosition)
        : !localStorage.getItem("@long") && /PasaridM/i.test(userAgent)
        ? longEnv
        : parseFloat(localStorage.getItem("@long")),
    limit: `${limit}`,
    kategori: category || "",
  };
  const { data } = await client.post(
    `api/v1/get_daftar_pasar_terbaru?page=${page}`,
    form
  );
  return data;
}

async function searchPasarDiKota(keyword, limit, page, sortBy) {
  const body = {
    latitude: parseFloat(localStorage.getItem("@lat")),
    longitude: parseFloat(localStorage.getItem("@long")),
    limit: `${limit}`,
    nama_pasar: `${keyword}`,
    alamat: "Indonesia",
    // sessionStorage.getItem("indo") && sessionStorage.getItem("indo") !== ""
    //   ? "Indonesia"
    //   : sessionStorage.getItem("currentCity") &&
    //     sessionStorage.getItem("currentCity") !== ""
    //   ? sessionStorage.getItem("currentCity")
    //   : localStorage.getItem("current"),
    sort_by: sortBy,
  };
  const res = await client.post(
    "api/v1/search_pasar_dikota_keyword?page=" + page,
    body
  );
  return res;
}

async function getPasarLastSeen(id, limit, lat, long) {
  const res = await client.get(
    `/api/v1/get_pasar_last_seen?session_id=${id}&limit=${limit}&latitude=${lat}&longitude=${long}`
  );
  return res;
}

export {
  getDaftarPasarClosest,
  getDataTotal,
  getBanners,
  getDetailPasar,
  getKategori,
  getPasarByTema,
  getBannersTerbaru,
  getBannersTerlama,
  sortPasarByPopular,
  sortPasarByNewest,
  searchPasarDiKota,
  getPasarLastSeen,
};
