import React, { useEffect, useReducer, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { withRouter, useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import {
  FETCH_COMPLETE,
  FETCH_DATA,
} from "../../../../components/reducer/Action";
import {
  OrderReducer,
  orderState,
} from "../../../../components/reducer/OrderReducer";
import {
  getListOrder,
  getListOrderGuest,
} from "../../../../components/services/OrderServices";
import FaIcon from "../../../../components/shared/font-awesome/FaIcon";
import NoDataFound from "../NoDataFound";
import Page from "../Page";
import { checkSessionId } from "../Profile";
import CardOrder from "./CardOrder";
import "./style.scss";
import localId from "components/shared/constants/ConstantsIdCustomer";
import cookieGuest from "components/shared/constants/ConstantsGuestCustomer";

function OrderList(props) {
  const { tabFromProfile } = props.location;
  const [fromProfile, setFromProfile] = useState(tabFromProfile);
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["Semua", "Menunggu", "Dalam Proses", "Selesai"];
  const [isEmpty, setIsEmpty] = useState(false);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loadingLoad, setLoadingLoad] = useState(false);
  const [orderListGuest, setOrderListGuest] = useState([]);

  const [state, dispatch] = useReducer(OrderReducer, orderState);

  const {
    location: { pathname },
  } = props;
  const pathnames = pathname.split("/").filter((x) => x);

  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  const fetchData = () => dispatch({ type: FETCH_DATA });
  const fetchComplete = (payload) =>
    dispatch({ type: FETCH_COMPLETE, payload });

  useEffect(() => {
    if (fromProfile !== undefined) setActiveTab(fromProfile);
    fetchData();
    getData(activeTab, page, 10);
  }, [activeTab, page]);

  //load more
  useEffect(() => {
    setLoadingLoad(true);
    getData(activeTab, page, limit);
  }, [limit]);

  const getData = (tb, pg, lmt) => {
    const tabActive = fromProfile ? fromProfile : tb;
    if (localId || cookieGuest) {
      if (localId) {
        getListOrder(tabActive + 1, pg, lmt)
          .then((res) => {
            if (res.data.Data.order_list.length != 0) {
              fetchComplete(res.data.Data.order_list);
              setTotalData(res.data.total_data);
              setTotalPage(res.data.total_page);
              setIsEmpty(false);
            } else setIsEmpty(true);
            setLoadingLoad(false);
          })
          .catch((err) => {
            setLoadingLoad(false);
            checkSessionId(err, props);
          });
      }
      if (cookieGuest) {
        getListOrderGuest(tabActive + 1, pg, lmt)
          .then((res) => {
            if (res.data.Data.order_list.length !== 0) {
              setTotalPage(res.data.total_page);
              setOrderListGuest(res.data.Data.order_list);
              setIsEmpty(false);
            } else setIsEmpty(true);
            setLoadingLoad(false);
          })
          .catch((err) => {
            setLoadingLoad(false);
          });
      }
    } else history.push("/login");
  };

  const toggle = (tab) => {
    setActiveTab(tab);
    setFromProfile(undefined);
  };

  const tab = () => {
    let rows = tabs.map((res, i) => {
      return (
        <div
          key={i}
          className={`btn_tab_list ${
            activeTab === i ? "select_tab" : "deselect_tab"
          }`}
          onClick={() => toggle(i)}
        >
          <label
            style={{
              cursor: "pointer",
              margin: 0,
              color: activeTab === i ? "#fff" : "#9E9E9E",
            }}
          >
            {res}
          </label>
        </div>
      );
    });
    return rows;
  };

  const loadingSkeleton = () => {
    return (
      <div style={{ width: "100%" }}>
        <Skeleton height={100} />
        <Skeleton height={100} />
        <Skeleton height={100} />
      </div>
    );
  };

  const generateListOrder = () => {
    let rows = loadingSkeleton();
    if (localId) {
      if (state.isLoading === false && !isEmpty) {
        rows = state.order.map((res, i) => {
          return <CardOrder key={i} data={res} />;
        });
      } else if (isEmpty) {
        rows = (
          <NoDataFound
            error={true}
            text="Belum ada data"
            text2="Anda belum membuat orderan"
          />
        );
      }

      return rows;
    }

    if (cookieGuest) {
      if (loadingLoad === false && !isEmpty) {
        rows = orderListGuest.map((res, i) => {
          return <CardOrder key={i} data={res} />;
        });
      } else if (isEmpty) {
        rows = (
          <NoDataFound
            error={true}
            text="Belum ada data"
            text2="Anda belum membuat orderan"
          />
        );
      }
    }

    return rows;
  };

  const loadMore = () => {
    setLimit(limit + 10);
  };
  return (
    <Page>
      <MUIBreadcrumbs aria-label="breadcrumb">
        {pathnames.length > 0 ? (
          <Link onClick={() => history.push("/")}>Home</Link>
        ) : (
          <Typography>Home</Typography>
        )}
        {pathnames.length > 0 ? (
          <Link>Pesanan</Link>
        ) : (
          <Typography>Pesanan</Typography>
        )}
      </MUIBreadcrumbs>
      <div className="container_order_list">
        <label style={{ fontWeight: "bold", fontSize: "20px" }}>
          Daftar Pesanan
        </label>
        <div className="container_tab">{tab()}</div>
        <div className="row content_order_list">{generateListOrder()}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "1rem 0",
          }}
        >
          {totalPage > 1 ? (
            <label className="load_more_order_list" onClick={loadMore}>
              Tampilkan Lebih{" "}
              {loadingLoad ? (
                <Spinner size="sm" />
              ) : (
                <FaIcon className="ml-2" icon="fas chevron-down" />
              )}
            </label>
          ) : null}
        </div>
      </div>
    </Page>
  );
}

export default withRouter(OrderList);
