import client from "../../utils/axios";
import localId from "../shared/constants/ConstantsIdCustomer";
import { getUlasanProduct } from "./OrderServices";

const userAgent = navigator.userAgent;

async function getProduct(lapak, idLapak, page) {
  const { data } = await client.get(
    `api/v1/get_list_produk?count=10&lapak_id=${idLapak}&page=${page}`
  );
  return data.data;
}

async function getAllProduct(params) {
  const { lapak_id, pasar_id, count } = params;
  const { data } = await client.get(
    `api/v1/get_list_produk?count=${count}&lapak_id=${lapak_id}&page=1&pasar_id=${pasar_id}`
  );
  const products = data.data?.list || [];
  // const mapProd = await Promise.all(
  //   products.map(async (prod) => {
  //     prod.rating_full = await getUlasanProduct(prod.id, params.lapak_id)
  //       .then((resp) => {
  //         return resp.Data;
  //       })
  //       .catch(() => {});
  //     return prod;
  //   })
  // );
  return {
    list: products,
    total_pages: data.data?.total_pages,
    total_items: data.data?.total_items,
  };
}

async function getDetailPedagang(id) {
  const form = {
    idlapak: `${id}`,
  };
  const { data } = await client.post("api/v1/get_detail_lapak", form);
  return data.Data;
}

async function getProdukByLapak(param) {
  const form = {
    id_lapak: parseInt(param.idLapak),
    keyword: param.keyword,
    limit: 12,
    page: param.page,
    sort_by: 0,
  };
  const { data } = await client.post("api/v1/get_produk_by_lapak", form);
  return data;
}

async function searchProdukByLapak(param) {
  const form = {
    id_lapak: parseInt(param.idLapak),
    keyword: param.keyword,
    limit: 12,
    page: 1,
    sort_by: 0,
  };
  const { data } = await client.post("api/v1/search_produk_by_lapak", form);
  return data;
}

async function getDetailProduct(pasar, lapak, id) {
  const { data } = await client.get(
    `api/v1/get_detail_produk?id=${id}&lapak_id=${lapak}&pasar_id=${pasar}${
      localId ? `&session_id=${localId}` : ""
    }`
  );
  return data.data;
}

async function searchProduk(lapak, keyword) {
  let url = `/api/v1/get_list_produk?count=999`;
  if (keyword && keyword.length > 1)
    url = `/api/v1/get_list_produk?count=999&keyword=${keyword}`;

  const res = await client.get(url);
  return res;
}

async function produkTerlarisLanding(limit, page, latEnv, longEnv) {
  const form = {
    limit: limit,
    page: page,
    latitude:
      parseFloat(sessionStorage.latPosition) &&
      parseFloat(sessionStorage.latPosition) !== null
        ? parseFloat(sessionStorage.latPosition)
        : !localStorage.getItem("@lat") && /PasaridM/i.test(userAgent)
        ? latEnv
        : parseFloat(localStorage.getItem("@lat")),
    longitude:
      parseFloat(sessionStorage.longPosition) &&
      parseFloat(sessionStorage.longPosition) !== null
        ? parseFloat(sessionStorage.longPosition)
        : !localStorage.getItem("@long") && /PasaridM/i.test(userAgent)
        ? longEnv
        : parseFloat(localStorage.getItem("@long")),
  };
  const { data } = await client.post("/api/v1/get_produk_terlaris", form);
  return data;
}

async function searchProdukDiKota(keyword, limit, page, sortBy) {
  const body = {
    limit: limit,
    page: page,
    latitude: parseFloat(sessionStorage.getItem("latPosition")),
    longitude: parseFloat(sessionStorage.getItem("longPosition")),
    search: keyword,
    sort_by: sortBy,
  };
  const res = await client.post("api/v1/search_produk_terdekat", body);
  return res;
}

async function getProdukByPasar(idPasar, sortBy, page, limit) {
  const body = {
    id_pasar: parseInt(idPasar),
    sort_by: parseInt(sortBy),
    limit: limit,
    page: page,
  };
  const res = await client.post("api/v1/get_produk_by_pasar", body);
  return res;
}

async function searchProdukByPasar(idPasar, sortBy, page, limit, keyword) {
  const body = {
    id_pasar: parseInt(idPasar),
    keyword: keyword,
    sort_by: sortBy,
    limit: limit,
    page: page,
  };
  const res = await client.post("api/v1/search_produk_by_pasar", body);
  return res;
}

async function getDaftarProdukByKategori(id, idCat, limit, page, filter) {
  const form = {
    idpasar: id,
    limit: `${limit}`,
  };
  const response = await client.post(
    `api/v1/get_daftar_lapak_by_pasar?filter=${filter}&kategory=${idCat}&page=` +
      page,
    form
  );
  return response;
}

export {
  getProduct,
  getAllProduct,
  getDetailPedagang,
  getDetailProduct,
  searchProduk,
  produkTerlarisLanding,
  searchProdukDiKota,
  getProdukByPasar,
  searchProdukByPasar,
  getDaftarProdukByKategori,
  getProdukByLapak,
  searchProdukByLapak,
};
