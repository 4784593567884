export const statusOrder = {
  menungguKonfirmasi: "Menunggu Konfirmasi",
  menungu: "Menunggu Pembayaran",
  pembayaran: "Pesanan Terbayar",
  dalamProses: "Pesanan Diproses",
  pesananDikirim: "Pesanan Diantar",
  pesananTiba: "Pesanan Tiba",
  pesananSelesai: "Pesanan Selesai",
  pesananDitolak: "Pesanan Ditolak",
};

export const colorBG = {
  menungguKonfirmasi: "#ED4140",
  menungu: "#ED4140",
  pembayaran: "#F8AF4E",
  dalamProses: "#F8AF4E",
  pesananDikirim: "#F8AF4E",
  pesananTiba: "#66BB6A",
  pesananSelesai: "#66BB6A",
  pesananDitolak: "#ED4140",
};

export const status = (status) => {
  switch (status) {
    case 1:
      return statusOrder.menungguKonfirmasi;
    case 2:
      return statusOrder.menungu;
    case 3:
      return statusOrder.pembayaran;
    case 4:
      return statusOrder.dalamProses;
    case 5:
      return statusOrder.pesananDikirim;
    case 6:
      return statusOrder.pesananTiba;
    case 7:
      return statusOrder.pesananSelesai;
    case 99:
      return statusOrder.pesananDitolak;
    default:
      return "Dipertanyakan";
  }
};
export function color(status) {
  switch (status) {
    case 1:
      return colorBG.menungguKonfirmasi;
    case 2:
      return colorBG.menungu;
    case 3:
      return colorBG.pembayaran;
    case 4:
      return colorBG.dalamProses;
    case 5:
      return colorBG.pesananDikirim;
    case 6:
      return colorBG.pesananTiba;
    case 7:
      return colorBG.pesananSelesai;
    case 99:
      return colorBG.pesananDitolak;
    default:
      return "#757575";
  }
}
