const INIT_STATE = {
  initialURL: "/",
  error: "",
  message: "",
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "START": {
      return { ...state, error: "", message: "", loading: true };
    }
    case "SUCCESS": {
      return { ...state, error: "", loading: false, message: action.payload };
    }
    case "ERROR": {
      return { ...state, loading: false, message: "", error: action.payload };
    }
    default:
      return state;
  }
};
