import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState, useReducer } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { geolocated } from "react-geolocated";
import Skeleton from "react-loading-skeleton";
import { withRouter, Link, useLocation } from "react-router-dom";
import productContext from "../../contex/context/product-context";
import "../../scss/footer/footer.scss";
import DropdownMenu from "../dropdown/DropdownMenu";
import { getKotaByLatLong } from "../services/LocationService";
import { searchPasarDiKota } from "../services/MarketService";
import latitude from "../shared/constants/ConstantLatitude";
import longitude from "../shared/constants/ConstantLongitude";
import DefaultPasarImgRandomizer from "../utils/DefaultPasarImgRandomizer";
import FaIcon from "../shared/font-awesome/FaIcon";
import IcoMapPin from "../../assets/map-pin.png";
import { toastObject } from "containers/layout/page/Profile";
import { useParams } from "react-router-dom";
import ToastNotification from "components/button/Toast";

const SearchBar = (props) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { setEnableGps, setSearchLoading, searchLoading } =
    useContext(productContext);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [page, setPage] = useState(1); // eslint-disable-line no-unused-vars
  const [locationLoading, setLocationLoading] = useState(false);
  const [lokasi, setLokasi] = useState("Mencari Lokasi ...");
  const [gpsDisabled, setGpsDisabled] = useState(false);
  const [keywords, setKeywords] = useState("");
  const [toast, setToast] = useState({ ...toastObject });
  const [result, setResult] = useState("");
  const { subDomainPasar, idorder, idLapak, idProduct, kota, keyword } =
    useParams();
  const { keywords: keys, keyword: key } = useParams();

  const namaLapak = idLapak?.split("-");

  const pasar = localStorage.getItem("@namaPasar");
  // const latEnv = parseFloat(-6.2158728);
  // const longEnv = parseFloat(106.814934);
  const latEnv = parseFloat(process.env.REACT_APP_DEFAULT_LATITUDE);
  const longEnv = parseFloat(process.env.REACT_APP_DEFAULT_LONGITUDE);

  const location = useLocation();

  const handleSearch = () => {
    if (search.length > 1) {
      setSearchLoading(!searchLoading);
      props.history.push({
        pathname: `/search/${result}/${
          sessionStorage.getItem("indo") && sessionStorage.getItem("indo") != ""
            ? "Indonesia"
            : sessionStorage.getItem("currentCity") &&
              sessionStorage.getItem("currentCity") !== ""
            ? sessionStorage.getItem("currentCity")
            : localStorage.getItem("current")
        }`,
        state: { query_text: "Produk", query_number: 2 },
      });
      setToast({
        ...toast,
        isOpen: false,
      });
    } else {
      setToast({
        ...toast,
        isOpen: true,
        message: `Pencarian kurang dari 2 karakter`,
        status: "error",
      });
    }
  };

  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      if (search.length > 1) {
        setSearchLoading(!searchLoading);

        if (
          location.pathname === `/${subDomainPasar}` ||
          location.pathname === `/${subDomainPasar}/search/${key}`
        ) {
          props.history.push(`/${subDomainPasar}/search/${result}`);
        } else if (
          location.pathname === `/${subDomainPasar}/${idLapak}` ||
          location.pathname === `/${subDomainPasar}/${idLapak}/search/${keys}`
        ) {
          props.history.push(`/${subDomainPasar}/${idLapak}/search/${result}`);
        } else {
          props.history.push({
            pathname: `/search/${result}/${
              sessionStorage.getItem("indo") &&
              sessionStorage.getItem("indo") !== ""
                ? "Indonesia"
                : sessionStorage.getItem("currentCity") &&
                  sessionStorage.getItem("currentCity") !== ""
                ? sessionStorage.getItem("currentCity")
                : localStorage.getItem("current")
            }`,
            state: {
              query_text: "Produk",
              query_number: 2,
            },
          });
        }
      } else {
        setToast({
          ...toast,
          isOpen: true,
          message: `Pencarian kurang dari 2 karakter`,
          status: "error",
        });
      }
    }
  };

  const handleOnclick = (e) => {
    setSearch(e);
    props.history.push(
      `/search/${e}/${
        sessionStorage.getItem("indo") && sessionStorage.getItem("indo") !== ""
          ? "Indonesia"
          : sessionStorage.getItem("currentCity") &&
            sessionStorage.getItem("currentCity") !== ""
          ? sessionStorage.getItem("currentCity")
          : localStorage.getItem("current")
      }`
    );
    window.location.reload();
  };

  const handleSearchPasar = (query) => {
    setKeywords(query);
    setSearch(query);
    const splits = query.replaceAll("/", "&sol;");
    setResult(splits);
  };

  useEffect(() => {
    let userAgent = navigator.userAgent || navigator.vendor;
    // if (/windows phone/i.test(userAgent)) {
    //   if (navigator.permissions) {
    //     navigator.permissions.query({ name: "geolocation" }).then(function (res) {
    //       if (res.state === "denied") {
    //         setGpsDisabled(true);
    //         setEnableGps(false);
    //       } else {
    //         if (props.coords) {
    //           successGeoLocation(props);
    //           setEnableGps(true);
    //         }
    //       }
    //     });
    //   }
    // } else if (/android/i.test(userAgent)) {
    //   if (navigator.permissions) {
    //     navigator.permissions.query({ name: "geolocation" }).then(function (res) {
    //       if (res.state === "denied") {
    //         setGpsDisabled(true);
    //         setEnableGps(false);
    //       } else {
    //         if (props.coords) {
    //           successGeoLocation(props);
    //           setEnableGps(true);
    //         }
    //       }
    //     });
    //   }
    // } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(successGeoLocation, errorGeoLocation, geoOptions);
    //   }
    // } else {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(
    //       successGeoLocation,
    //       errorGeoLocation,
    //       geoOptions
    //     );
    //   }
    // }

    const locationGPS =
      props.location.pathname === "/" ||
      props.location.pathname === "/checkout" ||
      props.location.pathname === "/profile" ||
      props.location.pathname ===
        `/search/${result}/${
          sessionStorage.getItem("indo") &&
          sessionStorage.getItem("indo") !== ""
            ? "Indonesia"
            : sessionStorage.getItem("currentCity") &&
              sessionStorage.getItem("currentCity") !== ""
            ? sessionStorage.getItem("currentCity")
            : localStorage.getItem("current")
        }` ||
      location.pathname === `/${subDomainPasar}` ||
      location.pathname === `/${subDomainPasar}/search/${key}` ||
      location.pathname === `/${subDomainPasar}/${idLapak}` ||
      location.pathname === `/${subDomainPasar}/${idLapak}/search/${keys}`;

    if (locationGPS) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          successGeoLocation,
          errorGeoLocation,
          geoOptions
        );
      } else if (navigator.permissions) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (res) {
            if (res.state === "denied") {
              setGpsDisabled(true);
              setEnableGps(false);
            } else {
              if (props.coords) {
                successGeoLocation(props);
                setEnableGps(true);
              }
            }
          });
      }
    }
  }, [props.coords, props.location]);

  const checkLocalStorage = () => {
    if (!longitude && !latitude) window.location.reload();
  };

  const geoOptions = {
    maximumAge: 5 * 60 * 1000,
  };

  const errorGeoLocation = (error) => {
    setLocationLoading(true);
    if (error.code === 1 || error.code === 2) {
      if (!localStorage.getItem("@lat") && !localStorage.getItem("@long")) {
        if (location.pathname === "/") {
          localStorage.setItem("@lat", latEnv);
          localStorage.setItem("@long", longEnv);
        }

        checkLocalStorage();
      }
      // setGpsDisabled(true);
      // setToast({
      //   ...toast,
      //   isOpen: true,
      //   message: `Aktifkan GPS Anda`,
      //   status: "error",
      // });
      setEnableGps(false);
      setLocationLoading(false);
    }
  };

  const successGeoLocation = (position) => {
    if (position) {
      setLocationLoading(true);
      setEnableGps(true);
      if (!sessionStorage.indo) {
        if (location.pathname === "/") {
          localStorage.setItem("@lat", position.coords.latitude);
          localStorage.setItem("@long", position.coords.longitude);
          sessionStorage.setItem("indo", "Indonesia");
        }
        setLocationLoading(false);
        checkLocalStorage();
      }
      setLocationLoading(false);
      // if (
      //   localStorage.getItem("current") &&
      //   localStorage.getItem("current") !== "" &&
      //   !sessionStorage.getItem("indo")
      // ) {
      //   setLokasi(localStorage.getItem("current"));
      //   setLocationLoading(false);
      //   checkLocalStorage();
      // } else if (
      //   sessionStorage.getItem("currentCity") &&
      //   sessionStorage.getItem("currentCity") !== ""
      // ) {
      //   setLokasi(sessionStorage.getItem("currentCity"));
      //   setLocationLoading(false);
      //   checkLocalStorage();
      // } else {
      //   sessionStorage.setItem("indo", "Indonesia");
      //   setLokasi("Indonesia");
      //   setLocationLoading(false);
      //   // if (!localStorage.getItem("current"))
      //   //   getKotaByLatLong({
      //   //     latitude: parseFloat(position.coords.latitude),
      //   //     longitude: parseFloat(position.coords.longitude),
      //   //   })
      //   //     .then((res) => {
      //   //       localStorage.setItem("current", res);
      //   //       window.location.reload();
      //   //     })
      //   //     .catch((err) => {});
      // }
    }
  };

  const onError = (e) => {
    const image = DefaultPasarImgRandomizer();
    return (e.target.src = image);
  };

  const filterByFields = [
    "nama_pasar",
    "alamat",
    "kota",
    "kecamatan",
    "provinsi",
  ];
  return (
    <>
      {!locationLoading ? (
        <div
          className={
            location.pathname === "/" ||
            location.pathname === `/${subDomainPasar}` ||
            location.pathname === `/${subDomainPasar}/${idLapak}` ||
            location.pathname ===
              `/${subDomainPasar}/${idLapak}/${idProduct}` ||
            location.pathname === "/product" ||
            location.pathname === "/pasar" ||
            location.pathname === `/search/${keyword}/${kota}` ||
            location.pathname === `/search/${keyword}/Indonesia` ||
            location.pathname ===
              `/${subDomainPasar}/${idLapak}/search/${keys}` ||
            location.pathname === `/${subDomainPasar}/search/${key}`
              ? `container-search ${props.classCss}`
              : "hide-container-search"
          }
        >
          {/* <div className="search-location">
            <div className="pin-map">
              {props.classCss.includes("search-city") ? (
                <img alt="pin-map" src={IcoMapPin} width={20} />
              ) : (
                <FontAwesomeIcon
                  className="icon-map"
                  style={{ color: "red" }}
                  icon="map-marker-alt"
                />
              )}
            </div>
            <div className="dropdown-container">
              <div className="dropdown-container-button">
                <button
                  className={
                    lokasi !== "Mencari Anda... "
                      ? "btn-dropdown dropdown-toggle"
                      : "btn-dropdown"
                  }
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => setOpen(!open)}
                >
                  {lokasi}
                </button>
                {open && (
                  <DropdownMenu
                    setOpen={setOpen}
                    open={open}
                    setLokasi={setLokasi}
                    coords={props.coords}
                  />
                )}
              </div>
            </div>
            {props.classCss.toString().includes("search-city") && (
              <div onClick={() => setOpen(!open)} className="chevron-wrapper">
                <FaIcon className="down-ico" icon="fas chevron-down" />
              </div>
            )}
          </div> */}

          <div className="search-produk-lapak-pasar">
            <div className="search-input">
              <div className="input-form">
                <AsyncTypeahead
                  filterBy={filterByFields}
                  isLoading={isLoading}
                  labelKey="nama"
                  id="typehead-searchbar"
                  // onInputChange={handleSearchPasar}
                  onSearch={handleSearchPasar}
                  onKeyDown={(e) => handleSearchEnter(e)}
                  options={options}
                  placeholder="Cari Produk, Lapak dan Pasar"
                  paginate
                  useCache={false}
                  minLength={2}
                  maxResults={20}
                  clearButton={true}
                  renderMenu={() => (
                    <div className="container-suggestion">
                      {pasar && subDomainPasar && !idLapak && (
                        <>
                          <div className="suggestion-search">
                            <p>
                              Pencarian di <b>{pasar}</b>
                            </p>
                          </div>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname: `/${subDomainPasar}/search/${result}`,
                              state: {
                                query_text: "Produk",
                                query_number: 3,
                              },
                            }}
                          >
                            <div className="suggestion-search">
                              <FaIcon
                                icon="fas shopping-basket"
                                className="faicon"
                              />
                              <p>Produk "{keywords}"</p>
                            </div>
                          </Link>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname: `/${subDomainPasar}/search/${result}`,
                              state: { query_text: "Lapak", query_number: 2 },
                            }}
                          >
                            <div className="suggestion-search">
                              <FaIcon icon="fas store" className="faicon" />
                              <p>Lapak "{keywords}"</p>
                            </div>
                          </Link>
                        </>
                      )}
                      {namaLapak && subDomainPasar && (
                        <>
                          <div className="suggestion-search">
                            <p>
                              Pencarian di <b>{namaLapak[0]}</b>
                            </p>
                          </div>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname: `/${subDomainPasar}/${idLapak}/search/${keywords}`,
                              // state: {
                              //   query_text: "Produk",
                              //   query_number: 3,
                              // },
                            }}
                          >
                            <div className="suggestion-search">
                              <FaIcon
                                icon="fas shopping-basket"
                                className="faicon"
                              />
                              <p>Produk "{keywords}"</p>
                            </div>
                          </Link>
                        </>
                      )}
                      <hr />
                      <div className="suggestion-search">
                        <p>
                          Pencarian di <b>Pasar.id</b>
                        </p>
                      </div>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={{
                          pathname: `/search/${result}/${
                            sessionStorage.getItem("indo") &&
                            sessionStorage.getItem("indo") !== ""
                              ? "Indonesia"
                              : sessionStorage.getItem("currentCity") &&
                                sessionStorage.getItem("currentCity") !== ""
                              ? sessionStorage.getItem("currentCity")
                              : localStorage.getItem("current")
                          }`,
                          state: { query_text: "Produk", query_number: 2 },
                        }}
                      >
                        <div className="suggestion-search">
                          <FaIcon
                            icon="fas shopping-basket"
                            className="faicon"
                          />
                          <p>Produk "{keywords}"</p>
                        </div>
                      </Link>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={{
                          pathname: `/search/${result}/${
                            sessionStorage.getItem("indo") &&
                            sessionStorage.getItem("indo") !== ""
                              ? "Indonesia"
                              : sessionStorage.getItem("currentCity") &&
                                sessionStorage.getItem("currentCity") !== ""
                              ? sessionStorage.getItem("currentCity")
                              : localStorage.getItem("current")
                          }`,
                          state: { query_text: "Lapak", query_number: 1 },
                        }}
                      >
                        <div className="suggestion-search">
                          <FaIcon icon="fas store" className="faicon" />
                          <p>Lapak "{keywords}"</p>
                        </div>
                      </Link>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={{
                          pathname: `/search/${result}/${
                            sessionStorage.getItem("indo") &&
                            sessionStorage.getItem("indo") !== ""
                              ? "Indonesia"
                              : sessionStorage.getItem("currentCity") &&
                                sessionStorage.getItem("currentCity") !== ""
                              ? sessionStorage.getItem("currentCity")
                              : localStorage.getItem("current")
                          }`,
                          state: { query_text: "Pasar", query_number: 0 },
                        }}
                      >
                        <div className="suggestion-search">
                          <FaIcon
                            icon="fas map-marker-alt"
                            className="faicon"
                          />
                          <p>Pasar "{keywords}"</p>
                        </div>
                      </Link>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="button-search">
              <button
                className="btn-dropdown"
                type="button"
                onClick={() => handleSearch()}
                id="button-addon2"
              >
                <FontAwesomeIcon
                  className={`icon ${props.classCss}`}
                  style={{ color: "red" }}
                  size="lg"
                  icon="search"
                />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={`container-search ${props.classCss}`}>
          <Skeleton width="100%" />
        </div>
      )}
      <SweetAlert
        show={gpsDisabled}
        onConfirm={() => setGpsDisabled(false)}
        confirmBtnBsStyle={"danger"}
        title="Aktifkan GPS Anda"
        warning
      />
      <ToastNotification
        isOpen={toast.isOpen}
        message={toast.message}
        status={toast.status}
        setToast={setToast}
      />
    </>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
})(withRouter(SearchBar));
