import React from "react";
import NoDataFound from "./NoDataFound";

export default function PageNotFound() {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NoDataFound
        error={true}
        text="Halaman Tidak Ditemukan"
        text2="Halaman yang anda cari tidak ada"
        buttons={true}
        textButton={"Home"}
      />
    </div>
  );
}
