import { FETCH_COMPLETE, FETCH_DATA, RESET, SET_LOADING } from "./Action";

const initialCustomer = {
  nama: "",
  email: "",
  no_hp: "",
  password: "",
};

export const customerState = {
  customer: { ...initialCustomer },
  isLoading: false,
};

export const customerReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DATA:
      return { ...state, isLoading: true };
    case FETCH_COMPLETE:
      return { ...state, customer: payload, isLoading: false };
    case SET_LOADING:
      return { ...state, isLoading: !state.isLoading };
    case RESET:
      return { ...state, customer: { ...initialCustomer } };
    default:
      return { ...state };
  }
};
