import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import middlewares, { sagas, sagaMiddleware } from "./middlewares";
import reducers from "./reducers";

const bindMiddleware = (middleware) => {
  // if (process.env.NODE_ENV !== "production") {
  //   const { composeWithDevTools } = require("redux-devtools-extension");
  //   return composeWithDevTools(applyMiddleware(...middleware));
  // }
  return applyMiddleware(...middleware);
};

const configureStore = (initialState) => {
  const store = createStore(reducers(), initialState, bindMiddleware([thunk]));
  return store;
};

export default configureStore(/* provide initial state if any */);
