import axios from "axios";
import token from "../components/shared/constants/ConstToken";
const clientFormData = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers:{
    token: `${token}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

export default clientFormData;
