import * as types from "./actions";

const initialState = {
  loading: false,
  wishlists: [],
  wishlist: {},
  pageWishlist: null,
  totalWishlist: null,
  error: null,
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_DATA:
      return {
        ...state,
        wishlists: action.payload.wishlist_list,
        pageWishlist: action.payload.total_pages,
        totalWishlist: action.payload.total_items,
      };

    case types.CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}
