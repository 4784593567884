import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useHistory, withRouter } from "react-router-dom";
import productContext from "../../contex/context/product-context";
import "../../scss/footer/search.scss";
import { getKotaByLatLong } from "../services/LocationService";
import { searchPasarDiKota } from "../services/MarketService";
import latitude from "../shared/constants/ConstantLatitude";
import longitude from "../shared/constants/ConstantLongitude";
import FaIcon from "../shared/font-awesome/FaIcon";
import DefaultPasarImgRandomizer from "../utils/DefaultPasarImgRandomizer";

function SearchNav(props) {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const { setSearchLoading, searchLoading } = useContext(productContext);
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1); // eslint-disable-line no-unused-vars
  const [isLoading, setIsloading] = useState(false);
  const [placeholder, setPlaceholder] = useState("Cari pasar di sekitar Anda");

  const [locationLoading, setLocationLoading] = useState(false); // eslint-disable-line no-unused-vars
  const [lokasi, setLokasi] = useState("Mencari Lokasi Anda ..."); // eslint-disable-line no-unused-vars

  const handleSearchEnter = (e) => {
    if (e.key === "Enter" && search.length > 1) {
      setSearchLoading(!searchLoading);
      history.push(`/searchpasar/${search}/${localStorage.getItem("current")}`);
    }
  };

  const handleSearch = () => {
    setSearchLoading(!searchLoading);
    if (search.length > 1) {
      props.history.push(
        `/search/${search}/${
          sessionStorage.getItem("indo") && sessionStorage.getItem("indo") != ""
            ? "Indonesia"
            : sessionStorage.getItem("currentCity") &&
              sessionStorage.getItem("currentCity") !== ""
            ? sessionStorage.getItem("currentCity")
            : localStorage.getItem("current")
        }`
      );
    } else {
      setPlaceholder("Minimal panjang 3 karakter");
      setTimeout(() => {
        setPlaceholder("Cari pasar di sekitar Anda");
      }, 2000);
    }
  };

  const handleOnclick = (e) => {
    setSearch(e);
    if (e.length > 1) {
      props.history.push(
        `/search/${e}/${
          sessionStorage.getItem("indo") &&
          sessionStorage.getItem("indo") !== ""
            ? "Indonesia"
            : sessionStorage.getItem("currentCity") &&
              sessionStorage.getItem("currentCity") !== ""
            ? sessionStorage.getItem("currentCity")
            : localStorage.getItem("current")
        }`
      );
    }
  };

  const handleSearchPasar = (query) => {
    if (query.length > 1) {
      setIsloading(true);
      searchPasarDiKota(query, "20", page)
        .then((res) => res.data)
        .then(({ Data }) => {
          const options = Data.map((i) => {
            setSearch(query);
            return {
              id: i.pasar,
              nama: i.nama_pasar,
              image: i.image,
              alamat: i.alamat,
              kota: i.kota,
              kecamatan: i.kecamatan,
              provinsi: i.provinsi,
            };
          });
          setOptions(options);
          setIsloading(false);
        })
        .catch((err) => {
          setIsloading(false);
          setSearch(query);
        });
    }
  };
  const checkLocalStorage = () => {
    if (!longitude && !latitude) window.location.reload();
  };

  useEffect(() => {
    if (props.coords) {
      localStorage.setItem("@lat", props.coords.latitude);
      localStorage.setItem("@long", props.coords.longitude);
      const location = {
        latitude: parseFloat(props.coords.latitude),
        longitude: parseFloat(props.coords.longitude),
      };
      setLocationLoading(true);
      getKotaByLatLong(location)
        .then((res) => {
          setLokasi(res);
          setLocationLoading(false);
          localStorage.setItem("current", res);
          checkLocalStorage();
        })
        .catch((err) => {});
    }
  }, [props.coords, props.location]);

  const onError = (e) => {
    const image = DefaultPasarImgRandomizer();
    return (e.target.src = image);
  };

  const filterByFields = [
    "alamat",
    "kota",
    "kecamatan",
    "provinsi",
    "nama_pasar",
  ];
  return (
    <>
      <FaIcon
        icon="fas arrow-left"
        cursor="pointer"
        color="#757575"
        className={props.expand ? "back-icon" : "d-none"}
        onClick={props.clicked}
      />
      <div className={props.expand ? "search-container" : "d-none"}>
        <div className={props.expand ? "search-txt expand" : "d-none"}>
          <AsyncTypeahead
            filterBy={filterByFields}
            isLoading={isLoading}
            labelKey="nama"
            id="typehead-searchnav"
            onSearch={handleSearchPasar}
            onKeyDown={(e) => handleSearchEnter(e)}
            options={options}
            placeholder={placeholder}
            useCache={false}
            minLength={3}
            maxResults={5}
            clearButton={false}
            renderMenuItemChildren={(option) => (
              <>
                <div key={option.id} onClick={() => handleOnclick(option.nama)}>
                  <img
                    onError={(e) => onError(e)}
                    src={option.image}
                    style={{ marginRight: 10, height: 32, width: 32 }}
                  />
                  <span>{option.nama}</span>
                </div>
              </>
            )}
          />
        </div>
        <button onClick={() => handleSearch()} className="btn">
          <FontAwesomeIcon icon="search" color="#757575" cursor="pointer" />
        </button>
      </div>
    </>
  );
}

export default withRouter(SearchNav);
