const jsonToText = (jsonObj) => {
  let split = JSON.stringify(jsonObj);
  split = split
    .split('"')
    .join("")
    .split("title")
    .join("")
    .split("[")
    .join("")
    .split("]")
    .join("")
    .split("{")
    .join("")
    .split("}")
    .join("")
    .split(":")
    .join(" ")
    .split(",")
    .join("%0a")
    .replace("NoTlp", "No. Tlp:")
    .replace("Pasar", "Pasar:")
    .replace("Order", "Order:")
    .replace("Nama", "Nama:")
    .replace("Alamat", "Alamat:");
  return split.replace("Pesanan ", "Pesanan:%0a");
};

const jsonProduct = (obj) => {
  let split = JSON.stringify(obj);
  split = split
    .split('"')
    .join("")
    .split("{")
    .join("")
    .split("nama")
    .join("")
    .split("jumlah")
    .join("")
    .split(",")
    .join("")
    .split("}")
    .join("x%0a");
  return split;
};

export { jsonToText, jsonProduct };
