import React, { useState } from "react";
import FaIcon from "../shared/font-awesome/FaIcon";
import "../../scss/form/modal.scss";
import FormPembelian from "../modal/FormPembelian";
import { withRouter } from "react-router-dom";

function CardAlamatMobile(props) {
  const {
    classname,
    data,
    trigger,
    setTrigger,
    idCard,
    isListAddress,
    onClick,
  } = props;
  const { pathname } = props.location;
  const [modal, setModal] = useState(false);

  const firstAddress = () => {
    return (
      <div>
        <label
          className="text_label"
          // style={{ marginLeft: isListAddress ? "" : "5px" }}
        >
          {data.judul}
          <span className="alamat_utama">
            {" "}
            {data.isAlamatUtama ? "(Alamat Utama)" : ""}
          </span>
        </label>
      </div>
    );
  };

  return (
    <div
      className={
        idCard == data.info_pembelian_id
          ? classname
          : classname + " not-clicked"
      }
      onClick={onClick}
    >
      {isListAddress ? "" : firstAddress()}
      <div>
        {isListAddress ? firstAddress() : ""}
        <div className="content-profile-name">
          <label className="text-profile-name left">{data.name}</label>
          <label className="label-profile-name right">{data.nomor_hp}</label>
        </div>
        <div className="content-profile-address">
          <div className="map_pin-address-profile" id="pin-point">
            <FaIcon
              icon="fas map-marker-alt"
              className="fa-lg"
              style={{ color: data.latitude ? "red" : "gray" }}
            />
          </div>
          <div className="content-address">
            <label className="text-address">{data.alamat}</label>
          </div>
        </div>
      </div>
      <FormPembelian
        modal={modal}
        setModal={setModal}
        trigger={trigger}
        setTrigger={setTrigger}
        where={"update"}
        id={data.id_info_pembelian}
        edit={data}
      />
    </div>
  );
}
export default withRouter(CardAlamatMobile);
