import { fetchError, fetchStart, fetchSuccess } from "../commons/actions";

import axios from "axios";
import * as type from "./actions";
import token from "../../../components/shared/constants/ConstToken";

const URL = process.env.REACT_APP_BASE_URL;

export const getData = (keyword, limit, page, sortBy, isInit) => {
  const body = {
    limit: limit,
    page: page,
    latitude: parseFloat(sessionStorage.getItem("latPosition")),
    longitude: parseFloat(sessionStorage.getItem("longPosition")),
    search: keyword,
    sort_by: sortBy,
  };
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .post(URL + `/api/v1/search_produk_terdekat`, body, {
        headers: {
          token: `${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchSuccess("New category was added successfully."));
          dispatch({ type: type.GET_DATA, payload: res.data, isInit: isInit });
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError("There was something issue in responding server"));
      });
  };
};

export const getSimilarData = (param) => {
  const { count, lapak_id, page, pasar_id } = param;
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .get(
        URL +
          `/api/v1/get_list_produk?count=${count}&lapak_id=${lapak_id}&page=${page}&pasar_id=${pasar_id}`,
        {
          headers: {
            token: `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchSuccess("New category was added successfully."));
          dispatch({
            type: type.GET_SIMILAR_DATA,
            payload: res.data.data.list,
          });
        } else {
          // dispatch(fetchError("There was something issue in responding server."));
        }
      })
      .catch((error) => {
        // dispatch(fetchError("There was something issue in responding server"));
      });
  };
};

export const getDataByCategory = (payload) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .post(URL + `/api/v1/get_produk_terlaris`, payload, {
        headers: {
          token: `${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchSuccess("New category was added successfully."));
          dispatch({
            type: type.GET_DATA_BY_CATEGORY,
            payload: res.data,
          });
        } else {
          dispatch(fetchError(res.data.error));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const clearData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch({ type: type.CLEAR_DATA });
    dispatch(fetchSuccess("Data cleared"));
  };
};
