import React, { useEffect, useState } from "react";
import "./toast.scss";
import PropTypes from "prop-types";

export default function ToastNotification(props) {
  const { isOpen, status, message, setToast } = props;
  const [visible, setVisible] = useState("");
  const [stat, setStatus] = useState("");

  const show = (s) => {
    setVisible("custom-toast--visible");
    setTimeout(() => {
      hide();
    }, 1500);
    switch (s) {
      case "success":
        setStatus("custom-toast--success");
        break;
      case "error":
        setStatus("custom-toast--error");
        break;
      default:
        break;
    }
  };

  const hide = () => {
    setVisible("");
    setToast({ isOpen: false, status: "", message: "" });
  };

  useEffect(() => {
    if (isOpen) {
      show(status);
    } else {
      hide();
    }
  }, [isOpen]);

  return (
    <div className={`custom-toast ${visible} ${stat}`}>
      <div className="body-toast">{message}</div>
    </div>
  );
}
ToastNotification.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  setToast: PropTypes.func.isRequired,
};
