import * as types from "./actions";

const initialState = {
  loading: false,
  products: [],
  similar: [],
  product: {},
  pageProduct: null,
  totalProduct: null,
  error: null,
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_DATA:
      return {
        ...state,
        products: action?.isInit
          ? action.payload.data
          : [...state.products, ...action.payload.data],
        pageProduct: action.payload.total_page,
        totalProduct: action.payload.total_product,
      };
    case types.GET_SIMILAR_DATA:
      return { ...state, similar: action.payload };
    case types.GET_DATA_BY_CATEGORY:
      return {
        ...state,
        products: action.payload.data,
        pageProduct: action.payload.total_page,
        totalProduct: action.payload.total_product,
      };
    case types.CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}
