const FETCH_DATA = 1;
const FETCH_COMPLETE = 2;
const INCREMENT = "INCREMENT";
const DECREMENT = "DECREMENT";
const ADD_PRODUCT = "ADD_PRODUCT";
const REMOVE_PRODUCT = "REMOVE_PRODUCT";
const ADD_QUANTITY = "ADD_QUANTITY";
const SET_LOADING = 3;
const RESET = 4;
const SET_SUB_TOTAL = 5;
const SET_TOTAL = 6;
const SET_SHIPPING = 7;
const SET_RELOAD = 8;
const FETCH_CHECKOUT = 9;
const FETCH_COMPLETE_CHECKOUT = 10;
const MENUNGGU = "Menunggu";
const DALAM_PROSES = "Dalam Proses";
const PESANAN_DIKIRIM = "Pesanan Dikirim";
const PESANAN_SELESAI = "Pesanan Selesai";
const PESANAN_DITOLAK = "Pesanan Ditolak";

export {
  FETCH_COMPLETE,
  FETCH_DATA,
  FETCH_CHECKOUT,
  FETCH_COMPLETE_CHECKOUT,
  INCREMENT,
  DECREMENT,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  ADD_QUANTITY,
  SET_LOADING,
  RESET,
  SET_SUB_TOTAL,
  SET_TOTAL,
  SET_SHIPPING,
  MENUNGGU,
  SET_RELOAD,
  PESANAN_DIKIRIM,
  PESANAN_DITOLAK,
  PESANAN_SELESAI,
  DALAM_PROSES,
};
