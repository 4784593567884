import * as types from "./actions";

const initialState = {
  faq: [],
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_DATA:
      return {
        ...state,
        faq: action.payload.Data,
      };

    default:
      return state;
  }
}
