import { differenceInSeconds } from "date-fns";
import Cookie from "js-cookie";
import {
  loginWithGoogle,
  registerWithGoogle,
} from "../services/LoginSocialMediaService";
import expiredStorage from "../utils/expiredStorage";

const responseGoogle = (response, setErrorText, setShowError) => {
  const checkError = (err) => {
    switch (err) {
      case "Not Found":
        setErrorText(
          "Akun sudah terdaftar, silahkan login menggunakan email dan password"
        );
        break;
      case "Failed to create session":
        setErrorText("Anda sudah melakukan login sebelumnya");
        break;
      default:
        setErrorText("Terjadi Kesalahan");
        break;
    }
  };

  if (response.error) {
  } else {
    registerWithGoogle(response.tokenId)
      .then((res) => {
        loginWithGoogle(response.tokenId)
          .then((res) => {
            const now = new Date(Date.now());
            const expired = new Date(res.data.Data.expired_at * 1000);
            const seconds = differenceInSeconds(expired, now);
            expiredStorage.setItem("@user", res.data.Data.session_id, seconds);
            expiredStorage.setItem(
              "@tipelogin",
              res.data.Data.login_with_socmed,
              seconds
            );
            Cookie.remove("user_guest");
            Cookie.remove("chart_guest");
            Cookie.remove("chart_length_guest");
            window.location.reload();
          })
          .catch((err) => {
            if (err.response) {
              checkError(err.response.data.Deskripsi);
              setShowError(true);
            } else {
              setErrorText("Anda Sudah Pernah Mendaftar Menggunakan Email ini");
              setShowError(true);
            }
          });
      })
      .catch((err) => {
        loginWithGoogle(response.tokenId)
          .then((res) => {
            const now = new Date(Date.now());
            const expired = new Date(res.data.Data.expired_at * 1000);
            const seconds = differenceInSeconds(expired, now);
            expiredStorage.setItem("@user", res.data.Data.session_id, seconds);
            expiredStorage.setItem(
              "@tipelogin",
              res.data.Data.login_with_socmed,
              seconds
            );
            Cookie.remove("user_guest");
            Cookie.remove("chart_guest");
            Cookie.remove("chart_length_guest");
            window.location.reload();
          })
          .catch((err) => {
            if (err.response) {
              checkError(err.response.data.Deskripsi);
              setShowError(true);
            } else {
              setErrorText("Anda Sudah Pernah Mendaftar Menggunakan Email ini");
              setShowError(true);
            }
          });
      });
  }
};

export default responseGoogle;
