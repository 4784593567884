export const fetchSuccess = (message) => {
  return (dispatch) => {
    dispatch({
      type: "SUCCESS",
      payload: message || "",
    });
  };
};
export const fetchError = (error) => {
  return (dispatch) => {
    dispatch({
      type: "ERROR",
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return (dispatch) => {
    dispatch({
      type: "START",
    });
  };
};
