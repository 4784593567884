import { differenceInMilliseconds, differenceInSeconds } from "date-fns";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  NavLink,
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import Cookie from "js-cookie";
import Notification from "components/Notification";
import NotificationItem from "components/Notification/_Item";
// assets
import IcChatAdmin from "../../../assets/icon/ic-chat-admin.svg";
import ShoppingBag from "../../../assets/icon/shopping-bag.svg";
import DropdownProfile from "../../../components/dropdown/DropdownProfile";
import Footer from "../../../components/footer/Footer";
import Navbar from "../../../components/navbar/Navbar";
import { FETCH_COMPLETE, FETCH_DATA } from "../../../components/reducer/Action";
import {
  customerReducer,
  customerState,
} from "components/reducer/CustomerReducer";
import SearchBar from "components/search/SearchBar";
import SearchNav from "components/search/SearchNav";
import { getListCart } from "components/services/CartService";
import { generateCidSecret, getToken } from "components/services/CidService";
import { getProfileCustomer } from "components/services/CustomerService";
import localId from "components/shared/constants/ConstantsIdCustomer";
import cookieGuest from "components/shared/constants/ConstantsGuestCustomer";
import FaIcon from "components/shared/font-awesome/FaIcon";
import {
  getOnePositionPage,
  useVisibilityComponent,
} from "../../../components/utils/condition";
import expiredStorage from "../../../components/utils/expiredStorage";
import ScrollToTop from "../../../components/utils/ScrollToTop";
import productContext from "../../../contex/context/product-context";
import { checkSessionId } from "./Profile";
import NewLoginModal from "../../../components/modal/NewLoginModal";

// assets
import "scss/footer/footer.scss";
const DefaultProfile =
  "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png";

const Page = (props) => {
  const City = "https://i.ibb.co/mCCXz96/logo-city.png";
  const history = useHistory();
  const [expand, setExpand] = useState(false);
  const [btnSearch, setBtnSearch] = useState(true);
  const [textCart, setTextCart] = useState(false);
  const [cityLogo, setCityLogo] = useState(false);
  const [search, setSearch] = useState(true);
  const [searchMobile, setSearchMobile] = useState(true);
  const [iconCart, setIconCart] = useState(true);
  const [open, setOpen] = useState(false);
  const [userIcon, setUserIcon] = useState(true);
  const [btnBack, setBackBtn] = useState(false);
  const [leftCityLogo, setLeftCityLogo] = useState(false);
  const [state, dispatch] = useReducer(customerReducer, customerState);
  const [isMobile, setMobile] = useState(window.innerWidth <= 767);
  const [renderButton, setRenderButton] = useState("");
  const [isLoginOrRegist, setIsLoginOrRegist] = useState("");
  const {
    cart,
    fetchCart,
    fetchCartComplete,
    fetchTotalCart,
    fetchSubtotalCart,
    fetchShippingCart,
  } = useContext(productContext);
  const fetchCustomer = () => dispatch({ type: FETCH_DATA });
  const fetchCustomerComplete = (payload) =>
    dispatch({ type: FETCH_COMPLETE, payload });
  const { id, subDomainPasar, token } = useParams();
  const location = useLocation();
  const profileRef = useRef(null);
  const [showNotif, setShowNotif] = useState(false);

  const { totalUnread } = useSelector(({ chat }) => chat);

  const now = new Date(Date.now());

  const isUserExist = Boolean(localId);
  const isGuestExist = Boolean(cookieGuest);
  const chartGuest = Cookie.get("chart_guest");
  const chartLengthGuest = Cookie.get("chart_length_guest");

  let lat = expiredStorage.getItem("@lat");
  let long = expiredStorage.getItem("@long");

  useEffect(() => {
    // add when mounted
    document.addEventListener("click", clickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  const clickOutside = (e) => {
    if (profileRef && !profileRef?.current?.contains(e.target)) {
      setOpen(false);
    } else {
      if (open) setOpen(true);
    }
  };

  //when window was scrolled
  // const showComponent = () => {
  //   if (
  //     windowScrollY(360) &&
  //     windowScreenWidth(680) &&
  //     pagePosition(location.pathname, "/")
  //   ) {
  //     setBtnSearch(true);
  //   } else {
  //     setBtnSearch(false);
  //   }
  // };
  // window.addEventListener("scroll", showComponent);

  //click button search
  const btnClicked = () => {
    setExpand(!expand);
  };

  const updateMedia = () => {
    setMobile(window.innerWidth <= 767);
  };

  const showWidthComponent = (isBool) => {
    if (isBool <= 680) {
      setSearch(false);
      setSearchMobile(true);
      setLeftCityLogo(false);
      switch (location.pathname) {
        case "/login":
          setBackBtn(true);
          setRenderButton(true);
        case "/register":
          setUserIcon(false);
          // setIconCart(false);
          setBackBtn(true);
          setRenderButton(true);
          break;
        case `/${id}/detail`:
          setBackBtn(false);
          setLeftCityLogo(true);
          break;
        case "/":
          setBackBtn(false);
          // setIconCart(false);
          setUserIcon(true);
          break;
        case "/list-order":
          setUserIcon(false);
          break;
        case `/auth/token/${token}`:
          setBackBtn(false);
          break;
        default:
          setBackBtn(true);
          // setTextCart(false);
          break;
      }
    }
    if (isBool > 680) {
      // setIconCart(false);
      // setTextCart(true);
      setSearch(true);
      setSearchMobile(false);
      setLeftCityLogo(false);
      if (location.pathname === `/${id}/detail`) {
        setCityLogo(true);
      }
    }
  };

  useEffect(() => {
    showWidthComponent(window.screen.width);
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/register"
    ) {
      setIsLoginOrRegist(location.pathname);
    } else {
      setIsLoginOrRegist("");
    }
    if (expiredStorage.getItem("@token")) {
      setTimeout(() => {
        expiredStorage.removeItem("@token");
        window.location.reload();
      }, expiredStorage.getTimeLeft("@token") * 1000);
    }
    if (localId && localId !== null) {
      fetchCustomer();
      getProfileCustomer(localId)
        .then((res) => {
          if (res.data.Data.daftar_alamat) {
            lat = res.data.Data.daftar_alamat[0].latitude;
            long = res.data.Data.daftar_alamat[0].longitude;
          }
          fetchCustomerComplete(res.data.Data);
          expiredStorage.setItem("@userEmail", res?.data?.Data?.email);
        })
        .catch((err) => {
          checkSessionId(err, props);
        });
    }

    if (localId && localId !== null) {
      fetchCart();
      getListCart(lat, long).then((res) => {
        fetchCartComplete(res.chart_list);
        fetchShippingCart(res.shipping);
        fetchSubtotalCart(res.subtotal);
      });
    } else fetchCartComplete([]);

    if (!expiredStorage.getItem("@token")) {
      generateCidSecret().then((res) => {
        getToken(res.data.Data).then((res) => {
          const expired = new Date(res.data.Data.token_active);
          const second = differenceInSeconds(expired, now);
          const millisecond = differenceInMilliseconds(expired, now);
          expiredStorage.setItem("@token", res.data.Data.token, second);
          window.location.reload();
        });
      });
    }
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, [cart.reload, props.location]);

  //search in dekstop
  const componentNav = getOnePositionPage(
    location.pathname,
    "/",
    "",
    <>
      {useVisibilityComponent(
        <>
          <hr className="solid" />
          <img className="logo-city" src={City} alt="logo-city" />
        </>,
        cityLogo
      )}
      {useVisibilityComponent(<SearchBar key={1} classCss="bg-grey" />, search)}
    </>
  );

  const componentNavMbl = getOnePositionPage(
    location.pathname,
    "/",
    "",
    <>
      {useVisibilityComponent(
        <>
          <hr className="solid" />
          <img className="logo-city" src={City} alt="logo-city" />
        </>,
        cityLogo
      )}
      {useVisibilityComponent(
        <SearchBar key={1} classCss="bg-grey" />,
        searchMobile
      )}
    </>
  );

  const lengthCart = () => {
    let rows = 0;
    if (isUserExist) {
      const lapak = cart.cart?.lapak_list;
      if (!Boolean(cart.isLoading) && Boolean(lapak)) {
        lapak.map((res) => {
          res.chart_list.map((l) => {
            rows += l.quantity;
          });
        });
      }
    }

    if (isGuestExist && chartLengthGuest) {
      const parsedChartGuest = chartGuest && JSON.parse(chartGuest);

      parsedChartGuest.lapak_list.map((item) => {
        rows += item.quantity;
      });
    }
    return rows;
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const onCloseModal = () => setModalOpen(!isModalOpen);

  const renderNavbarWithoutUser = (isMobile, renderButton, expand) => {
    const style = {
      border: "0",
      borderRadius: "60px",
      fontWeight: "bold",
      display: Boolean(renderButton) ? "none" : "block",
      cursor: "pointer",
    };

    const handleClickMasuk = () => {
      if (!["/login", "/register"].includes(window.location.pathname))
        setModalOpen(!isModalOpen);
    };

    if (isMobile) {
      return (
        <NavLink
          style={{
            ...style,
            color: "#9CA3AF",
            padding: "0px 0.5rem 0px 1.25rem",
          }}
          className={`link-login ${expand ? "expanded" : ""}`}
          to="/login"
        >
          <FaIcon icon="far user" />
        </NavLink>
      );
    }

    return (
      <div
        style={{
          ...style,
          backgroundColor: "#7A8D3F",
          color: "#FFF",
          margin: "auto",
          marginLeft: "5px",
        }}
        className={`link-login ${expand ? "expanded" : ""}`}
        onClick={() => handleClickMasuk()}
      >
        <label
          className={`label ${expand ? "expanded" : ""}`}
          style={{ marginBottom: 0, cursor: "pointer" }}
        >
          Masuk
        </label>
        <FaIcon
          className="ico-user"
          icon="far user"
          style={{ marginLeft: "15px" }}
        />
      </div>
    );
  };

  return (
    <>
      <ScrollToTop>
        <Navbar
          showLogo={expand}
          backBtn={btnBack}
          city={leftCityLogo}
          cityImg={City}
          isLoginOrRegist={isLoginOrRegist}
          style={{ display: !isMobile ? "flex" : "flex", flexWrap: "wrap" }}
        >
          {componentNav}
          {location.pathname === `/auth/token/${token}` ? (
            ""
          ) : (
            <div className={expand ? "expand-content-nav" : "content-nav"}>
              <div className={expand ? "expand-cart-style" : "cart-style"}>
                {/* <SearchNav expand={expand} clicked={btnClicked} /> */}
                {/* <FaIcon
                id="btn_search_mobile_di_navbar"
                className={expand ? "d-none" : ""}
                icon="fas search"
                style={{
                  display:
                    isMobile &&
                    window.location.pathname !== "/login" &&
                    window.location.pathname !== "/register" &&
                    !expand
                      ? "block"
                      : "none",
                }}
                onClick={() => {
                  setExpand(!expand);
                  if (btnBack) {
                    setBackBtn(!btnBack);
                  }
                }}
              /> */}
                {isUserExist || isGuestExist ? (
                  <React.Fragment>
                    {!isMobile && (
                      <div className="d-flex">
                        {/* {isUserExist && (
                        <React.Fragment>
                          <NavLink className="ml-2 ico-link" to="/chat">
                            <div className="cart-icon">
                              <FaIcon
                                style={{ width: "1em", height: "1.5em" }}
                                icon="far comment"
                              />
                              {totalUnread ? (
                                <div className="count-wrapper">
                                  <div className="count-cart">
                                    {totalUnread}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </NavLink>

                          <div
                            className="ml-2 ico-link cursor-pointer"
                            onClick={() => setShowNotif(true)}
                          >
                            <div className="cart-icon">
                              <FaIcon
                                style={{ width: "1em", height: "1.5em" }}
                                icon="far bell"
                              />
                            </div>
                            {showNotif && (
                              <Notification
                                className="notification-navbar"
                                showHandler={() => setShowNotif(!showNotif)}
                              >
                                <NotificationItem>
                                  Belum ada pemberitahuan
                                </NotificationItem>
                              </Notification>
                            )}
                          </div>
                        </React.Fragment>
                      )} */}

                        <NavLink className="ml-2 ico-link" to="/cart">
                          <div className="cart-icon cart">
                            <img
                              src={ShoppingBag}
                              style={{ width: "1.3rem" }}
                            />
                            {lengthCart() ? (
                              <div className="count-wrapper">
                                <div className="count-cart">{lengthCart()}</div>
                              </div>
                            ) : null}
                          </div>
                        </NavLink>
                      </div>
                    )}

                    <NavLink
                      className="ico-link"
                      to="/cart"
                      style={{
                        display:
                          isMobile &&
                          window.location.pathname !== "/login" &&
                          window.location.pathname !== "/register" &&
                          !expand
                            ? "block"
                            : "none",
                      }}
                    >
                      <div className="cart-icon cart">
                        <img src={ShoppingBag} style={{ width: "1.3rem" }} />
                        {lengthCart() ? (
                          <div className="count-wrapper">
                            <div className="count-cart">{lengthCart()}</div>
                          </div>
                        ) : null}
                      </div>
                    </NavLink>

                    {location.pathname !== "/login" &&
                    location.pathname !== "/register" ? (
                      <div
                        className="container-profile"
                        ref={profileRef}
                        style={{
                          marginTop: isGuestExist ? "0.25rem" : "0px",
                        }}
                      >
                        {isGuestExist && (
                          // <NavLink
                          //   style={{
                          //     border: "0",
                          //     borderRadius: "60px",
                          //     fontWeight: "bold",
                          //     color: "black",
                          //     padding: "0px 0.5rem",
                          //   }}
                          //   to="/login"
                          // >
                          //   <FaIcon icon="far user" />
                          // </NavLink>
                          <label
                            htmlFor="check"
                            onClick={() => setOpen(!open)}
                            className="burger-icon"
                          >
                            <FaIcon icon="far user" />
                          </label>
                        )}

                        {isUserExist && isMobile && (
                          <label
                            htmlFor="check"
                            onClick={() => setOpen(!open)}
                            className="burger-icon"
                          >
                            <FaIcon icon="fas bars" />
                          </label>
                        )}

                        {userIcon && isUserExist && !isMobile ? (
                          <div
                            className="profiles"
                            onClick={() => setOpen(!open)}
                          >
                            <div style={{ cursor: "pointer" }}>
                              {state.customer.nama_depan}
                            </div>
                            <img
                              id="img-profile"
                              src={DefaultProfile}
                              alt="profile image"
                              style={{
                                width: "1.5em",
                                marginLeft: "10px",
                                cursor: "pointer",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                        ) : null}
                        {open && (
                          <DropdownProfile
                            setOpen={setOpen}
                            open={open}
                            isMobile={isMobile}
                          />
                        )}
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : (
                  renderNavbarWithoutUser(isMobile, renderButton, expand)
                )}
                <RenderTopNavLink button={renderButton} />
              </div>
            </div>
          )}
          {componentNavMbl}
        </Navbar>
        {/* <div>{props.children}</div>
      {Boolean(props.footer) ? null : <Footer {...props} />} */}
        <div>
          {props.children}
          {/* {subDomainPasar && localId ? (
            <button
              className="btn btn-chat-admin"
              onClick={() => history.push("/chat?createRoom=true")}
            >
              <img alt="" src={IcChatAdmin} />
              <span className="label-btn-chat ml-2">CHAT</span>
            </button>
          ) : (
            <></>
          )} */}
        </div>
        {Boolean(props.footer) ? null : <Footer {...props} />}
        <NewLoginModal isOpen={isModalOpen} onClose={onCloseModal} />
      </ScrollToTop>
    </>
  );
};

const RenderTopNavLink = React.memo(
  (props) => {
    const { button } = props;

    const location = useLocation();
    const location_splitted = location.pathname.split("/").join("");

    const login = location_splitted === "login";

    if (button) {
      return (
        <NavLink
          style={{
            fontSize: "0.9rem",
            fontWeight: 600,
            border: "0",
            color: "#7A8D3F",
            margin: "auto 0.75rem",
          }}
          to={login ? "/register" : "/login"}
        >
          {login ? "Daftar" : "Masuk"}
        </NavLink>
      );
    }
    return null;
  },
  (prevProps, nextProps) => {
    if (prevProps.button !== nextProps.button) {
      return false;
    }
  }
);

export default withRouter(Page);
