import {
  FETCH_DATA,
  FETCH_COMPLETE,
  SET_LOADING,
  SET_SUB_TOTAL,
  SET_TOTAL,
  SET_SHIPPING,
  SET_RELOAD,
} from "../../components/reducer/Action";
const cartForm = {
  id: "",
  id_order: "",
  id_user: "",
  id_produk: "",
  id_lapak: "",
  id_pasar: "",
  transaction_type: "",
  quantity: 0,
  date_created: "",
  date_updated: "",
  price: 0,
  unit: "",
};

export const cartState = {
  cart: {},
  form: { ...cartForm },
  isLoading: false,
  reload: false,
  subTotal: 0,
  total: 0,
  shipping: 0,
};

export default function CartReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_DATA:
      return { ...state, isLoading: true };
    case FETCH_COMPLETE:
      return { ...state, cart: payload, isLoading: false };
    case SET_LOADING:
      return { ...state, reload: !state.reload };
    case SET_SUB_TOTAL:
      return { ...state, subTotal: payload };
    case SET_TOTAL:
      return { ...state, total: state.subTotal + state.shipping };
    case SET_SHIPPING:
      return { ...state, shipping: payload, reload: false };
    case SET_RELOAD:
      return { ...state, reload: true };
    default:
      return { ...state };
  }
}
