import React from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import currencyFormat from "../../../../components/utils/currencyFormat";
import DefaultPasarImgRandomizer from "../../../../components/utils/DefaultPasarImgRandomizer";
import handleErrorImg from "../../../../components/utils/handleErrorImage";
import { color, status } from "../../../../components/utils/statusOrder";
import { splitDate } from "../../../../utils/function";

export default function CardOrder({ data }) {
  const history = useHistory();

  const generateFoto = () => {
    let rows = (
      <>
        <div className="col-3 px-1">
          <Skeleton height={50} />
        </div>
        <div className="col-3 px-1">
          <Skeleton height={50} />
        </div>
        <div className="col-3 px-1">
          <Skeleton height={50} />
        </div>
        <div className="col-3 px-1">
          <Skeleton height={50} />
        </div>
      </>
    );
    if (true) {
      rows = Array(1)
        .fill()
        .map((res, i) => {
          if (i < 4)
            return (
              <div key={i} className="col-3 px-1">
                <img
                  className="gambar_product_list"
                  // src={DefaultProductRandomizer()}
                  src={data.image_pasar}
                  alt={data.nama_pasar}
                  onError={(e) =>
                    handleErrorImg(e, DefaultPasarImgRandomizer())
                  }
                />
              </div>
            );
        });
    }
    return rows;
  };
  return (
    <div
      className="col-md-6 col-12 card_order_list"
      onClick={() => history.push(`/detailorder/${data.id}`)}
    >
      <div className="invoice_status_list">
        <label className="col-6 m-0 label_invoice_list">{data.invoice}</label>
        <label
          className="col-6 m-0 label_status_list label_align_right_list"
          style={{ color: color(data.status) }}
        >
          {status(data.status)}
        </label>
      </div>
      <div className="market_date_list">
        <label className="col-6 m-0 label_market_list">{data.nama_pasar}</label>
        <label className="col-6 m-0 label_date_list label_align_right_list">
          {splitDate(data.date_created).full}
        </label>
      </div>
      <div className="foto_total_list">
        <div className="col-8 content_foto_list">{generateFoto()}</div>
        <div className="col-4 label_align_right_list">
          <label className="m-0 label_total_list">Total Belanja</label>
          <label className="m-0 label_total_price_list">
            Rp{currencyFormat(data.total_price + data.shipping)}
          </label>
        </div>
      </div>
    </div>
  );
}
