import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faPowerOff,
  faBell,
  faExclamationCircle,
  faAngleRight,
  faStoreAlt,
  faUsers,
  faMapMarkerAlt,
  faSearch,
  faShoppingCart,
  faMinusCircle,
  faPlusCircle,
  faCrosshairs,
  faUserCircle,
  faArrowLeft,
  faArrowRight,
  faUserCog,
  faFileInvoice,
  faSignInAlt,
  faSignOutAlt,
  faCheckCircle,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBars,
  faPowerOff,
  faBell,
  faExclamationCircle,
  faAngleRight,
  faStoreAlt,
  faUsers,
  faMapMarkerAlt,
  faSearch,
  faShoppingCart,
  faMinusCircle,
  faPlusCircle,
  faUserCircle,
  faCrosshairs,
  faArrowLeft,
  faArrowRight,
  faUserCog,
  faFileInvoice,
  faSignInAlt,
  faSignOutAlt,
  faCheckCircle,
  faEye
);

export default library;
