import React from "react";
import Page from "./Page";
import BreadCrumb from "../../../components/breadcrump/BreadCrumb";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import "../../../scss/home/carabelanja.scss";

// import image
import stepOne from "../../../assets/how-to-shop/how1.png";
import stepTwo from "../../../assets/how-to-shop/how2.png";
import stepThree from "../../../assets/how-to-shop/how3.png";
import icCheck from "../../../assets/check.svg";

export default function CaraBelanja() {
  const history = useHistory();
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter((x) => x);

  const step = [
    {
      image: stepOne,
      data: {
        stepOne: "Pilih Pasar dimana kamu akan belanja",
        stepTwo: "Pilih pedagang/item",
      },
    },
    {
      image: stepTwo,
      data: {
        stepOne: "Masukan Keranjang lalu checkout",
        stepTwo: "Tunggu Admin mengkonfirmasi Pesananmu",
      },
    },
    {
      image: stepThree,
      data: {
        stepOne: "Transfer sesuai dengan Total belanjaan",
        stepTwo: "Belanjaan akan di kirim Tempat Kamu",
      },
    },
  ];

  return (
    <Page>
      {/* <BreadCrumb /> */}
      <MUIBreadcrumbs aria-label="breadcrumb">
        {pathnames.length > 0 ? (
          <Link onClick={() => history.push("/")}>Home</Link>
        ) : (
          <Typography> Home </Typography>
        )}
        {pathnames.length > 0 ? (
          <Link>Cara Belanja</Link>
        ) : (
          <Typography>Cara Belanja</Typography>
        )}
      </MUIBreadcrumbs>
      <div className="container">
        <div className="jumbotron-how-to-shop">
          <div className="text-jumbotron">
            <div className="title">Cara Belanja</div>
            <div className="desc">Lihat cara berbelanja di Pasar.id</div>
          </div>
        </div>

        <div className="how-to-shop-content">
          <div className="row">
            {step.map((res, i) => (
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="box-step">
                  <div
                    className="image-box"
                    style={{ backgroundImage: `url(${res.image})` }}
                  ></div>

                  <div className="step-content mt-3">
                    <ul>
                      <li>
                        <b>
                          <img src={icCheck} alt="icon check" />{" "}
                          {res.data.stepOne}
                        </b>
                      </li>
                      <li>
                        <b>
                          <img src={icCheck} alt="icon check" />{" "}
                          {res.data.stepTwo}
                        </b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="info-box">
            <div className="have-question">
              Mempunyai pertanyaan? Lihat{" "}
              <a
                href="/cara-belanja"
                style={{ color: "#EF3F3A", textDecoration: "underline" }}
              >
                Layanan Bantuan
              </a>
            </div>
            <div className="informasi">untuk informasi lebih lanjut</div>
          </div> */}
        </div>
      </div>
    </Page>
  );
}
