import localId from "../shared/constants/ConstantsIdCustomer";

const { default: client } = require("../../utils/axios");

async function addToCart(id) {
  let form = {
    session_id: `${localId}`,
    id_produk: `${id.idProduct}`,
    id_lapak: `${id.idLapak}`,
    id_pasar: `${id.idPasar}`,
    quantity: parseInt(id.quantity),
  };
  if (id.catatan) {
    form = {
      ...form,
      catatan: `${id.catatan}`,
    };
  }
  //section untuk membedakan page cart dan page lain agar di page cart qty tidak bisa kurang dari 1
  if (id.section) {
    form = {
      ...form,
      isSection: id.section,
    };
  }
  const response = await client.post("api/v1/add_to_chart", form);
  return response;
}

async function getListCart(lat, long) {
  const { data } = await client.get(
    `api/v1/get_chart_list?session_id=${localId}&latitude=${lat}&longitude=${long}`
  );
  return data;
}

async function buyProduct(idPasar, shipping, list_produk, lat, lng, shipTime) {
  const form = {
    id_pasar: `${idPasar}`,
    session_id: localId,
    shipping: parseInt(shipping),
    latitude: lat,
    longitude: lng,
    list_produk: list_produk,
    estimate_ship_date: shipTime,
  };
  const data = await client.post(`api/v1/checkout`, form);
  return data;
}

async function confirmation(tempCheckout) {
  const { data } = await client.post(
    `api/v1/get_confirmation_product`,
    tempCheckout
  );
  return data;
}

async function getShippingCost(lat, long, idPasar) {
  const { data } = await client.get(
    `api/v1/get_shipping_cost?latitude=${lat}&longitude=${long}&market=${idPasar}`
  );
  return data;
}

async function addCustomOrder(order) {
  const response = await client.post(`api/v1/add_custom_order`, order);
  return response;
}

async function deleteAll() {
  const response = await client.delete(
    `api/v1/delete_all_cart?session_id=${localId}`
  );
  return response;
}
export {
  addToCart,
  getListCart,
  buyProduct,
  addCustomOrder,
  deleteAll,
  confirmation,
  getShippingCost,
};
