import React, { useEffect, useReducer, useState } from "react";
import { withRouter } from "react-router-dom";
import { Modal, Spinner } from "reactstrap";

import Maps from "./Maps";
import "../../scss/form/modal.scss";
import FaIcon from "../shared/font-awesome/FaIcon";
import { FETCH_DATA, SET_LOADING } from "../reducer/Action";
import { addressReducer, addressState } from "../reducer/AddressReducer";
import { saveAddress, updateAddress } from "../services/CustomerService";
import { getKotaOpenstreetMap } from "components/services/LocationService";

function FormPembelian(props) {
  const [state, dispatch] = useReducer(addressReducer, addressState);
  const {
    id,
    edit,
    modal,
    where,
    toast,
    trigger,
    setModal,
    setToast,
    saveAndGo,
    setIsEdit,
    setTrigger,
    clearToast,
    setTriggerAlamat,
    triggerAlamat,
  } = props;

  const [map, setMap] = useState(false);
  const alamat = {
    id: id,
    title: "",
    receiver: "",
    hp: "",
    address: "",
    latitude: "",
    longitude: "",
  };

  const validation = {
    title: "",
    receiver: "",
    hp: "",
    address: "",
    isTitle: false,
    isReceiver: false,
    isHp: false,
    isAddress: false,
  };
  const styleError = { color: "red" };

  const [error, setError] = useState({ ...validation });
  const [dress, setDress] = useState({ ...alamat });
  const [locationName, setLocationName] = useState("");

  const fetchData = () => dispatch({ type: FETCH_DATA });
  const setLoading = () => dispatch({ type: SET_LOADING });

  useEffect(() => {
    if (dress.latitude && dress.longitude) {
      getKotaOpenstreetMap(dress.latitude, dress.longitude).then((res) => {
        setLocationName(res.display_name);
      });
    }
  }, [dress.longitude, dress.longitude]);

  useEffect(() => {
    if (where == "update") {
      setDress({
        ...dress,
        id: id,
        title: edit.judul,
        receiver: edit.name,
        hp: edit.nomor_hp,
        address: edit.alamat,
        latitude: edit.latitude,
        longitude: edit.longitude,
      });
    }
  }, [modal, id]);

  const errorTimeOut = () => {
    setTimeout(() => {
      setError({ ...error, validation });
    }, 3000);
  };

  const clearAddress = () => {
    if (where != "update") {
      setTimeout(() => {
        setDress({ ...alamat });
      }, 2000);
    }
  };

  const errorWhenNotAllFill = () => {
    setError({
      ...error,
      title: "Field tidak boleh kosong",
      receiver: "Field tidak boleh kosong",
      hp: "Field tidak boleh kosong",
      address: "Field tidak boleh kosong",
      isTitle: true,
      isReceiver: true,
      isHp: true,
      isAddress: true,
    });
  };
  const isEmptyObject = () => {
    return (
      dress.address == "" &&
      dress.hp == "" &&
      dress.receiver == "" &&
      dress.title == ""
    );
  };

  const saveBtn = (e) => {
    errorTimeOut();
    fetchData();
    clearToast();
    if (!isEmptyObject()) {
      if (dress.latitude !== "" && dress.longitude !== "") {
        saveAddress(dress)
          .then((res) => {
            setToast({
              ...toast,
              isOpen: true,
              message: `Sukses menambahkan ${res.data.Data.judul} sebagai alamat`,
              status: "success",
            });
            setLoading();
            clearAddress();
            toggle();
            setTrigger(!trigger);
          })
          .catch((err) => {
            if (err.response.data.Deskripsi === "Validation")
              validationError(
                err.response.data.Data.error.validationError,
                setError,
                error
              );
            else if (
              err.response.data.Data.error ===
              "You can only enter max 5 addresses"
            ) {
              setToast({
                ...toast,
                isOpen: true,
                message:
                  err.response.data.Data.error ??
                  "Anda hanya dapat menambahkan 5 alamat",
                status: "error",
              });
            }
            setLoading();
          });
      } else {
        setToast({
          ...toast,
          isOpen: true,
          message: `Anda belum pin lokasi`,
          status: "error",
        });
        setLoading();
      }
    } else {
      errorWhenNotAllFill();
      setLoading();
    }
  };

  const saveGo = () => {
    errorTimeOut();
    fetchData();
    if (!isEmptyObject()) {
      if (dress.latitude !== "" && dress.longitude !== "") {
        saveAddress(dress)
          .then((res) => {
            clearAddress();
            // saveAndGo();
            setTriggerAlamat(!triggerAlamat);
            toggle();
            setLoading();
            setTrigger(!trigger);
          })
          .catch((err) => {
            console.log(err);
            if (err?.response?.data?.Deskripsi === "Validation")
              validationError(
                err.response.data.Data.error.validationError,
                setError,
                error
              );
            setLoading();
          });
      } else {
        // alert("Anda belum pin lokasi");
        setToast({
          ...toast,
          isOpen: true,
          message: `Anda belum pin lokasi`,
          status: "error",
        });
        setLoading();
      }
    } else {
      errorWhenNotAllFill();
      setLoading();
    }
  };

  const patchAddress = () => {
    errorTimeOut();
    fetchData();
    if (!isEmptyObject()) {
      if (dress.latitude !== "" && dress.longitude !== "") {
        updateAddress(dress)
          .then((res) => {
            setLoading();
            toggle();
            setTrigger(!trigger);
            setIsEdit(false);
            clearAddress();
            setToast({
              ...toast,
              isOpen: true,
              message: `Sukses ubah alamat`,
              status: "success",
            });
          })
          .catch((err) => {
            if (err.response.data.Deskripsi === "Validation") {
              validationError(
                err.response.data.Data.error.validationError,
                setError,
                error
              );
            }

            setToast({
              ...toast,
              isOpen: true,
              message: `Gagal ubah alamat`,
              status: "error",
            });
            setLoading();
          });
      } else {
        // alert("Anda belum pin lokasi");
        setToast({
          ...toast,
          isOpen: true,
          message: `Anda belum pin lokasi`,
          status: "error",
        });
        setLoading();
      }
    } else {
      errorWhenNotAllFill();
      setLoading();
    }
  };

  const numeric = (num) => {
    if (/^[0-9]+$/.test(num) || num === "") {
      setDress({ ...dress, hp: num });
    }
  };
  const generateButtons = (where) => {
    switch (where) {
      case "cart":
        return (
          <button
            id="btn-save-and-go"
            className={`btn_save_go_modal${
              state.isLoading ? "btn_disable" : ""
            }`}
            onClick={() => saveGo()}
            disabled={state.isLoading}
          >
            {state.isLoading === true ? <Spinner size="sm" /> : ""} SIMPAN &
            LANJUTKAN
          </button>
        );
      case "profile":
        return (
          <button
            id="btn-save"
            className={`btn_simpan_modal simpan-form ${
              state.isLoading ? "btn_disable" : ""
            }`}
            onClick={saveBtn}
            disabled={state.isLoading}
          >
            {state.isLoading === true ? <Spinner size="sm" /> : ""} SIMPAN &
            LANJUTKAN
          </button>
        );
      case "update":
        return (
          <button
            id="btn-update"
            className={`btn_simpan_modal ${
              state.isLoading ? "btn_disable" : ""
            }`}
            onClick={patchAddress}
            disabled={state.isLoading}
          >
            {state.isLoading === true ? <Spinner size="sm" /> : ""} SIMPAN
          </button>
        );
      default:
        break;
    }
  };

  const onMobile = () => {
    props.history.push("/listaddress");
  };

  const toggle = () => {
    clearAddress();
    setModal(false);
  };
  return (
    <Modal centered isOpen={modal} backdrop>
      <div style={{ padding: "20px" }}>
        <div className="modal_header-form">
          <label style={{ fontSize: "24px", fontWeight: "bold" }}>
            {where === "update" ? "Ubah Alamat" : "Tambah Alamat"}
          </label>
          <div>
            <button className="btn_close" onClick={toggle}>
              <FaIcon icon="fas times" className="fa-lg" />
            </button>
          </div>
        </div>
        <form>
          <div className="form-group">
            <label htmlFor="alamat" className="text_label">
              Alamat Sebagai
            </label>
            <input
              value={dress.title}
              onChange={(e) => setDress({ ...dress, title: e.target.value })}
              type="text"
              className="form-control input_modal"
              aria-describedby="simpan_alamat"
              placeholder="(Contoh: Alamat rumah, Alamat kantor, dll)"
              minLength="3"
              maxLength="25"
              required
            />
            <small style={{ color: "#757575", float: "right" }}>
              {dress.title?.length}/25
            </small>
            {error.isTitle ? (
              <small style={styleError}>{error.title}</small>
            ) : (
              ""
            )}
          </div>
          <div className="form-group">
            <label htmlFor="alamat" className="text_label">
              Nama Penerima
            </label>
            <input
              value={dress.receiver}
              onChange={(e) => setDress({ ...dress, receiver: e.target.value })}
              type="text"
              className="form-control input_modal"
              aria-describedby="simpan_alamat"
              placeholder="(Contoh: Ibu Budi, Bapak Agus, dll)"
              minLength="3"
              maxLength="25"
              required
            />
            <small style={{ color: "#757575", float: "right" }}>
              {dress.receiver?.length}/25
            </small>
            {error.isReceiver ? (
              <small style={styleError}>{error.receiver}</small>
            ) : (
              ""
            )}
          </div>
          <div className="form-group">
            <label htmlFor="alamat" className="text_label">
              No. Handphone
            </label>
            <input
              value={dress.hp}
              onChange={(e) => numeric(e.target.value)}
              type="text"
              className="form-control input_modal"
              aria-describedby="simpan_alamat"
              placeholder="(Nomor aktif yang bisa dihubungi)"
              maxLength="13"
              minLength="9"
              required
            />
            <small style={{ color: "#757575", float: "right" }}>
              {dress.hp?.length}/13
            </small>
            {error.isHp ? <small style={styleError}>{error.hp}</small> : ""}
          </div>
          <div className="form-group">
            <label htmlFor="alamat" className="text_label">
              Alamat
            </label>
            <textarea
              value={dress.address}
              onChange={(e) => setDress({ ...dress, address: e.target.value })}
              type="text"
              className="form-control input_modal"
              aria-describedby="simpan_alamat"
              placeholder="(Alamat jelas lokasi pengantaran barang)"
              minLength="3"
              required
              maxLength="100"
            />
            <small style={{ color: "#757575", float: "right" }}>
              {dress.address?.length}/100
            </small>
            {error.isAddress ? (
              <small style={styleError}>{error.address}</small>
            ) : (
              ""
            )}
          </div>
          <div className="form-group">
            <label htmlFor="alamat" className="text_label">
              Pilih Lokasi
            </label>
            <div className="map_">
              <div className="map_pin" onClick={() => setMap(!map)}>
                <FaIcon
                  icon="fas map-marker-alt"
                  className="fa-2x"
                  style={{ color: "red" }}
                />
              </div>
              {dress.latitude !== "" && dress.longitude !== "" ? (
                <label className="text_location">{locationName}</label>
              ) : (
                <label className="text_pin">
                  Pin lokasi alamat tujuan pengiriman
                </label>
              )}
            </div>
          </div>
        </form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {generateButtons(where)}
        </div>
        <Maps
          modal={map}
          setModal={setMap}
          setLatLong={setDress}
          latLong={dress}
          isDetailPasar={false}
        />
      </div>
    </Modal>
  );
}

function validationError(e, setError, error) {
  switch (e) {
    case "Judul must be minimum 3 char":
      setError({
        ...error,
        title: "Judul alamat minimal 3 karakter",
        isTitle: true,
      });
      break;
    case "Judul must be char":
      setError({
        ...error,
        title: "Judul alamat hanya menggunakan huruf",
        isTitle: true,
      });
      break;
    case "Name must be minimum 3 char":
      setError({
        ...error,
        receiver: "Nama penerima minimal 3 karakter",
        isReceiver: true,
      });
      break;
    case "Name must be char":
      setError({
        ...error,
        receiver: "Nama penerima hanya menggunakan huruf",
        isReceiver: true,
      });
      break;
    case "NomorHp must be minimum 9 char":
      setError({ ...error, hp: "Nomor HP minimal 9 karakter", isHp: true });
      break;
    case "NomorHp must start with '08'":
      setError({ ...error, hp: "Format nomor hp 08xxxxxxx", isHp: true });
      break;

    case "Alamat must be minimum 3 char":
      setError({
        ...error,
        address: "Alamat minimal 3 karakter",
        isAddress: true,
      });
      break;
    case "Alamat can only use special characters: titik(.), koma(,), slash(/), dash(-)":
      setError({
        ...error,
        address:
          "Alamat hanya bisa menggunakan spesial karakter: titik(.), koma(,), slash(/), dash(-)",
        isAddress: true,
      });
    default:
      setError({
        ...error,
        address: e,
        isAddress: true,
      });
      break;
  }
}
export default withRouter(FormPembelian);
