import React from "react";
import { withRouter } from "react-router-dom";
import "../../scss/profile/addaddress.scss";
import FormPembelian from "../modal/FormPembelian";

function AddAddress(props) {
  // const { where } = props.location;

  return (
    <div>
      <FormPembelian modal={true} where={"profile"} />
    </div>
  );
}
export default withRouter(AddAddress);
