import { lazy } from "react";
import IdleTimer from "./components/utils/IdleTimer";

import ChangePassword from "./components/form/ChangePassword";
import Maps from "./components/modal/Maps";
import AddAddress from "./components/Profile/AddAddress";
import DaftarAlamat from "./components/Profile/DaftarAlamat";
import CaraBelanja from "./containers/layout/page/CaraBelanja";
import OrderList from "./containers/layout/page/OrderList";
import PageNotFound from "./containers/layout/page/PageNotFound";
import VerifikasiAkun from "./containers/layout/page/VerifikasiAkun";
const OrderDetail = lazy(() => import("./containers/layout/page/OrderDetail"));
const BayariWeb = lazy(() => import("./components/modal/BayariWeb"));
const ListUlasan = lazy(() => import("./containers/layout/page/ListUlasan"));
const CartPage = lazy(() => import("./containers/layout/page/CartPage"));
const Cart = lazy(() => import("./containers/layout/page/Checkout/Cart"));
const Checkout = lazy(() =>
  import("./containers/layout/page/Checkout/Checkout")
);
const waiting = lazy(() => import("./containers/layout/page/Checkout/Waiting"));
const ListPasar = lazy(() => import("./containers/layout/page/ListPasar"));
const ListProduct = lazy(() => import("./containers/layout/page/ListProduct"));
const Login = lazy(() => import("./containers/layout/page/LoginPage"));
const DetailPromo = lazy(() => import("./containers/layout/page/DetailPromo"));
const Home = lazy(() => import("./containers/layout/page/Home"));
const RecentMarket = lazy(() => import("./components/market/RecentMarket"));
const Profile = lazy(() => import("./containers/layout/page/Profile"));
const AfterUlasan = lazy(() => import("./containers/layout/page/AfterUlasan"));
const FAQ = lazy(() => import("./containers/layout/page/FAQ"));
const Category = lazy(() => import("./containers/layout/page/Category"));
const ProductByCategory = lazy(() =>
  import("./containers/layout/page/ListProductByCategory")
);
const Wishlist = lazy(() => import("./containers/layout/page/Wishlist"));
const ProductDetail = lazy(() =>
  import("./containers/layout/page/ProductDetail")
);
const ResetPassword = lazy(() =>
  import("./containers/layout/page/ResetPassword")
);
const SelectedPasar = lazy(() =>
  import("./containers/layout/page/ListPasarSelected")
);
const ListSearchPasar = lazy(() =>
  import("./containers/layout/page/ListSearchPasar")
);
const DetailProduct = lazy(() =>
  import("./containers/layout/page/DetailProduct")
);
const KonfirmasiPembayaran = lazy(() =>
  import("./containers/layout/page/KonfirmasiPembayaran")
);

const DetailUlasan = lazy(() =>
  import("./containers/layout/page/DetailUlasan")
);

const DetailVoting = lazy(() =>
  import("./containers/layout/page/DetailVoting")
);

const DetailPengiriman = lazy(() =>
  import("./containers/layout/page/DetailPengiriman")
);

const DetailPemesanan = lazy(() =>
  import("./containers/layout/page/DetailPemesanan")
);

const Chat = lazy(() => import("./containers/layout/page/Chat"));

const MarketDetail = lazy(() =>
  import("./containers/layout/page/MarketDetail")
);
const DetailPedagang = lazy(() =>
  import("./containers/layout/page/DetailPedagang")
);
const Pesanan = lazy(() => import("./containers/layout/page/Pesanan/Pesanan"));

export const routes = [
  {
    path: "/auth/token/:token",
    exact: true,
    component: () => IdleTimer(VerifikasiAkun),
  },
  {
    path: "/",
    exact: true,
    component: () => IdleTimer(Home),
  },
  {
    path: "/maps",
    exact: true,
    component: () => IdleTimer(Maps),
  },
  {
    path: "/changepassword",
    exact: true,
    component: () => IdleTimer(ChangePassword),
  },
  {
    path: "/recent-market",
    exact: true,
    component: () => IdleTimer(RecentMarket),
  },
  {
    path: "/pasar",
    exact: true,
    component: () => IdleTimer(ListPasar),
  },
  {
    path: "/product",
    exact: true,
    component: () => IdleTimer(ListProduct),
  },
  {
    path: "/pasartipe/:tipe/:kota",
    exact: true,
    component: () => IdleTimer(SelectedPasar),
  },
  {
    path: "/search/:keyword/:kota",
    exact: true,
    component: () => IdleTimer(ListSearchPasar),
  },
  {
    path: "/detail-promo",
    exact: true,
    component: () => IdleTimer(DetailPromo),
  },
  {
    path: "/detail-promo/:idPasar",
    exact: true,
    component: () => IdleTimer(DetailPromo),
  },
  {
    path: "/cart1",
    exact: true,
    component: () => IdleTimer(CartPage),
  },
  {
    path: "/cart",
    exact: true,
    component: () => IdleTimer(Cart),
  },
  {
    path: "/checkout",
    exact: true,
    component: () => IdleTimer(Checkout),
  },
  {
    path: "/waiting",
    exact: true,
    component: () => IdleTimer(waiting),
  },
  {
    path: ["/login/", "/register/"],
    exact: true,
    component: Login,
  },

  {
    path: "/profile",
    exact: true,
    component: () => IdleTimer(Profile),
  },
  {
    path: "/pembayaran",
    exact: true,
    component: () => IdleTimer(BayariWeb),
  },
  {
    path: "/listaddress",
    exact: true,
    component: () => IdleTimer(DaftarAlamat),
  },
  {
    path: "/addaddress",
    exact: true,
    component: () => IdleTimer(AddAddress),
  },
  {
    path: "/faq",
    exact: true,
    component: () => IdleTimer(FAQ),
  },
  {
    path: "/category",
    exact: true,
    component: () => IdleTimer(Category),
  },
  {
    path: "/category/:param",
    exact: true,
    component: () => IdleTimer(ProductByCategory),
  },
  {
    path: "/wishlist",
    exact: true,
    component: () => IdleTimer(Wishlist),
  },
  {
    path: "/listorder",
    exact: true,
    component: () => IdleTimer(OrderList),
  },
  {
    path: "/profile/listulasan",
    exact: true,
    component: () => IdleTimer(ListUlasan),
  },
  {
    path: "/detailorder/:idorder",
    exact: true,
    component: () => IdleTimer(OrderDetail),
  },
  {
    path: "/detailpengiriman/:id_pasar",
    exact: true,
    component: () => IdleTimer(DetailPengiriman),
  },
  {
    path: "/detailpemesanan",
    exact: true,
    component: () => IdleTimer(DetailPemesanan),
  },
  {
    path: "/detail-ulasan/:idorder",
    exact: true,
    component: () => IdleTimer(DetailUlasan),
  },
  {
    path: "/detail-voting/:idorder",
    exact: true,
    component: () => IdleTimer(DetailVoting),
  },
  {
    path: "/after-ulasan/:idorder",
    exact: true,
    component: () => IdleTimer(AfterUlasan),
  },

  {
    path: "/KonfirmasiPembayaran",
    exact: true,
    component: () => IdleTimer(KonfirmasiPembayaran),
  },
  {
    path: "/carabelanja",
    exact: true,
    component: () => IdleTimer(CaraBelanja),
  },
  {
    path: "/chat",
    exact: true,
    component: () => IdleTimer(Chat),
  },
  {
    path: "/chat/:roomId",
    exact: true,
    component: () => IdleTimer(Chat),
  },
  {
    path: "/password/reset/:token",
    exact: true,
    component: ResetPassword,
  },
  {
    path: "/password/reset",
    exact: true,
    component: ResetPassword,
  },
  {
    path: "/notfound",
    exact: true,
    component: PageNotFound,
  },
  {
    path: "/productdetail",
    exact: true,
    component: () => IdleTimer(ProductDetail),
  },
  {
    path: "/pesanan/:idorder",
    exact: true,
    component: () => IdleTimer(Pesanan),
  },
  {
    path: "/:subDomainPasar",
    exact: true,
    component: () => IdleTimer(MarketDetail),
  },
  {
    path: "/:subDomainPasar/search/:keyword",
    exact: true,
    component: () => IdleTimer(MarketDetail),
  },
  {
    path: "/:subDomainPasar/:idLapak",
    exact: true,
    component: () => IdleTimer(DetailPedagang),
  },
  {
    path: "/:subDomainPasar/:idLapak/:idProduct",
    exact: true,
    component: () => IdleTimer(DetailProduct),
  },
  {
    path: "/:subDomainPasar/:idLapak/search/:keywords",
    exact: true,
    component: () => IdleTimer(DetailPedagang),
  },
];
