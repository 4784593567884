import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useIdleTimer } from 'react-idle-timer'
import { Route, useHistory } from 'react-router-dom'
import { routes } from '../../routes'
import { logoutCustomer } from '../services/CustomerService'
import localId from '../shared/constants/ConstantsIdCustomer'
import expiredStorage from './expiredStorage'

const SESION_IDLE_MINUTES = 10;

const IdleTimer = (CompossedClass) => {
  const history = useHistory();
  const [idleModal, setIdleModal] = useState(false);

    const handleIdle=(e)=>{
        if(localId){
            if(getRemainingTime()===0){
                logoutCustomer()
                .then(res=>{
                    if(res.data.Deskripsi==="Success Logout"){
                        localStorage.removeItem("@user");
                        localStorage.removeItem("@tipelogin");
                        expiredStorage.removeItem("@token")
                        expiredStorage.removeItem("@tokenChat")
                        expiredStorage.removeItem("@userChat")
                        setIdleModal(true)
                    }
                })
                .catch(err=>{
                    setIdleModal(true)
                })
            }
      }
  };
  const handleActive = (e) => {
    reset();
  };

  const handleReLogin = () => {
    setIdleModal(false);
    window.location.reload();
  };

  const { getLastActiveTime, reset, getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * SESION_IDLE_MINUTES,
    onIdle: handleIdle,
    onActive: handleActive,
    onAction: handleActive,
    debounce: 500,
  });

  return (
    <>
      <CompossedClass />
      <SweetAlert
        warning
        show={idleModal}
        title={`Anda telah tidak aktif selama ${SESION_IDLE_MINUTES} menit`}
        onConfirm={() => handleReLogin()}
      />
    </>
  );
};

export default IdleTimer;
