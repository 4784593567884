import React, { Component } from "react";
import SocialLogin from "react-social-login";
import { Button } from "reactstrap";

class SocialLoginButton extends Component {
  render() {
    const { children, triggerLogin, ...props } = this.props
    return (
      <Button
        block
        style={{
          background: "white",
          borderRadius: "60px",
          borderColor: "#E0E0E0",
        }}
        onClick={triggerLogin}
        {...props}
        className="btn-icon btn-3"
      >
        {children}
      </Button>
    );
  }
}

export default SocialLogin(SocialLoginButton);
