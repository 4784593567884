import * as types from "./actions";

const initialState = {
  loading: false,
  sellers: [],
  pageSeller: null,
  totalSeller: null,
  error: null,
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_DATA:
      return {
        ...state,
        sellers: action?.isInit ? action.payload.data : [...state.sellers, ...action.payload.data],
        pageSeller: action.payload.total_page,
        totalSeller: action.payload.total_lapak,
      };
    case types.CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}
