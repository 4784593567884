import React, { useEffect, useReducer, useState } from "react";
import {
  FETCH_CHECKOUT,
  FETCH_COMPLETE_CHECKOUT,
  FETCH_COMPLETE,
  FETCH_DATA,
  RESET,
  SET_LOADING,
  SET_RELOAD,
  SET_SHIPPING,
  SET_SUB_TOTAL,
  SET_TOTAL,
} from "../components/reducer/Action";
import {
  customerReducer,
  customerState,
} from "../components/reducer/CustomerReducer";
import { addToCart } from "../components/services/CartService";
import { getProfileCustomer } from "../components/services/CustomerService";
import {
  getDetailPedagang,
  getProduct,
} from "../components/services/ProductService";
import localId from "../components/shared/constants/ConstantsIdCustomer";
import ProductContext from "./context/product-context";
import CartReducer, { cartState } from "./reducers/CartReducers";
import CheckoutReducer, { checkoutState } from "./reducers/CheckoutReducers";
import ProductReducer, { productState } from "./reducers/ProductReducer";

const GlobalState = (props) => {
  const [enableGps, setEnableGps] = useState(false);
  const [product, dispatch] = useReducer(ProductReducer, productState);
  const [cart, dispatchCart] = useReducer(CartReducer, cartState);
  const [checkout, dispatchCheckout] = useReducer(
    CheckoutReducer,
    checkoutState
  );
  const [customer, dispatchCustomer] = useReducer(
    customerReducer,
    customerState
  );
  const [profile, setProfile] = useState({
    nama_depan: "",
    nama_belakang: "",
    email: "",
    password: "",
    alamat: "",
    no_hp: "",
  });
  const [loadingLocation, setLoadingLocation] = useState(false); // eslint-disable-next-line no-unused-vars
  const [mdlChckout, setMdlChkcout] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [nohplapak, setNohp] = useState(0);
  const [idLapak, setIdLapak] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [detailPedagang, setDetailPedagang] = useState({});
  const [pasarId, setPasarId] = useState("");
  const [listProduct, setListProduct] = useState([]); // eslint-disable-next-line no-unused-vars
  const fetchProduct = () => dispatch({ type: FETCH_DATA });
  const fetchProductComplete = (payload) =>
    dispatch({ type: FETCH_COMPLETE, payload });
  const fetchCart = () => dispatchCart({ type: FETCH_DATA });
  const reloadCart = () => dispatchCart({ type: SET_LOADING });
  const setReload = () => dispatchCart({ type: SET_RELOAD });
  const fetchCartComplete = (payload) =>
    dispatchCart({ type: FETCH_COMPLETE, payload });
  const fetchSubtotalCart = (payload) =>
    dispatchCart({ type: SET_SUB_TOTAL, payload });
  const fetchTotalCart = () => dispatchCart({ type: SET_TOTAL });
  const fetchShippingCart = (payload) =>
    dispatchCart({ type: SET_SHIPPING, payload });
  const fetchCheckout = () => dispatchCheckout({ type: FETCH_CHECKOUT });
  const fetchCompleteCheckout = (payload) =>
    dispatchCheckout({ type: FETCH_COMPLETE_CHECKOUT, payload });
  const fetchCustomer = () => dispatchCustomer({ type: FETCH_DATA });
  const resetCustomer = () => dispatchCustomer({ type: RESET });
  const setLoadingCustomer = () => dispatchCustomer({ type: SET_LOADING });
  const fetchCustomerComplete = (payload) =>
    dispatchCustomer({ type: FETCH_COMPLETE, payload: payload });

  const addProductToCart = (product, pasar, lapak, qty, catatan) => {
    const id = {
      idUser: localId,
      idProduct: product,
      idPasar: pasar,
      idLapak: lapak,
      quantity: qty,
      catatan: catatan,
    };
    addToCart(id)
      .then((res) => {
        reloadCart();
      })
      .catch((err) => {
        // console.log(err.response);
      });
  };

  const addToCartNoReload = (product, pasar, lapak, qty, catatan, section) => {
    const id = {
      idUser: localId,
      idProduct: product,
      idPasar: pasar,
      idLapak: lapak,
      quantity: qty,
      catatan: catatan,
      section: section,
    };
    addToCart(id)
      .then((res) => {
        reloadCart();
      })
      .catch((err) => {
        // console.log(err.response);
      });
  };

  useEffect(() => {
    fetchProduct();
    if (localId && localId !== null) {
      fetchCustomer();
      getProfileCustomer(localId).then((res) => {
        setProfile(res.data.Data);
        setLoadingCustomer();
      });
    }
    if (idLapak) {
      getDetailPedagang(idLapak)
        .then((res) => {
          setDetailPedagang(res);
          setNohp(res.nohp);
        })
        .catch((err) => {});
    }
    if (nohplapak) {
      getProduct(nohplapak, idLapak, page)
        .then((data) => {
          setTotalPages(data.total_pages);
          setTotalProduct(data.total_items);

          if (data.list.length > 0) {
            fetchProductComplete(data.list);
          }
        })
        .catch((err) => {});
    }
  }, [nohplapak, idLapak, props.location, page]);

  return (
    <ProductContext.Provider
      value={{
        products: product,
        setNohp,
        pedagang: detailPedagang,
        setDetailPedagang,
        setIdLapak,
        idLapak: idLapak,
        cart: cart,
        addProductToCart: addProductToCart,
        fetchCustomer,
        setLoadingCustomer,
        fetchCustomerComplete,
        checkout: checkout,
        fetchCheckout,
        fetchCompleteCheckout,
        customerLoading: customer.isLoading,
        customer: customer.customer,
        fetchCart,
        fetchCartComplete,
        cartLoading: cart.isLoading,
        resetCustomer,
        reloadCart,
        setIdPasar: setPasarId,
        pasarId,
        subTotal: cart.subTotal,
        totalCart: cart.total,
        shipping: cart.shipping,
        fetchSubtotalCart,
        fetchTotalCart,
        addToCartNoReload,
        fetchShippingCart,
        listProduct: listProduct,
        searchLoading,
        setSearchLoading,
        profile: profile,
        setProfile,
        mdlCheckout: mdlChckout,
        setMdlChckout: setMdlChkcout,
        fetchProduct,
        fetchProductComplete,
        loadingLocation,
        totalPages,
        totalProduct,
        page,
        setPage,
        enableGps,
        setEnableGps,
        setReload,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
};

export default GlobalState;
