import React, { useState } from "react";
import { Modal, Spinner, Tooltip } from "reactstrap";
import CheckCircle from "../../assets/icon/check-circle.svg";
import Circle from "../../assets/icon/circle.svg";
import "../../scss/form/modal.scss";
import FormPembelian from "../modal/FormPembelian";
import { deleteAddress, updateFirstAddress } from "../services/CustomerService";
import FaIcon from "../shared/font-awesome/FaIcon";

export default function CardAlamat(props) {
  const { data, setTrigger, trigger, toast, setToast, clearToast } = props;
  const [confirm, setConfirm] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipPin, setTooltipPin] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [edit, setEdit] = useState({});
  const [loading, setLoading] = useState(false);

  const popupConfirmation = {
    title: "",
    description: "",
  };
  const [popup, setPopup] = useState(popupConfirmation);
  const [del, setDelete] = useState(false);

  let icon = Circle;
  if (data.isAlamatUtama) icon = CheckCircle;
  const checkCircle = () => {
    setDelete(true);
    setPopup({
      ...popup,
      title: "Jadikan Alamat Utama",
      description: `Apakah anda ingin mengubah "${data.judul}" sebagai alamat utama? Anda hanya dapat memilih satu alamat utama`,
    });
    if (data.isAlamatUtama === false) {
      setConfirm(true);
    }
  };

  const setFistAddress = () => {
    clearToast();
    updateFirstAddress(data.id_info_pembelian)
      .then((res) => {
        setTrigger(!trigger);
        setConfirm(!confirm);
        setToast({
          ...toast,
          isOpen: true,
          message: `Sukses menjadikan alamat utama`,
          status: "success",
        });
      })
      .catch((err) => {
        setToast({
          ...toast,
          isOpen: true,
          message: `GAGAL menjadikan alamat utama`,
          status: "error",
        });
      });
  };

  const loadingTime = () => {
    setLoading(!loading);
  };

  const btnDelete = () => {
    // console.log("card Alamat");
    clearToast();
    setLoading(true);
    deleteAddress(data.id_info_pembelian)
      .then((res) => {
        setLoading(false);
        setTrigger(!trigger);
        setConfirm(!confirm);
        setToast({
          ...toast,
          isOpen: true,
          message: `Sukses menghapus dari daftar alamat`,
          status: "success",
        });
      })
      .catch((err) => {
        setLoading(false);
        setToast({
          ...toast,
          isOpen: true,
          message: `GAGAL menghapus dari daftar alamat`,
          status: "error",
        });
      });
  };

  const isDelete = () => {
    setDelete(false);
    setPopup({
      ...popup,
      title: "Hapus Alamat?",
      description: `Apakah Anda ingin menghapus "${data.judul}" dari daftar alamat?`,
    });
    setConfirm(true);
  };

  const setTextTooltop = () => {
    return data.isPinLocation ? "Sudah pin alamat" : "Belum pin alamat";
  };

  const editBtn = () => {
    setIsEdit(!edit);
    setEdit(data);
    setModal(true);
  };

  return (
    <div className="card-alamat-profile">
      <div>
        <img
          src={icon}
          alt="icon check"
          onClick={() => checkCircle()}
          style={{ cursor: "pointer" }}
          id={`circle-check-${data.id_info_pembelian}`}
        />
        <label className="text_label" style={{ marginLeft: "5px" }}>
          {data.judul}
          <span className="alamat_utama">
            {" "}
            {data.isAlamatUtama ? "(Alamat Utama)" : ""}
          </span>
        </label>
        <Tooltip
          placement="bottom"
          isOpen={tooltipOpen}
          autohide={false}
          target={`circle-check-${data.id_info_pembelian}`}
          toggle={toggle}
          delay={500}
        >
          Jadikan alamat utama
        </Tooltip>
      </div>
      <div className="row container-address-profile">
        <div className="col-3 receiver-profile">
          <label className="text_label_profile">Penerima</label>
          <label className="text_label_profile">
            Nama{" "}
            <span className="data-address-profile_ font-weight-bold">
              {data.name}
            </span>
          </label>
          <label className="text_label_profile">
            No Handphone{" "}
            <span className="data-address-profile_ font-weight-bold">
              {data.nomor_hp}
            </span>
          </label>
        </div>
        <div className="col-5 address-profile">
          <label className="text_label_profile">Alamat Pengiriman</label>
          <label className="data-address-profile">{data.alamat}</label>
        </div>
        <div className="col pin-profile">
          <label className="text_label_profile">Pin Lokasi</label>
          <div
            className="map_pin-address-profile"
            id={`pin-point-${data.id_info_pembelian}`}
          >
            <FaIcon
              icon="fas map-marker-alt"
              className="fa-lg"
              style={{ color: data.latitude ? "red" : "gray" }}
            />
          </div>
          <Tooltip
            placement="bottom"
            isOpen={tooltipPin}
            autohide={false}
            target={`pin-point-${data.id_info_pembelian}`}
            toggle={() => setTooltipPin(!tooltipPin)}
            delay={500}
            fade
            flip
          >
            {setTextTooltop()}
          </Tooltip>
        </div>
        <div className="col-2 action-profile">
          <div className="ubah-address-profile" onClick={editBtn}>
            <FaIcon icon="far edit" className="fa-sm" />
            <label className="label-on-action">Ubah</label>
          </div>
          {!data?.isAlamatUtama && (
            <div className="hapus-address-profile" onClick={() => isDelete()}>
              <FaIcon icon="far trash-alt" className="fa-sm" />
              <label className="label-on-action">Hapus</label>
            </div>
          )}
        </div>
      </div>
      <Modal centered isOpen={confirm}>
        <div className="confirm-set-first-address">
          <label className="label-set-first-address">{popup.title}</label>
          <label className="text-set-first-address">{popup.description}</label>
          <div className="btn-container-set-address">
            <button
              className="btn-cancel-set-address-profile"
              onClick={() => setConfirm(false)}
            >
              BATAL
            </button>
            {del ? (
              <button
                className="btn-delete-address"
                onClick={() => setFistAddress()}
              >
                JADIKAN ALAMAT UTAMA
              </button>
            ) : (
              <button
                className="btn-delete-address"
                onClick={() => btnDelete()}
              >
                {loading ? <Spinner size="sm" /> : "HAPUS"}
              </button>
            )}
          </div>
        </div>
      </Modal>
      <FormPembelian
        modal={modal}
        setModal={setModal}
        trigger={trigger}
        setTrigger={setTrigger}
        where={"update"}
        id={data.id_info_pembelian}
        edit={edit}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        toast={toast}
        setToast={setToast}
      />
    </div>
  );
}
