import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { withRouter, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import "../../scss/dropdown/dropdown.scss";
import { logoutCustomer } from "../services/CustomerService";
import expiredStorage from "../utils/expiredStorage";
import cookieGuest from "components/shared/constants/ConstantsGuestCustomer";

function DropdownProfile(props) {
  const { isMobile } = props;

  const goTo = (to, logout) => {
    if (logout && logout === true) {
      logoutCustomer()
        .then((res) => {
          if (res.data.Deskripsi === "Success Logout") {
            localStorage.removeItem("@user");
            localStorage.removeItem("@tipelogin");
            localStorage.removeItem("@userEmail");
            expiredStorage.removeItem("@token");
            expiredStorage.removeItem("@tokenChat");
            expiredStorage.removeItem("@userChat");
            window.location.reload();
          }
        })
        .catch((err) => {});
    } else {
      props.history.push(to);
    }
  };
  const handleLogout = (props) => {
    goTo(props.to, props.logout);
  };
  const ifLoginMobile = (
    <div>
      <DropdownItem
        to="/profile"
        leftIcon={<FontAwesomeIcon icon="user-cog" />}
      >
        <span>Profil</span>
      </DropdownItem>
      <DropdownItem
        to={`/listorder`}
        leftIcon={<FontAwesomeIcon icon="file-invoice" />}
      >
        <span>Pembelian</span>
      </DropdownItem>
      <DropdownItem
        to={`/wishlist`}
        leftIcon={<FontAwesomeIcon icon="heart" />}
      >
        <span>Wishlist</span>
      </DropdownItem>
      {/* <DropdownItem to={`/chat`} leftIcon={<FontAwesomeIcon icon="comments" />}>
        <span>Pesan</span>
      </DropdownItem> */}
      <DropdownItem
        to="/"
        logout={true}
        leftIcon={<FontAwesomeIcon icon="sign-out-alt" />}
      >
        <span>Keluar</span>
      </DropdownItem>
    </div>
  );
  const ifLogin = (
    <div>
      <DropdownItem
        to="/profile"
        leftIcon={<FontAwesomeIcon icon="user-cog" />}
      >
        <span>Profil</span>
      </DropdownItem>
      <DropdownItem
        to={`/listorder`}
        leftIcon={<FontAwesomeIcon icon="file-invoice" />}
      >
        <span>Pembelian</span>
      </DropdownItem>{" "}
      <DropdownItem
        to={`/wishlist`}
        leftIcon={<FontAwesomeIcon icon="heart" />}
      >
        <span>Wishlist</span>
      </DropdownItem>
      <DropdownItem
        to="/"
        logout={true}
        leftIcon={<FontAwesomeIcon icon="sign-out-alt" />}
      >
        <span>Keluar</span>
      </DropdownItem>
    </div>
  );
  const notLogin = (
    <div>
      <DropdownItem
        to={`/listorder`}
        leftIcon={<FontAwesomeIcon icon="file-invoice" />}
      >
        <span>Pembelian</span>
      </DropdownItem>
      <DropdownItem
        to="/login"
        leftIcon={<FontAwesomeIcon icon="sign-in-alt" />}
      >
        <span>Masuk</span>
      </DropdownItem>
    </div>
  );
  return (
    <div className={`dropdown-profile ${isMobile ? "mobile" : ""}`}>
      {localStorage.getItem("@user") && localStorage.getItem("@user") !== null
        ? !isMobile
          ? ifLogin
          : ifLoginMobile
        : cookieGuest
        ? notLogin
        : "masuk bro"}
    </div>
  );
  function DropdownItem(props) {
    return (
      <div
        onClick={() => handleLogout(props)}
        // onClick={() => props.setOpen(!props.open)}
        className="menu-item-profile"
        style={{ cursor: "pointer" }}
      >
        <span className="icon-button-profile">{props.leftIcon}</span>
        {props.children}
      </div>
    );
  }
}

export default withRouter(DropdownProfile);
