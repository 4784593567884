import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { withRouter } from "react-router-dom";
import FormPembelian from "../modal/FormPembelian";
import FaIcon from "../shared/font-awesome/FaIcon";
import CardAlamatMobile from "./CardAlamatMobile";

function ProfileMobile(props) {
  const {
    className,
    customer,
    profileImage,
    activities,
    setTrigger,
    trigger,
    modal,
    setModal,
    toast,
    setToast,
    clearToast,
    editProfile,
    setEditProfile,
    isLoginMedsos,
    KeyIcon,
  } = props;

  const [add, setAdd] = useState(false);
  const [trig, setTrig] = useState(false);

  useEffect(() => { }, [trig]);

  return (
    <div className={className}>
      <div className="row content-profile-customer">
        <div className="col-5 img-profile-new">
          <img
            id="img-profile"
            src={profileImage}
            alt="profile image"
            className="profile-image"
          />
        </div>
        <div className="col-7 detail-data">
          <label className="text-detail-profile-name">
            {customer.nama_depan !== undefined ? (
              customer.nama_depan + " " + customer.nama_belakang
            ) : (
              <Skeleton />
            )}
          </label>
          {customer.ttl ? (
            <>
              <label className="text-detail-profile">{customer.ttl}</label>
              <label className="text-detail-profile">{customer.jk}</label>
            </>
          ) : null}
          <label className="text-detail-profile">{customer.no_hp}</label>
          <label className="text-detail-profile">{customer.email}</label>
          <label className="text-detail-profile-alamat_profile">
            {customer.alamat}
          </label>
        </div>
        <div
          className="col-12"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem 1rem 0 1rem",
          }}
        >
          {isLoginMedsos == "false" ? (
            <button
              id="btn-ubah-katasandi"
              className="btn-change-password"
              style={{ float: "right" }}
              onClick={() => props.history.push("/changepassword")}
            >
              <img src={KeyIcon} alt="key-icon" className="img-ganti-sandi" />
              <label className="text-on-button my-0">UBAH KATA SANDI</label>
            </button>
          ) : (
            ""
          )}
          <button
            id="btn-ubah-profile"
            className="btn-change-profile"
            onClick={() => setEditProfile(true)}
          >
            <FaIcon
              icon="far edit"
              className="fa-lg"
              style={{ color: "#fff" }}
            />
            <label className="text-on-button my-0">UBAH PROFILE</label>
          </button>
        </div>
      </div>
      <div>
        <label style={{ fontWeight: "bold" }}>Setting Alamat</label>
        {customer.daftar_alamat ? (
          <>
            <div className="container_alamat_profile_mobile">
              <CardAlamatMobile
                data={customer.daftar_alamat[0]}
                className={"card-alamat-profile-mobile"}
              />
            </div>
            <label
              className="text-lihat-semua"
              onClick={() =>
                props.history.push({
                  pathname: "/listaddress",
                  modal: modal,
                  setModal: setModal,
                  trigger: trigger,
                  setTrigger: setTrigger,
                  toast: toast,
                  setToast: setToast,
                })
              }
            >
              Lihat Semua
            </label>
          </>
        ) : (
          <label
            style={{ color: "red" }}
            className="text-lihat-semua"
            onClick={() => setModal(true)}
          >
            + Tambah Alamat
          </label>
        )}
      </div>

      <div className="shopping-activity-mobile">
        <div className="text-aktivitas-belanja">
          <label>Aktivitas Belanja</label>
        </div>
        {activities.map((res, i) => {
          return (
            <div
              key={i}
              className="content-activities-mobile"
              onClick={() =>
                props.history.push({
                  pathname: res.link,
                  tabFromProfile: res.value,
                })
              }
            >
              <label>{res.text}</label>
              <FaIcon icon="fas chevron-right" className="fa-lg" />
            </div>
          );
        })}
      </div>
      <div className="shopping-activity-mobile">
        <div className="text-aktivitas-belanja akun">
          <label>Akun</label>
        </div>

        <div
          className="content-activities-mobile"
          onClick={() => props.history.push("/changepassword")}
        >
          <label>Ubah password</label>
          <FaIcon icon="fas chevron-right" className="fa-lg" />
        </div>
      </div>
      <FormPembelian
        modal={add}
        setModal={setAdd}
        trigger={trig}
        setTrigger={setTrig}
        where={"profile"}
        clearToast={clearToast}
      />
    </div>
  );
}
export default withRouter(ProfileMobile);
