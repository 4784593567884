import React, { useContext, useEffect, useReducer, useState } from "react";
import Modal from "react-modal";
import { withRouter } from "react-router-dom";
import productContext from "../../contex/context/product-context";
import "../../scss/form/modal.scss";
import { FETCH_COMPLETE, FETCH_DATA } from "../reducer/Action";
import { GenerateWhatsappReducer, orderState } from "../reducer/OrderReducer";
import { generateWhatsapp } from "../services/OrderServices";
import { jsonProduct, jsonToText } from "../utils/splitterJsonToText";

Modal.setAppElement("#root");
const ModalComp = (props) => {
  const [user, setUser] = useState({});
  const { isDone, idOrder } = props;
  const { reloadCart } = useContext(productContext);

  const [state, dispatch] = useReducer(GenerateWhatsappReducer, orderState);
  const FetchData = () => dispatch({ type: FETCH_DATA });
  const FetchComplete = (payload) => dispatch({ type: FETCH_COMPLETE, payload });

  const handleSelesai = () => {
    props.onRequestClose();
    props.history.push(`/listorder`);
    reloadCart();
  };

  useEffect(() => {
    FetchData();
    if (idOrder && idOrder !== 0) {
      generateWhatsapp(idOrder).then((res) => {
        FetchComplete(res.Data);
        setUser(res.Data.user);
      });
    }
  }, [idOrder]);

  const message = () => {
    let rows = {};
    if (state.isLoading === false && state.generateWhatsapp.pesanan !== undefined) {
      const order = jsonProduct(state.generateWhatsapp.pesanan);

      return (rows = {
        title: "Konfirmasi Pesanan",
        Order: state.generateWhatsapp.invoice,
        Pasar: state.generateWhatsapp.nama_pasar,
        Pesanan: order,
        Nama: user.nama_depan,
        Alamat: user.alamat,
        NoTlp: user.no_hp,
      });
    }
    return rows;
  };
  const stringMessage = jsonToText(message());
  const phone = `+62${state.generateWhatsapp.no_hp}`;

  const whatsappLink = `https://api.whatsapp.com/send?phone=+${phone}&text=${stringMessage}`;

  return (
    <div className="modal-container">
      <Modal
        style={{ overlay: { backgroundColor: "rgba(0,0,0,0.4)" } }}
        className="modal__konf"
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
      >
        <h2 className="text-confirm">Konfirmasi</h2>
        <p className="text-description">
          Mohon tunggu pesanan Anda sedang dalam proses Admin Pasar.id dan akan di konfirmasi
          melalui WhatsApp.
          <br />
          Terima kasih
        </p>
        <div className="button-selesai">
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
            <button
              className="button_selesai"
              onClick={() => handleSelesai()}
              style={{ backgroundColor: "#7A8D3F" }}
            >
              <div style={{ color: "white" }}>Selesai</div>
            </button>
          </a>
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(ModalComp);
