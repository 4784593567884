import React, { useEffect, useReducer, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Page from "../../containers/layout/page/Page";
import { FETCH_COMPLETE, FETCH_DATA } from "../reducer/Action";
import { addressReducer, addressState } from "../reducer/AddressReducer";
import {
  deleteAddress,
  getListAddress,
  updateFirstAddress,
} from "../services/CustomerService";
import CardAlamatMobile from "./CardAlamatMobile";
import "../../scss/form/modal.scss";
import { withRouter } from "react-router-dom";
import FormPembelian from "../modal/FormPembelian";
import FaIcon from "../shared/font-awesome/FaIcon";
import { Modal, Spinner } from "reactstrap";
import NoDataFound from "../../containers/layout/page/NoDataFound";
import { clearToast, toastObject } from "../../containers/layout/page/Profile";
import ToastNotification from "../button/Toast";

function DaftarAlamat(props) {
  const { pathname } = props.location;
  const [state, dispatch] = useReducer(addressReducer, addressState);
  const [trigger, setTrigger] = useState(false);
  const [toast, setToast] = useState({ ...toastObject });
  const [idAddress, setIdAddress] = useState("");
  const [modal, setModal] = useState(false);
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [edit, setEdit] = useState({});
  const [where, setWhere] = useState("profile");
  const [isEmpty, setIsEmpty] = useState(false);

  const fetchData = () => dispatch({ type: FETCH_DATA });
  const fetchComplete = (payload) => {
    dispatch({ type: FETCH_COMPLETE, payload });
  };

  useEffect(() => {
    fetchData();
    getListAddress()
      .then((res) => {
        if (res.data.Data != null) {
          fetchComplete(res.data.Data);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [trigger]);

  const loadingTime = () => {
    setLoading(!loading);
  };

  const toggle = (btn, res, type) => {
    if (active !== btn) {
      setEdit(res);
      setIdAddress(btn);
      setActive(btn);
      setIsEdit(!isEdit);
      setWhere("update");
    }

    if (type) {
      switch (type) {
        case "update":
          onUpdate(res.info_pembelian_id);
          break;
        case "delete":
          setConfirm(true);
          break;
        default:
          break;
      }
    }
  };
  const selectAddress = () => {
    if (idAddress) {
      updateFirstAddress(idAddress)
        .then((res) => {
          setTrigger(!trigger);
          props.history.push("/profile");
        })
        .catch((err) => {});
    } else {
      alert("Pilih alamat");
    }
  };

  const onUpdate = (id) => {
    setModal(true);
    setWhere("update");
    setIdAddress(id);
  };

  const onAdd = () => {
    setWhere("profile");
    setModal(true);
  };

  const btnDelete = () => {
    loadingTime();
    deleteAddress(idAddress)
      .then((res) => {
        setLoading(false);
        setTrigger(!trigger);
        setConfirm(false);
      })
      .catch((err) => {
        setConfirm(false);
        setLoading(false);
      });
  };

  const isListAddress = () => {
    return pathname === "/listaddress";
  };

  const generateAddress = () => {
    let rows = (
      <div>
        <Skeleton height={150} />
        <Skeleton height={150} />
        <Skeleton height={150} />
      </div>
    );
    if (state.isLoading === false && state.addressList !== null) {
      rows = state.addressList.map((res, i) => {
        return (
          <div
            key={i}
            className={`container-card-address my-3 ${
              active == res.info_pembelian_id ? "" : "not-clicked"
            }`}
            id={`card-${i}`}
          >
            <CardAlamatMobile
              isListAddress={isListAddress()}
              data={res}
              className={`card-alamat-profile-mobile`}
              trigger={trigger}
              setTrigger={setTrigger}
              onClick={() => toggle(res.info_pembelian_id, res)}
              idCard={active}
            />
            <div className="added-when-click">
              <div
                className="ubah-address-profile"
                onClick={() => toggle(res.info_pembelian_id, res, "update")}
              >
                <FaIcon icon="far edit" className="fa-sm" />
                <label className="label-button-listaddress">Ubah</label>
              </div>
              {!res?.isAlamatUtama && (
                <div
                  className="hapus-address-profile"
                  onClick={() => toggle(res.info_pembelian_id, res, "delete")}
                >
                  <FaIcon icon="far trash-alt" className="fa-sm" />
                  <label className="label-button-listaddress">Hapus</label>
                </div>
              )}
            </div>
            {/* {active == res.info_pembelian_id ? (
              <div className="added-when-click">
                <div
                  className="ubah-address-profile"
                  onClick={() => onUpdate(res.info_pembelian_id)}
                >
                  <FaIcon icon="far edit" className="fa-sm" />
                  <label className="label-button-listaddress">Ubah</label>
                </div>
                <div
                  className="hapus-address-profile"
                  onClick={() => setConfirm(true)}
                >
                  <FaIcon icon="far trash-alt" className="fa-sm" />
                  <label className="label-button-listaddress">Hapus</label>
                </div>
              </div>
            ) : (
              ""
            )} */}
          </div>
        );
      });
    } else if (isEmpty) {
      rows = (
        <NoDataFound
          error="Oops!"
          text="Kamu belum menambah alamat, ayo tambahkan alamat agar bisa berbelanja di pasar.id"
        />
      );
    }
    return rows;
  };
  return (
    <Page>
      <div className="page-list-address-profile">
        {generateAddress()}
        <div className="button-container-list-address">
          <button className="btn-list-address add" onClick={onAdd}>
            TAMBAH ALAMAT
          </button>
          <button className="btn-list-address select" onClick={selectAddress}>
            PILIH
          </button>
        </div>
        <Modal centered isOpen={confirm}>
          <div className="confirm-set-first-address">
            <label className="label-set-first-address">Hapus Alamat</label>
            <label className="text-set-first-address">
              Anda yakin ingin manghapus alamat?
            </label>
            <div className="btn-container-set-address">
              <button
                className="btn-cancel-set-address-profile"
                onClick={() => setConfirm(false)}
              >
                BATAL
              </button>
              <button
                className="btn-delete-address"
                onClick={() => btnDelete()}
              >
                {loading ? <Spinner size="sm" /> : "HAPUS"}
              </button>
            </div>
          </div>
        </Modal>
        <FormPembelian
          modal={modal}
          setModal={setModal}
          trigger={trigger}
          setTrigger={setTrigger}
          toast={toast}
          setToast={setToast}
          where={where}
          setWhere={setWhere}
          id={idAddress}
          edit={edit}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          clearToast={() => clearToast(setToast)}
        />
        <ToastNotification
          isOpen={toast.isOpen}
          message={toast.message}
          status={toast.status}
          setToast={setToast}
        />
      </div>
    </Page>
  );
}
export default withRouter(DaftarAlamat);
