import React, { useEffect } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { generateCidSecret, getToken } from './components/services/CidService'
import loadFontAwesome from './components/shared/font-awesome/loader'
import expiredStorage from './components/utils/expiredStorage'
import GlobalState from './contex/GlobalState'
import { history } from './history'
import { routes } from './routes'
import ReactGa from 'react-ga'
loadFontAwesome()

function App() {
  const id = process.env.REACT_APP_GOOGLE_ANALYTICS
  useEffect(() => {
    if (!expiredStorage.getItem('@token')) {
      generateCidSecret().then((res) => {
        getToken(res.data.Data).then((res) => {
          expiredStorage.setItem('@token', res.data.Data.token, 3595)
          window.location.reload()
        })
      })
    }
    const token = setInterval(() => {
      if (!expiredStorage.getItem('@token')) {
        generateCidSecret().then((res) => {
          getToken(res.data.Data).then((res) => {
            expiredStorage.setItem('@token', res.data.Data.token, 3595)
          })
        })
      }
    }, 3500000)

    return () => clearInterval(token)
  }, [expiredStorage.getItem('@token')])

  ReactGa.initialize(id)
  ReactGa.pageview(window.location.pathname + window.location.search)

  history.listen((location) => {
    ReactGa.set({ page: location.pathname })
    ReactGa.pageview(window.location.pathname + window.location.search)
  })

  return (
    <GlobalState>
      <Router history={history}>
        <React.Suspense fallback={''}>
          <Switch>
            {routes.map((route, i) => (
              <Route key={i} {...route} />
            ))}
          </Switch>
        </React.Suspense>
      </Router>
    </GlobalState>
  )
}

export default App
